import { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { styled as styledComponent } from 'styled-components'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from "@mui/material/Button"
import GoogleWalletAddCard from '../../../../../assets/wallet/google/frFR_add_to_google_wallet_add-wallet-badge.png'
import endpoints from '../../../../../mixins/endpoints'
import api from '../../../../../mixins/api'
import apiBaseUrl from '../../../../../mixins/apiBaseUrl'
import GoogleObject from '../../../../../models/GoogleObject'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1B365F",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function styleDetail(style: number){
  if(style === 0){
    return "Générique"
  }
  // else if(style === 1){
  //   return "Billet d'évènement"
  // }
  // else if(style === 2){
  //   return "Carte d'embarquement"
  // }
  // else if(style === 3){
  //   return "Carte cadeau"
  // }
  // else if(style === 4){
  //   return "Carte de fidélité"
  // }
  // else if(style === 5){
  //   return "Carte de transport"
  // }
  else{
    return "Inconnu"
  }
}

function openNewTab(classId: string, objectId: string) {
  api.methods.get(apiBaseUrl.data().digitalFramework + endpoints.data().GetGoogleObjectLinkEndpoint + "?classId=" + classId +"&objectId=" + objectId).then((response =>{
    window.open(response.data,"_blank");
  })).catch((error)=>{
      alert(error)
      console.log(error)
  })
}

function getPassLink(classId: string, objectId: string){
  api.methods.get(apiBaseUrl.data().digitalFramework + endpoints.data().GetGoogleObjectLinkEndpoint + "?classId=" + classId +"&objectId=" + objectId).then((response =>{
    navigator.clipboard.writeText(response.data)
  })).catch((error)=>{
      alert(error)
      console.log(error)
  })
}

export default function DisplayPass() {
  const [dataGoogleObject, setDataGoogleObject] = useState<GoogleObject[]>([]);
  const [dataGoogleObjectDisabled, setDataGoogleObjectDisabled] = useState<GoogleObject[]>([]);
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page2, setPage2] = useState(0)
  const [rowsPerPage2, setRowsPerPage2] = useState(10)

  useEffect(() => {
    fetchGoogleObject()
    fetchGoogleObjectDisabled()
  }, []);

  const fetchGoogleObject = async () => {
    api.methods.get(apiBaseUrl.data().digitalFramework+ endpoints.data().GetAllGoogleObjectEndpoint + '?&filter=State eq true').then((response =>{
      setDataGoogleObject(response.data)
    })).catch((error)=>{
        alert(error)
        console.log(error)
    })
  }

  const fetchGoogleObjectDisabled = async () => {
    api.methods.get(apiBaseUrl.data().digitalFramework + endpoints.data().GetAllGoogleObjectEndpoint + '?&filter=State eq false').then((response =>{
      setDataGoogleObjectDisabled(response.data)
    })).catch((error)=>{
        alert(error)
        console.log(error)
    })
  }

  function formatDate(parameter:string, hideHour : boolean){
    var result = ''
    if(parameter !== '')
    { 
      if(hideHour){
        result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'long', day: 'numeric' });
      }else{
        result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' });
      }
    }
    return result;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const handleChangePage2 = (event: unknown, newPage: number) => {
    setPage2(newPage);
  }

  const handleChangeRowsPerPage2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage2(+event.target.value);
    setPage2(0);
  }

  return (
    <>
      {/* <Button onClick={fetchGoogleObject}>Actualiser</Button> */}
      <center>CARTES ACTIVES</center>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align='center'>ID</StyledTableCell>
              <StyledTableCell align="center">Type</StyledTableCell>
              <StyledTableCell align="center">Modèle</StyledTableCell>
              <StyledTableCell align='center'>Titre</StyledTableCell>
              {/* <StyledTableCell align="center">Statut</StyledTableCell> */}
              <StyledTableCell align="center">Créé</StyledTableCell>
              <StyledTableCell align="center">Mise à jour</StyledTableCell>
              <StyledTableCell align="center">Expiration</StyledTableCell>
              <StyledTableCell align="center">Version</StyledTableCell>
              <StyledTableCell align="center">Ajouter</StyledTableCell>
              <StyledTableCell align="center">Lien</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataGoogleObject.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <StyledTableRow key={row.title}>
                <StyledTableCell align="center">{row.objectId}</StyledTableCell>
                <StyledTableCell align="center">{styleDetail(row.style)}</StyledTableCell>
                <StyledTableCell align="center">{row.classId}</StyledTableCell>
                <StyledTableCell align='center' component="th" scope="row">
                  {row.title}
                </StyledTableCell>
                {/* <StyledTableCell align="center"><b style={{color:'green'}}>{row.state === true ? 'Actif' : 'Inactif'}</b></StyledTableCell> */}
                <StyledTableCell align="center">{formatDate(row.created, true)}</StyledTableCell>
                <StyledTableCell align="center">{formatDate(row.lastUpdate ?? '', true)}</StyledTableCell>
                <StyledTableCell align="center">{formatDate(row.validityEnd ?? '', true)}</StyledTableCell>
                <StyledTableCell align="center">{row.version}</StyledTableCell>
                <StyledTableCell align="center">
                  <Button onClick={(e) =>{openNewTab(row.classId, row.objectId)}}>
                      <img src={GoogleWalletAddCard} alt='' width={'125px'} />
                  </Button>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Button onClick={(e) =>{getPassLink(row.classId, row.objectId)}}>
                      Copier le lien
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Align>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={dataGoogleObject.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={
            ({ from, to, count }) => {
              return '' + from + '-' + to + ' sur ' + count
            }
          }
          labelRowsPerPage="Lignes par page"
        />
      </Align>
      <br /><br />
      <center>CARTES INACTIVES</center>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align='center'>ID</StyledTableCell>
              <StyledTableCell align="center">Type</StyledTableCell>
              <StyledTableCell align='center'>Titre</StyledTableCell>
              {/* <StyledTableCell align="center">Etat</StyledTableCell> */}
              <StyledTableCell align="center">Créé</StyledTableCell>
              <StyledTableCell align="center">Mise à jour</StyledTableCell>
              <StyledTableCell align="center">Date de validité</StyledTableCell>
              <StyledTableCell align="center">Date d'expiration</StyledTableCell>
              <StyledTableCell align="center">Version</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataGoogleObjectDisabled.map((row) => (
              <StyledTableRow key={row.title}>
                <StyledTableCell align="center">{row.objectId}</StyledTableCell>
                <StyledTableCell align="center">{styleDetail(row.style)}</StyledTableCell>
                <StyledTableCell align='center' component="th" scope="row">
                  {row.title}
                </StyledTableCell>
                {/* <StyledTableCell align="center"><b style={{color:'red'}}>{row.state === true ? 'Actif' : 'Inactif'}</b></StyledTableCell> */}
                <StyledTableCell align="center">{formatDate(row.created, false)}</StyledTableCell>
                <StyledTableCell align="center">{formatDate(row.lastUpdate ?? '', false)}</StyledTableCell>
                <StyledTableCell align="center">{formatDate(row.validityStart ?? '', true)}</StyledTableCell>
                <StyledTableCell align="center">{formatDate(row.validityEnd ?? '', true)}</StyledTableCell>
                <StyledTableCell align="center">{row.version}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Align>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={dataGoogleObjectDisabled.length}
          rowsPerPage={rowsPerPage2}
          page={page2}
          onPageChange={handleChangePage2}
          onRowsPerPageChange={handleChangeRowsPerPage2}
          labelDisplayedRows={
            ({ from, to, count }) => {
              return '' + from + '-' + to + ' sur ' + count
            }
          }
          labelRowsPerPage="Lignes par page"
        />
      </Align>
    </>
  );
}

const Align = styledComponent.div`
p {
  margin-top: 13px
}
`;