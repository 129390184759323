import { useState, useEffect } from 'react';
import Form from '../../../../components/Forms/Form';
import { IInputProps } from '../../../../components/Forms/Input';
import endpoints from '../../../../mixins/endpoints';
import api from '../../../../mixins/api';
import apiBaseUrl from '../../../../mixins/apiBaseUrl';
import Button from "@mui/material/Button"
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Logo from '../../../../models/Logo'
import Garbage from '../../../../assets/icons/Garbage.png'
import UpdateButton from '../../../../assets/icons/Update.png'
import DialogImg from '../../../../components/Modals/DialogImg'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import THEME from '../../../../styles/theme';
import { styled as styledComponent } from 'styled-components';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1B365F",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  

function LogoManagement() {
    const rx = /^(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5]),(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5]),(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])$/
    const [dataLogos, setDataLogos] = useState<Logo[]>([]);
    const [displayDialogNewLogo, setDisplayDialogNewLogo] = useState(false)
    const [displayDialogUpdateLogo, setDisplayDialogUpdateLogo] = useState(false)
    const [displayDialogConfirmRemove, setDisplayDialogConfirmRemove] = useState(false)
    const [displayDialog, setDisplayDialog] = useState(false)
    const [dialogMessage, setDialogMessage] = useState("")
    const [logoIdToUpdate, setLogoIdToUpdate] = useState("")
    const [logoIdToRemove, setLogoIdToRemove] = useState("")
    const [inputLogoName, setInputLogoName] = useState("")
    const [inputLogoBackgroundColor, setInputLogoBackgroundColor] = useState("")
    const [inputLogoFile, setInputLogoFile] = useState<File>()
    const [errorInputFileMessage, setErrorInputFileMessage] = useState("")
    const [logoFormData, setLogoFormData] = useState<FormData>()
    const [displayDialogImg, setDisplayDialogImg] = useState(false)
    const [imgDialog, setImgDialog] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const COLUMNLEFTBASE: IInputProps[] = 
    [
        {
          input:'text',
          type:'full',
          id:'inputLogoName',
          label:"Nom de l'entité (requis)",
          labelPosition: 'left',
          name:'inputLogoName',
          placeholder:"Nom de l'entité (requis)",
          value: inputLogoName,
          setValue: setInputLogoName
        },
        {
          input:'text',
          type:'full',
          id:'inputLogoBackgroundColor',
          label:'Couleur RGB associée',
          labelPosition: 'left',
          name:'inputLogoBackgroundColor',
          placeholder:'Si vide alors la couleur AGL sera appliquée par défaut',
          value: inputLogoBackgroundColor,
          setValue: setInputLogoBackgroundColor
      }
    ]
    
    const fetchLogos = async () => {
      api.methods.get(apiBaseUrl.data().digitalFramework + endpoints.data().GetAllLogoEndpoint).then((response =>{
        setDataLogos(response.data)
          })).catch((error)=>{
              alert(error)
              console.log(error)
          })
      }
      
      useEffect(() => {
        fetchLogos();
      }, []);

      function confirmDeleteLogo(logoId: string, logoName: string){
            setDialogMessage("Suppression définitive de l'entité \"" + logoName + "\" ?")
            setDisplayDialogConfirmRemove(true)
            setLogoIdToRemove(logoId)
      }

      function confirmUpdateLogo(logoId: string, logoName: string, logoBackground : string){
        setDisplayDialogUpdateLogo(true)
        setLogoIdToUpdate(logoId)
        setInputLogoName(logoName)
        setInputLogoBackgroundColor(logoBackground)
      }

      const handleClose = () => {
        setDisplayDialogConfirmRemove(false);
        setDisplayDialog(false)
      };

      const handleCloseNewLogo = () => {
        setDisplayDialogNewLogo(false)
        setErrorInputFileMessage("")
        setInputLogoFile(undefined)
        setLogoFormData(undefined)
        setInputLogoName("")
        setInputLogoBackgroundColor("")
      };

      const handleCloseUpdateLogo = () => {
        setDisplayDialogUpdateLogo(false)
        setErrorInputFileMessage("")
        setInputLogoFile(undefined)
        setLogoFormData(undefined)
        setInputLogoName("")
        setInputLogoBackgroundColor("")
      };

      const displayPreview = async(imgbase64: string) => {
        setImgDialog(imgbase64)
        setDisplayDialogImg(true)
      }
    
      const deleteLogo = async () => {
        setDisplayDialogConfirmRemove(false);
        
        if(logoIdToRemove !== ""){
          api.methods.delete(apiBaseUrl.data().digitalFramework + endpoints.data().DeleteLogoEndpoint + "?logoId=" + logoIdToRemove).then(() =>{
            setLogoIdToRemove("")
            fetchLogos()
              }).catch((error)=>{
                  alert(error)
                  console.log(error)
              })
        }
      }
      
      function openNewLogoDialog(){
        setDisplayDialogNewLogo(true)
      }

      function checkInputNewLogo(){
        var fileExtension = inputLogoFile?.name.split(".").at(-1);
        const allowedFileTypes = ["jpg", "png"];

        if (allowedFileTypes.includes(fileExtension?.toLowerCase() ?? "unknow")) {
            if(inputLogoName !== ""){
              if(inputLogoBackgroundColor !== '' && !rx.test(inputLogoBackgroundColor)){
                setErrorInputFileMessage("Veuillez renseigner une couleur composée de trois valeurs allant de 0 à 255 séparées par une virgule (exemple: 50,45,255)")
              }
              else{
                addNewLogo()
              }    
            }
            else{
              setErrorInputFileMessage("Veuillez renseigner le nom du logo")
            }
        }else{
          setErrorInputFileMessage("Le format du fichier n'a pas été reconnu")
        }
      }

      function checkInputUpdateLogo(){
        var fileExtension = inputLogoFile?.name.split(".").at(-1);
        const allowedFileTypes = ["jpg", "png"];

        if (allowedFileTypes.includes(fileExtension?.toLowerCase() ?? "unknow")) {
            if(inputLogoName !== ""){
              if(inputLogoBackgroundColor !== '' && !rx.test(inputLogoBackgroundColor)){
                setErrorInputFileMessage("Veuillez renseigner une couleur composée de trois valeurs allant de 0 à 255 séparées par une virgule (exemple: 50,45,255)")
              }
              else{    
                updateNewLogo()
              }
            }
            else{
              setErrorInputFileMessage("Veuillez renseigner le nom du logo")
            }
        }else{
          setErrorInputFileMessage("Le format du fichier n'a pas été reconnu")
        }
      }

      const handleFileUpload = (event: HTMLInputElement) => {
        var file = event !== null && event.files !== null ? event.files[0] : null;
        if(file !== null){
          const formData = new FormData();
          formData.append("logo", file);

          setLogoFormData(formData)
          setInputLogoFile(file)
        }
      };

      const addNewLogo = async () => {
        setDisplayDialogNewLogo(false);
        api.methods.post(apiBaseUrl.data().digitalFramework + endpoints.data().AddNewLogoEndpoint+ "?name=" + inputLogoName + "&backgroundColor=" + inputLogoBackgroundColor, logoFormData).then((() =>{
          handleCloseNewLogo()
          fetchLogos()
        })).catch((error)=>{
            console.log(error)
            setDialogMessage("Un logo portant le même nom existe déjà")
            setDisplayDialog(true)
            handleCloseNewLogo()
        })
      }

      const updateNewLogo = async () => {
        setDisplayDialogNewLogo(false);
        api.methods.put(apiBaseUrl.data().digitalFramework + endpoints.data().UpdateNewLogoEndpoint+ "?existingLogoId=" + logoIdToUpdate + "&newLogoName=" + inputLogoName + "&newBackgroundColor=" + inputLogoBackgroundColor, logoFormData).then((response =>{
            setDialogMessage("L'entité a bien été modifiée")
            setDisplayDialog(true)
            handleCloseUpdateLogo()
            fetchLogos()
        })).catch((error)=>{
            console.log(error)
            setDialogMessage("Une erreur est apparue durant le traitement, informer l'équipe administrateurs afin qu'ils puissent résoudre votre problème")
            setDisplayDialog(true)
            handleCloseUpdateLogo()
        })
      }

      function formatBytes(size: string, decimals = 2) {
        var bytes = Number(size)
        if (!+bytes) return '0 Bytes'
    
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo']
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    function formatDate(parameter:string, hideHour : boolean){
        var result = ''
        if(parameter !== '')
        { 
          if(hideHour){
            result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'long', day: 'numeric' });
          }else{
            result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' });
          }
        }
        return result;
      }

      const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
      }
    
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
      }

    return (
        <>
            <Button onClick={openNewLogoDialog}>Ajouter</Button>
            <br/><br/>
            <DialogImg type='main' 
              display={displayDialogImg} 
              setDisplay={setDisplayDialogImg} 
              title='AGL - Entité' 
              imagebase64={imgDialog} 
              copyEnabled={false}>customdialog
            </DialogImg>
            <Dialog
              open={displayDialogNewLogo} 
              onClose={handleCloseNewLogo}
              PaperComponent={PaperStyle}
              fullWidth={true}
              //fullScreen={true}
              >
              <DialogTitle>Ajouter une entité</DialogTitle>
              <DialogContent>
                Format autorisé : PNG ou JPEG<br/>
                Poids max : 1 mo<br/>
                Resolution recommandée (avec rapport d'aspect fixe) :
                <ul>
                  <li>Soit une Hauteur : 88 pixels max OU soit une Largeur : 156 pixels max</li>
                </ul>
                Vous pouvez redimensionner votre image en ligne sur :<a href="https://www.resizepixel.com/fr/resize-image" target='_blank' rel="noreferrer">ResizePixel</a>
                <center>
                  <Warning>{errorInputFileMessage}</Warning>
                </center>
              </DialogContent>
                  <Form
                        title=""
                        first={COLUMNLEFTBASE}
                        type="main"
                        form="full" 
                        design="main"
                        corporate
                        >
                      <input type="file" onChange={(e)=>{handleFileUpload(e.currentTarget)}}/>
                  </Form>
                  
              <DialogActions>
                <Button onClick={checkInputNewLogo} color="primary" autoFocus>
                  <Color>Ajouter</Color>
                </Button>
                <Button onClick={handleCloseNewLogo} color="primary" autoFocus>
                  <Color>Annuler</Color>
                </Button>
              </DialogActions>
            </Dialog>
            
            <Dialog
              open={displayDialogUpdateLogo} 
              onClose={handleCloseUpdateLogo}
              PaperComponent={PaperStyle}
              fullWidth={true}
              //fullScreen={true}
              >
              <DialogTitle>Modifier une entité</DialogTitle>
              <DialogContent>
                Format autorisé : PNG ou JPEG<br/>
                Poids max : 1 mo<br/>
                Resolution recommandée (avec rapport d'aspect fixe) :
                <ul>
                  <li>Soit une Hauteur : 88 pixels max OU soit une Largeur : 156 pixels max</li>
                </ul>
                Vous pouvez redimensionner votre image en ligne sur :<a href="https://www.resizepixel.com/fr/resize-image" target='_blank' rel="noreferrer">ResizePixel</a>
                <center>
                  <Warning>{errorInputFileMessage}</Warning>
                </center>
              </DialogContent>
                  <Form
                        title=""
                        first={COLUMNLEFTBASE}
                        type="main"
                        form="full" 
                        design="main"
                        corporate
                        >
                      <input type="file" onChange={(e)=>{handleFileUpload(e.currentTarget)}}/>
                  </Form>
              <DialogActions>
                <Button onClick={checkInputUpdateLogo} color="primary" autoFocus>
                  <Color>Modifier</Color>
                </Button>
                <Button onClick={handleCloseUpdateLogo} color="primary" autoFocus>
                  <Color>Annuler</Color>
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={displayDialogConfirmRemove} 
              onClose={handleClose}
              PaperComponent={PaperStyle}>
              <DialogContent>
                <ContentContainer >
                    {dialogMessage}
                </ContentContainer>
              </DialogContent>
              <DialogActions>
                <Button onClick={deleteLogo} color="primary" autoFocus>
                  <Color>Oui</Color>
                </Button>
                <Button onClick={handleClose} color="primary" autoFocus>
                  <Color>Non</Color>
                </Button>
              </DialogActions>
            </Dialog> 
            <Dialog
              open={displayDialog} 
              onClose={handleClose}
              PaperComponent={PaperStyle}>
              <DialogTitle>Gestion des entitées</DialogTitle>
              <DialogContent>
                <ContentContainer >
                    {dialogMessage}
                </ContentContainer>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                  Ok
                </Button>
              </DialogActions>
            </Dialog>            
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Nom</StyledTableCell>
                    <StyledTableCell align="center">Aperçu</StyledTableCell>
                    <StyledTableCell align='center'>Couleur RGB</StyledTableCell>
                    <StyledTableCell align='center'>Taille</StyledTableCell>
                    <StyledTableCell align="center">Créé</StyledTableCell>
                    <StyledTableCell align="center">Créé par</StyledTableCell>
                    <StyledTableCell align="center">Modifier</StyledTableCell>
                    <StyledTableCell align="center">Supprimer</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataLogos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell align="center">{row.name}</StyledTableCell>
                      <StyledTableCell align='center'>
                        <input type='image' onClick={()=>displayPreview(row.imageBase64)} src={`data:image/png;base64,${row.imageBase64}`} alt='' width={'80px'}/>
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.backgroundColor}</StyledTableCell>
                      <StyledTableCell align="center">{formatBytes(row.size)}</StyledTableCell>
                      <StyledTableCell align="center">{formatDate(row.created, true)}</StyledTableCell>
                      <StyledTableCell align="center">{row.createdBy}</StyledTableCell>
                      <StyledTableCell align="center">
                        <Button onClick={(e) =>{confirmUpdateLogo(row.id, row.name, row.backgroundColor)}}>
                            <img src={UpdateButton} width={25} height={25} alt="Modifier l'entité" title="Modifier l'entité" />
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button onClick={(e) =>{confirmDeleteLogo(row.id, row.name)}}>
                            <img src={Garbage} width={30} height={30} alt="Supprimer l'entité" title="Supprimer l'entité" />
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Align>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={dataLogos.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={
                  ({ from, to, count }) => {
                    return '' + from + '-' + to + ' sur ' + count
                  }
                }
                labelRowsPerPage="Lignes par page"
              />
            </Align>
        </>
    );
}


export default LogoManagement;

const PaperStyle = styled(Paper)`
  background-color: ${THEME.background.color.general};
  color: ${THEME.text.color.general};
`;

const ContentContainer = styledComponent.div`
  color: ${THEME.text.color.general};
`;

const Warning = styledComponent.div`
  color: #c82121;
`;

const Color = styledComponent.div`
  color: white;
`;

const Align = styledComponent.div`
p {
  margin-top: 13px
}
`;