import { useState, useEffect } from 'react'
import { IInputProps } from '../../../../../components/Forms/InputV2'
import endpoints from '../../../../../mixins/endpoints'
import api from '../../../../../mixins/api'
import apiBaseUrl from '../../../../../mixins/apiBaseUrl'
import Form from '../../../../../components/Forms/FormV2'
import GoogleClass from '../../../../../models/GoogleClass'
import Dialog from '../../../../../components/Modals/Dialog'
import Checkbox from '../../../../../components/Forms/Checkbox'
import { styled } from 'styled-components';
import Issuer from "../../../../../models/Issuer"

import Button from "@mui/material/Button";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { useTheme } from '@mui/material/styles';

export default function UpdateClass() {
    const [cardClassId, setCardClassId] = useState('')
    const [selectedNbRows, setSelectedNbRows] = useState(0)
    const [selectedNbRowsText, setSelectedNbRowsText] = useState('')
    const [nfcEnabled, setNfcEnabled] = useState(false)
    const [merchandIds, setMerchandIds] = useState('')
    const [displayDialog, setDisplayDialog] = useState(false)
    const [dialogCardMessage, setDialogCardMessage] = useState('')
    const [dataGoogleClass, setDataGoogleClass] = useState<GoogleClass[]>([])
    const [displayContent, setDisplayContent] = useState(false)
    const [classStyle] = useState(0)
    const dataListRows = ["0", "1", "2", "3"]

    const COLUMNLEFTNFC: IInputProps[] = 
    [
        {
            input:'text',
            type:'full',
            id:'merchandId',
            label:'ID de marchand(s) (requis)',
            labelPosition: 'left',
            name:'merchandId',
            placeholder:"Si vous en avez plusieurs séparer-les par un ;",
            value: merchandIds,
            setValue: setMerchandIds
        }
    ]
    
    const fetchGoogleClass = async () => {
        api.methods.get(apiBaseUrl.data().digitalFramework + endpoints.data().GetAllGoogleClassEndpoint).then((response =>{
                setDataGoogleClass(response.data)
            })).catch((error)=>{
                alert(error)
                console.log(error)
            })
        }
        
        useEffect(() => {
            fetchGoogleClass();
        }, []);
    
    const preFillCard = (event: SelectChangeEvent) => {
        let value = event.target.value as string
        if(value !== '' && value !== undefined){
            setDisplayContent(true)
        }
        else{
            setDisplayContent(false)
        }

        setCardClassId(value)
        let item = dataGoogleClass.find((item)=> item.classId === value)
        if(item !== undefined){
            setSelectedNbRows(item.nbRows)
            setSelectedNbRowsText(item.nbRows.toString())
            setNfcEnabled(item.enableSmartTap)
            setMerchandIds(formatIssuersValues(item.redemptionIssuers))
        }
        else {
            setSelectedNbRows(0)
            setSelectedNbRowsText('')
            setNfcEnabled(false)
            setMerchandIds('')
        }
    }

    function updateNfcCheckbox(){
        if(!nfcEnabled){
            setNfcEnabled(true)
        }
        else{
            setNfcEnabled(false)
        }
    }

    const updateRows= (event: SelectChangeEvent) => {
        let value = event.target.value as string
        let num = Number(value)

        setSelectedNbRowsText(value)
        setSelectedNbRows(num)
    }

    function initFormValues(){
        setCardClassId('')
        setSelectedNbRows(-1)
        setSelectedNbRowsText('')
        setNfcEnabled(false)
        setDisplayContent(false)
    }

    function buildRedemptionIssuerProperties(value: string){
        var collectionIssuers:Issuer[] = []
        if(value !== '' && value !== undefined){
            let issuers = value.split(';')
            if(issuers.length >= 1){
                issuers.forEach((issuer) =>{
                    if(issuer !== '' && issuer !== undefined){
                        let newIssuer:Issuer = {
                            Id: issuer
                        }
                        
                        collectionIssuers.push(newIssuer)
                    }
                })
            }
        }
        return collectionIssuers;
    }

    function isInputIssuerValuesCompliant(value: string){
        let result = true
        if(value !== '' && value !== undefined){
            let issuers = value.split(';')
            if(issuers.length >= 1){
                issuers.forEach((issuer) =>{
                    if(issuer === '' || issuer === undefined || !Number(issuer)){
                        result = false
                    }
                })
            }
        }
        return result
    }

    function checkInputRequired(){
        if(cardClassId === '' || selectedNbRowsText === ''){
            setDialogCardMessage('Veuillez renseigner les champs obligatoires (requis)')
            setDisplayDialog(true)
        }
        else if(nfcEnabled === true && (merchandIds === '' || !isInputIssuerValuesCompliant(merchandIds))){
            if(merchandIds === ''){
                setDialogCardMessage('Veuillez renseigner un ou plusieurs ID(s) de marchand (requis)')
            }
            else{
                setDialogCardMessage("Veuillez renseigner un ou plusieurs ID(s) de marchand sous le format d'un nombre entier uniquement")
            }
            setDisplayDialog(true)
        }
        else{
            UpdateNewGoogleClass()
        }
    }

    function formatIssuersValues(value: string){
        var result= ''
        const properties:Issuer[] = JSON.parse(value)
        if(properties !== null && properties !== undefined && properties.length >= 1){
            properties.forEach((element:Issuer, index, properties) => {
              if(properties.length - 1 === index){
                result += element.Id
              }
              else{
                result += element.Id + ';'
              }
          });
        }
    
        return result
    }

    function formatPassStyl(){
        let result = ''
        let style = dataGoogleClass.find((item)=> item.classId === cardClassId)?.style
        
        if(style != null && style != undefined){
            switch(style){
                case 0 : result = 'Générique'
                        break
                default : result = 'Indéfini'
                        break
            }
        }
        else{
            result = 'Indéfini'
        }

        return result
    }

    function UpdateNewGoogleClass(){
        let classSend
        let rowsArray
        //build class to send depend on nbRows and NFC 
        switch(selectedNbRows){
            case 0: 
                break;
            case 1:
                rowsArray = [
                    {
                        threeItems: {
                            fieldNameLeft : { id:'Row1ItemLeft' },
                            fieldNameMiddle : { id: 'Row1ItemMiddle'},
                            fieldNameRight : { id: 'Row1ItemRight'}
                        }
                    }
                ]
                break;
            case 2:
                rowsArray = [
                    {
                        threeItems: {
                            fieldNameLeft : { id:'Row1ItemLeft' },
                            fieldNameMiddle : { id: 'Row1ItemMiddle'},
                            fieldNameRight : { id: 'Row1ItemRight'}
                        }
                    },
                    {
                        threeItems: {
                            fieldNameLeft : { id:'Row2ItemLeft' },
                            fieldNameMiddle : { id: 'Row2ItemMiddle'},
                            fieldNameRight : { id: 'Row2ItemRight'}
                        }
                    }
                ]
                break;
            case 3:
                rowsArray = [
                    {
                        threeItems: {
                            fieldNameLeft : { id:'Row1ItemLeft' },
                            fieldNameMiddle : { id: 'Row1ItemMiddle'},
                            fieldNameRight : { id: 'Row1ItemRight'}
                        }
                    },
                    {
                        threeItems: {
                            fieldNameLeft : { id:'Row2ItemLeft' },
                            fieldNameMiddle : { id: 'Row2ItemMiddle'},
                            fieldNameRight : { id: 'Row2ItemRight'}
                        }
                    },
                    {
                        threeItems: {
                            fieldNameLeft : { id:'Row3ItemLeft' },
                            fieldNameMiddle : { id: 'Row3ItemMiddle'},
                            fieldNameRight : { id: 'Row3ItemRight'}
                        }
                    }
                ]
                break;
            default:
                break;
        }

        classSend = {
            classId: cardClassId,
            style: classStyle,
            nbRows: selectedNbRows,
            rows: rowsArray != undefined && rowsArray.length > 0 ? rowsArray : null,
            enableSmartTap: nfcEnabled,
            redemptionIssuers: merchandIds !== '' ? buildRedemptionIssuerProperties(merchandIds) : ''
        }

        api.methods.put(apiBaseUrl.data().digitalFramework + endpoints.data().UpdateGoogleClassEndpoint, classSend).then(() =>{
            setDialogCardMessage("Le modèle de carte \"" + cardClassId + "\" vient d'être modifié")
            setDisplayDialog(true)
            initFormValues()
            fetchGoogleClass()
          }).catch((error)=>{
              //alert(error)
              console.log(error)
              setDialogCardMessage("Une erreur est survenue : \"" + error)
              setDisplayDialog(true)
        })
    }

  return (
    <>
    <Dialog type='main' display={displayDialog} setDisplay={setDisplayDialog} title='AGL - Modèle de carte' text={dialogCardMessage}>customdialog</Dialog>
     <BandSideContainer>
      <Description>
	    <center>
            <FormControl sx={{ m: 1,width:'25ch' }} variant="standard">
                <InputLabel id="pass-label">Modèle de carte</InputLabel>
                <Select
                    labelId="pass-label"
                    id="pass-select"
                    value={cardClassId}
                    label="Modèle de carte"
                    onChange={preFillCard}
                >
                    {dataGoogleClass.map((item, index = 0)=><MenuItem key={index++} value={item.classId}>{item.classId}</MenuItem>)}
                </Select>
            </FormControl>
        
            { displayContent ? 
                <>
                <br/><br/>
                {/* <label htmlFor="cardType-choice">Type de la carte :</label>&nbsp;
                <InputMedium id="cardType-choice" name="cardType-choice" value="Générique" disabled /> */}
                <FormControl sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="demo-customized-textbox">Style de carte</InputLabel>
                    <BootstrapInput id="demo-customized-textbox" value={formatPassStyl()} disabled/>
                </FormControl>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <FormControl sx={{ m: 1,width:'10ch' }} variant="standard">
                    <InputLabel id="NbRows-label">Nb lignes</InputLabel>
                    <Select
                        labelId="NbRows-label"
                        id="NbRows-select"
                        value={selectedNbRowsText}
                        label="Nb lignes"
                        onChange={updateRows}>
                        {
                            dataListRows.map((item, index = 0)=><MenuItem key={index++} value={item}>{item}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <br/><br/>
                <Checkbox label='Activer le Smart Tap (NFC) ?' name='nfcEnabled' value='' checked={nfcEnabled} isChecked={updateNfcCheckbox}/>
                <br/><br/>
                { nfcEnabled === true ?
                    <>
                    {/* <center> */}
                        <b>Prérequis</b> : L'ID d'un marchand est nécessaire afin d'autoriser la communication entre 
                        <br/>le marchand et les futures cartes virtuelles qui hériteront de ce modèle de carte.
                        <br/>
                        <br/>
                        <Warning>Si vous n'avez pas encore d'ID(s) de marchand(s) alors il est inutile d'activer le NFC maintenant</Warning>
                    {/* </center> */}
                    <Form
                        title=""
                        first={COLUMNLEFTNFC}
                        type="main"
                        form="half" 
                        design="transparent"
                        corporate
                    >
                    </Form>
                    </> : null
                }
                <center>
                    <Button onClick={checkInputRequired}>Modifier</Button>
                </center>
            </> : null
            }
        </center>
      </Description>
      <RightBlock>
        {/* { nfcEnabled === true ?
           <> */}
           <br/><br/>
           <b>SmartTap : Fournisseurs de terminaux compatbiles</b>
            <ul>
                <li>Advanced Card Systems Ltd.</li>
                <li>Axess AG</li>
                <li>Technologie des châteaux</li>
                <li>Contactless Technologies B.V.</li>
                <li>Dot Origin</li>
                <li>Embed International</li>
                <li>Équinoxe</li>
                <li>HID</li>
                <li>IDTech</li>
                <li>Périphériques infinis</li>
                <li>Ingenico</li>
                <li>Intercard Inc.</li>
                <li>Janam</li>
                <li>Innovations en phase</li>
                <li>Pax</li>
                <li>PinvAccess</li>
                <li>Réseau Radius</li>
                <li>Skidata</li>
                <li>Socket Mobile</li>
                <li>SpringCard</li>
                <li>Techsigno SRL</li>
                <li>UIC Payworld Inc.</li>
                <li>Verifone</li>
                <li>XAC</li>
            </ul>
            <br/>
           {/* </> : null
        } */}
      </RightBlock>
    </BandSideContainer>    
    </>
  );
}

const Warning = styled.div`
  color: #c82121;
`;

const InputMedium = styled.input`
  width: 100px;
  text-align: center;
`;

const InputShort = styled.input`
  width: 50px;
  text-align: center;
`;

const BandSideContainer = styled.div`
  display: flex;
`;

// const Img = styled.img`
//   position: relative;
//   left: 50px;
// `;

const RightBlock = styled.div`
  position: relative;
  top: -50px;
  right: 100px;
  min-height: 300px;
  width: 410px;
`;

const Description = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 410px);
  min-height: 500px;
`;

const BootstrapInput = styled(InputBase)(() => ({
    'label + &': {
      marginTop: useTheme().spacing(2),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      textAlign: 'center',
      width: '150px',
      height:'10px',
      position: 'relative',
      backgroundColor: useTheme().palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: useTheme().transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }));