import { useState } from 'react'
import { styled } from 'styled-components'
import Layout from '../../containers/Layout'
import Title from '../../components/Text/Title';
import TabList from '../../components/Tab/TabList'
import TabContent from '../../components/Tab/TabContent'
import Container from '../../containers/Container'
import QrCode from '../../contents/Forms/QrCode'
import MENU from '../../data/menu'
import GoogleWallet from '../../contents/Forms/GoogleWallet';
import GoogleWalletLogo from '../../assets/wallet/google/frFR_google_wallet_logo.png'
import AppleWallet from '../../contents/Forms/AppleWallet'
import AppleWalletLogo from '../../assets/wallet/apple/frFR_apple_wallet_logo.png'
import QrCodeLogo from '../../assets/logo/qrcode_logo.png'

const TABS=[{
  label: 'QR Code',
  logo: QrCodeLogo
},{
  label: 'Google Wallet',
  logo: GoogleWalletLogo
},{
  label: 'Apple Wallet',
  logo: AppleWalletLogo
}
]

const MENU_LINKS = {
  links: MENU,
  type: "main",
  subType: "main"
}

function DigitalFeaturesHome() {
  const [active, setActive] = useState("QR Code")

  return (
    <div>
      <Layout
        type="general"
        menu={MENU_LINKS}
        active="DigitalFeaturesHome">
          <ColumnContainer>
            <Title type='emphasis' size='title' label='Digital Features' align='center' color='general' />
            <Title type='general' size='subtitle' label='Sélectionner le service que vous souhaitez utiliser' align='center' />
            <Container type="main" design="main">
              {/* <Title type='emphasis' size='title' label="Demo" align='center' color='main'/> */}
              <TabList 
                active={active}
                setActive={setActive}
                tabList={TABS}
                width='small' 
              >
                <TabContent active={active===TABS[0].label}>
                <QrCode />
                </TabContent>
                <TabContent active={active===TABS[1].label}>
                  <GoogleWallet/>
                </TabContent>
                <TabContent active={active===TABS[2].label}>
                  <AppleWallet/>
                </TabContent>
              </TabList>
            </Container>
          </ColumnContainer>
      </Layout>
     
    </div>
  );
}

export default DigitalFeaturesHome;

const ColumnContainer = styled.div`
  position: relative;
  top: 70px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
`;
