import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react'
import Button from "./components/Button"
import { IdTokenData } from './page/Authentication/Auth'
import { loginRequest } from './mixins/authConfig'
import Title from './components/Text/Title'
import Logo from './components/Illustration/Logo'

const MainContent = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()

  const handleRedirect = () => {
      instance
          .loginRedirect({
              ...loginRequest,
              prompt: 'create',
          })
          .catch((error) => console.log(error))
  };
 
  return (
    <>
        <AuthenticatedTemplate>
            {activeAccount ? (
                <IdTokenData />
            ) : null}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <br/><br/>
            <center>
            <Logo type="main" />
            <Title type='emphasis' size='title' label='Digital Features' align='center' color='main' />
            <br/>
            <Button type='general' label="Login" onClick={handleRedirect} />
            </center>
        </UnauthenticatedTemplate>
    </>
  );
}

 /**
  * msal-react is built on the React context API and all parts of your app that require authentication must be 
  * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication 
  * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the 
  * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
  * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
  */
 const App = ({ instance }) => {
    
  return (
      <MsalProvider instance={instance}>
          <MainContent />
      </MsalProvider>
  );
}

export default App;