const MENU = [
    {
      label: 'DigitalFeaturesHome',
      href: '/',
      subLinks: [
        {
          label: 'Sous-Menu',
          href: '/'
        },
        {
          label: 'Sous-Menu',
          href: '/'
        },
        {
          label: 'Sous-Menu',
          href: '/'
        },
      ]
    },
    // {
    //   label: 'Documentation',
    //   href: '/documentation'
    // },
    // {
    //   label: 'Badges',
    //   href: '/badges'
    // },
    // {
    //   label: 'Candidatures',
    //   href: '/candidatures'
    // },
    // {
    //   label: 'Analyses',
    //   href: '/analyses'
    // },
]
  

export default MENU
