import React from 'react';
import { styled } from 'styled-components';
import THEME from '../../../styles/theme';

interface ITitleProps {
    type?: string
    size?: string
    color?: string
    variant?: string
    label: string
    align?: string
    onClick?: (args: any) => void
}

interface ITitleContainerProps {
  type?: string
  size?: string
  variant?: string
  align?: string
}

function Title(props: ITitleProps) {
  return (
    <TitleContainer 
      type={props.type}
      size={props.size}
      color={props.color} 
      variant={props.variant} 
      onClick={props.onClick}
      align={props.align}
    >
      {props.label}
    </TitleContainer>
  );
}

export default Title;

const TitleContainer = styled.div<ITitleContainerProps>`
    font-weight: ${
        props => props.type === 'main'? THEME.text.weight.main : 
        props => props.type === 'general'? THEME.text.weight.general : 
        props => props.type === 'emphasis'? THEME.text.weight.emphasis : THEME.text.weight.general};
    font-size: ${
        props => props.size === 'title'? THEME.text.size.title : 
        props => props.size === 'subtitle'? THEME.text.size.subtitle : THEME.text.size.title};
    color: ${
        props => props.color === 'main'? THEME.text.color.main : 
        props => props.color === 'general'? THEME.text.color.general : 
        props => props.color === 'emphasis'? THEME.text.color.main : 
        props => props.color === 'primary'? THEME.text.color.primary : THEME.text.color.general};
    margin: 5px 0;
    border-bottom: ${props => props.variant === 'section' && `1px solid ${THEME.border.color.diverse}`};
    text-decoration: ${props => props.variant === 'underline' && 'underline'};
    text-underline-offset: ${props => props.variant === 'underline' && '5px'};
    text-align: ${props => props.align? props.align : 'left'}
`;
