import { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { styled as styledComponent } from 'styled-components'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import endpoints from '../../../../mixins/endpoints'
import api from '../../../../mixins/api'
import apiBaseUrl from '../../../../mixins/apiBaseUrl'
import ApplePass from '../../../../models/ApplePass'
import AppleWalletAddCard from '../../../../assets/wallet/apple/frFR_add_to_apple_wallet_card.png'
import Button from "@mui/material/Button";
import Dialog from '../../../../components/Modals/Dialog';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1B365F",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function DisplayPass() {
  const [dataApplePass, setDataApplePass] = useState<ApplePass[]>([]);
  const [dataApplePassArchived, setDataApplePassArchived] = useState<ApplePass[]>([]);
  const [displayDialog, setDisplayDialog] = useState(false)
  const [dialogMessage, setDialogMessage] = useState("")
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageArchived, setPageArchived] = useState(0);
  const [rowsPerPageArchived, setRowsPerPageArchived] = useState(10);

  useEffect(() => {
    fetchApplePass()
    fetchApplePassArchived()
  }, []);

  const fetchApplePass = async () => {
    api.methods.get(apiBaseUrl.data().digitalFramework+ endpoints.data().GetAllApplePassesEndpoint + '?&filter=IsArchived eq false').then((response =>{
        setDataApplePass(response.data)
    })).catch((error)=>{
        alert(error)
        console.log(error)
    })
  }

  const fetchApplePassArchived = async () => {
    api.methods.get(apiBaseUrl.data().digitalFramework+ endpoints.data().GetAllApplePassesEndpoint + '?&filter=IsArchived eq true').then((response =>{
        setDataApplePassArchived(response.data)
    })).catch((error)=>{
        alert(error)
        console.log(error)
    })
  }

  function styleDetail(style: number){
    let type = ''
    switch(style){
      case 0 :
        type = "Générique"
        break
      case 1 :
        type = "Carte d'embarquement"
        break
      case 2 :
        type = "Coupon"
        break
      case 3 :
        type = "Billet d'événement"
        break
      case 4 :
        type = "Carte de magasin" 
        break
      default:
        type = "Inconnu"
        break
    }
    
    return type
  }

  function NfcDisplay(value : boolean){
    var result = ''
    if(value){
        result = 'Activé'
    }
    else{
        result = 'Désactivé'
    }
    return result;
  }

  function openNewTab(storageId: string, passName: string) {
    if(navigator.platform === 'Win32'){
        setDialogMessage("L'ajout de la carte ne peut se faire qu'à partir d'un appareil provenant de la plateforme IOS")
        setDisplayDialog(true)
    }
    else{
        window.open(process.env.REACT_APP_BlobStorageUrl +"/apple-pkpass/"+ storageId + '/' + passName,"_blank");
    }
  }

  function formatDisplayName(value: string){
    return value.split('.')[0]
  }

  function formatDate(parameter:string, hideHour : boolean){
    var result = ''
    if(parameter !== '')
    { 
      if(hideHour){
        result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'long', day: 'numeric' });
      }else{
        result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' });
      }
    }
    return result;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePageArchived = (event: unknown, newPage: number) => {
    setPageArchived(newPage);
  };

  const handleChangeRowsPerPageArchived = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPageArchived(+event.target.value);
    setPageArchived(0);
  };

  return (
    <>
      <Dialog type='main' display={displayDialog} setDisplay={setDisplayDialog} title='AGL - Apple Wallet' text={dialogMessage}>customdialog</Dialog>
      <center>CARTES ACTIVES</center>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align='center'>Nom</StyledTableCell>
              <StyledTableCell align="center">Type</StyledTableCell>
              <StyledTableCell align='center'>NFC</StyledTableCell>
              <StyledTableCell align="center">Créé</StyledTableCell>
              <StyledTableCell align="center">Mise à jour</StyledTableCell>
              <StyledTableCell align='center'>Expire le</StyledTableCell>
              <StyledTableCell align='center'>Version</StyledTableCell>
              <StyledTableCell align='center'>Ajouter</StyledTableCell>
              <StyledTableCell align='center'>Lien</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataApplePass.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell align='center'>{formatDisplayName(row.passName)}</StyledTableCell>
                <StyledTableCell align="center">{styleDetail(row.style)}</StyledTableCell>
                <StyledTableCell align="center">{NfcDisplay(row.nfc)}</StyledTableCell>
                <StyledTableCell align="center">{formatDate(row.created, true)}</StyledTableCell>                
                <StyledTableCell align="center">{formatDate(row.lastUpdate ?? '', true)}</StyledTableCell>
                <StyledTableCell align="center">{formatDate(row.relevantDate, true)}</StyledTableCell>
                <StyledTableCell align="center">{row.version}</StyledTableCell>
                <StyledTableCell align="center">
                  <Button onClick={(e) =>{openNewTab(row.storageId, row.passName)}}>
                      <img src={AppleWalletAddCard} alt='' width={'125px'} />
                  </Button>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Button onClick={(e) =>{navigator.clipboard.writeText(process.env.REACT_APP_BlobStorageUrl +"/apple-pkpass/"+ row.storageId + '/' + row.passName)}}>
                      Copier le lien
                  </Button>
                 </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Align>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={dataApplePass.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={
            ({ from, to, count }) => {
              return '' + from + '-' + to + ' sur ' + count
            }
          }
          labelRowsPerPage="Lignes par page"
        />
      </Align>
      <br /><br />
      <center>CARTES INACTIVES<br/>
      <i>Note : Durée de rétention de 30 jours glissants avant suppression définitive</i>
      </center>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align='center'>Nom</StyledTableCell>
              <StyledTableCell align="center">Type</StyledTableCell>
              <StyledTableCell align='center'>NFC</StyledTableCell>
              <StyledTableCell align="center">Créé</StyledTableCell>
              <StyledTableCell align="center">Mise à jour</StyledTableCell>
              <StyledTableCell align='center'>Mise à jour par</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataApplePassArchived.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell align='center'>{formatDisplayName(row.passName)}</StyledTableCell>
                <StyledTableCell align="center">{styleDetail(row.style)}</StyledTableCell>
                <StyledTableCell align="center">{NfcDisplay(row.nfc)}</StyledTableCell>
                <StyledTableCell align="center">{formatDate(row.created, true)}</StyledTableCell>                
                <StyledTableCell align="center">{formatDate(row.lastUpdate, true)}</StyledTableCell>
                <StyledTableCell align="center">{row.lastUpdateBy}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Align>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={dataApplePassArchived.length}
          rowsPerPage={rowsPerPageArchived}
          page={pageArchived}
          onPageChange={handleChangePageArchived}
          onRowsPerPageChange={handleChangeRowsPerPageArchived}
          labelDisplayedRows={
            ({ from, to, count }) => {
              return '' + from + '-' + to + ' sur ' + count
            }
          }
          labelRowsPerPage="Lignes par page"
        />
      </Align>
    </>
  );
}

const Align = styledComponent.div`
p {
  margin-top: 13px
}
`;