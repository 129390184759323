import { useState} from 'react'
import TabListDropdrown from '../../../components/Tab/TabListDropdown'
import TabContent from '../../../components/Tab/TabContent'
import DisplayClass from './Class/Display'
import DisplayPass from './Object/Display'
import CreateClass from './Class/Create'
import CreatePass from './Object/Create'
import DisablePass from './Object/Disable'
import UpdatePass  from './Object/Update'
import UpdateClass from './Class/Update'


const TabsGeneral=[
  {
    label: "Modèles de carte"
  },
  {
    label: "Cartes virtuelles"
  }
]

const TabsChilds=[
    {
      parent: "Modèles de carte",
      label: "Disponibles",
      container:"DisplayClass"
    },
    {
      parent: "Modèles de carte",
      label: "Créer un modèle",
      container:"CreateClass"
    },
    {
      parent: "Modèles de carte",
      label: "Modifier un modèle",
      container:"UpdateClass"
    },
    {
      parent: "Cartes virtuelles",
      label: "Disponibles",
      container:"DisplayPass"
    },
    {
      parent: "Cartes virtuelles",
      label: "Créer une carte",
      container:"CreatePass"
    },
    {
      parent: "Cartes virtuelles",
      label: "Modifier une carte",
      container:"UpdatePass"
    },
    {
      parent: "Cartes virtuelles",
      label: "Désactiver une carte",
      container:"DisablePass"
    }
]
 
export default function GoogleWallet() {
  const [active, setActive] = useState("Cartes virtuelles")
  const [container, setContainer] = useState("DisplayPass")

  return (
    <>
      <TabListDropdrown 
        active={active}
        setActive={setActive}
        container={container}
        setContainer={setContainer}
        tabList={TabsGeneral} 
        childs={TabsChilds}
        width='medium'
        liwidth='30px'
        
      />
        <TabContent active={container==="DisplayClass"}>
          <DisplayClass/> 
        </TabContent> 
        <TabContent active={container==="CreateClass"}>
          <CreateClass /> 
        </TabContent>
        <TabContent active={container==="UpdateClass"}>
          <UpdateClass/>
        </TabContent> 
        <TabContent active={container==="DisplayPass"}>
          <DisplayPass /> 
        </TabContent>
        <TabContent active={container==="CreatePass"}>
          <CreatePass /> 
        </TabContent>
        <TabContent active={container==="UpdatePass"}>
          <UpdatePass /> 
        </TabContent>
        <TabContent active={container==="DisablePass"}>
          <DisablePass /> 
        </TabContent>
    </>
  );
}