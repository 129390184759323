import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { CssBaseline, ThemeProvider,} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { loginRequest } from '../../mixins/authConfig';
import DigitalFeaturesHome from '../DigitalFeaturesHome';


export const IdTokenData = () => {
    const keys = JSON.parse(sessionStorage.getItem("msal.token.keys." + loginRequest.clientId));
    const idToken = JSON.parse(sessionStorage.getItem(keys["accessToken"]));
    if(sessionStorage.getItem("msal.idtoken") === null || sessionStorage.getItem("msal.idtoken") !== idToken["secret"]){
       sessionStorage.setItem("msal.idtoken", idToken["secret"]);
    }

    const theme = createTheme({
        palette: {
          primary: {
            light: "#63b8ff",
            main: "#0989e3",
            dark: "#005db0",
            contrastText: "#000",
          },
          secondary: {
            main: "#4db6ac",
            light: "#82e9de",
            dark: "#00867d",
            contrastText: "#000",
          },
        },
      });
    
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <Routes>
                <Route path='/' element={<DigitalFeaturesHome />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
};