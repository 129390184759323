import React from 'react';
import { styled } from 'styled-components';
import THEME from '../../../styles/theme';

interface IInputProps {
  type?: string
  input?: string
  id: string
  label: string
  labelPosition?: string
  name: string
  placeholder?: string
  value: string
  multiline?: boolean
  rows?: number
  required?: boolean
  setValue: React.Dispatch<React.SetStateAction<string>>
}

export type { IInputProps }

function Input(props: IInputProps) {
  return (
      <InputContainer type={props.type} label={props.labelPosition}>
        <Label>{props.label}</Label>
        <InputForm
          id={props.id}
          type={props.input? props.input : "text"}
          name={props.name}
          placeholder={props.placeholder ? props.placeholder : props.name}
          value={props.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.setValue(e.target.value)}
        />
      </InputContainer>
  );
}

export default Input;

interface IInputContainerProps {
  type?: string
  label?: string
}

const InputContainer = styled.div<IInputContainerProps>`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  text-align: ${props => props.label};
  width: ${props => props.type === "full" ? "100%" : props.type === "tiers" ? "30%" : "100%"};
`;

const Label = styled.div`
  padding: 5px 15px;
`;

const InputForm = styled.input`
  width: 100%;
  height: 45px;
  outline: 0;
  background: ${THEME.background.color.content};
  border: 0;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
`;
