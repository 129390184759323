import React from 'react';

interface IInputProps {
    label: string
    name: string
    value: string
    checked: boolean
    isChecked: (checked: boolean) => void
}

function Checkbox(props: IInputProps) {
  const handleCheck = () => {
    props.isChecked(props.checked)
  };
  return (
    <label>{props.label} <input type="checkbox" name={props.name} value={props.value} checked={props.checked} onChange={handleCheck} onClick={handleCheck}/></label>
  );
}

export default Checkbox;