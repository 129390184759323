import { useState, useEffect } from 'react';
import endpoints from '../../../../../mixins/endpoints';
import api from '../../../../../mixins/api';
import apiBaseUrl from '../../../../../mixins/apiBaseUrl';
import Dialog from '../../../../../components/Modals/Dialog'
import GoogleObject from '../../../../../models/GoogleObject'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from "@mui/material/Button";

export default function DisablePass() {
    const [dataGoogleObject, setDataGoogleObject] = useState<GoogleObject[]>([]);
    const [displayDialog, setDisplayDialog] = useState(false)
    const [dialogMessage, setDialogMessage] = useState("")
    const [objectId, setObjectId] = useState("")

    useEffect(() => {
      fetchGoogleObject();
    }, []);
  
    const fetchGoogleObject = async () => {
      api.methods.get(apiBaseUrl.data().digitalFramework+ endpoints.data().GetAllGoogleObjectEndpoint + '?&filter=State eq true').then((response =>{
        setDataGoogleObject(response.data)
      })).catch((error)=>{
          alert(error)
          console.log(error)
      })
    }

    const preFillCard = (event: SelectChangeEvent) => {
      let value = event.target.value as string
      
      setObjectId(value)
    }

    function checkInput(){
      if(objectId !== ''){
        disableGoogleObject()
      }
      else{
        setDialogMessage("Veuillez sélectionner une carte virtuelle")
        setDisplayDialog(true)
      }
    }

    function initValues(){
      setObjectId('')
    }

    function disableGoogleObject(){
      api.methods.put(apiBaseUrl.data().digitalFramework + endpoints.data().DisableGoogleObjectEndpoint + "?objectId=" + objectId).then(() =>{
        setDialogMessage("La carte \"" + objectId + "\" vient d'être désactivée")
        setDisplayDialog(true)
        initValues()
      }).catch((error)=>{
          //alert(error)
          console.log(error)
          setDialogMessage("Une erreur est survenue : \"" + error)
          setDisplayDialog(true)
      })
    }

  return (
    <>
        <Dialog type='main' display={displayDialog} setDisplay={setDisplayDialog} title='AGL - Carte virtuelle' text={dialogMessage}>customdialog</Dialog>
        <center>
        <FormControl sx={{ m: 1,width:'28ch' }} variant="standard">
            <InputLabel id="pass-label">Sélectionner une carte virtuelle</InputLabel>
            <Select
                labelId="pass-label"
                id="pass-select"
                value={objectId}
                label="Sélectionner une carte virtuelle"
                onChange={preFillCard}
            >
                {dataGoogleObject.map((item, index = 0)=><MenuItem key={index++} value={item.objectId}>{item.objectId}</MenuItem>)}
            </Select>
        </FormControl>
        <br/><br/>
        <Button onClick={checkInput}>Désactiver</Button>
        </center>
    </>
  );
}
