import React, { ReactNode } from 'react';
import styled from 'styled-components';
import THEME from '../../styles/theme';

interface ISimpleLayoutProps {
  type?: string
  children?: ReactNode
}

function SimpleLayout(props: ISimpleLayoutProps) {
  return (
    <LayoutContainer type={props.type}>{props.children}</LayoutContainer>
  );
}

export default SimpleLayout;

const LayoutContainer = styled.div<ISimpleLayoutProps>`
  background-color: ${props => props.type === 'main'? THEME.background?.color?.main: 
                      props.type === 'primary'? THEME.background?.color?.primary:
                      props.type === 'general'? THEME.background?.color?.general:
                      props.type === 'principal'? THEME.background?.color?.principal:
                      props.type === 'diverse'? THEME.background?.color?.diverse:
                      THEME.background?.color?.main};
  min-height: 100vh;
  padding-bottom: 50px;
`