import { useState, useEffect } from 'react'
import { IInputProps } from '../../../../../components/Forms/InputV2'
import endpoints from '../../../../../mixins/endpoints'
import api from '../../../../../mixins/api'
import apiBaseUrl from '../../../../../mixins/apiBaseUrl'
import Form from '../../../../../components/Forms/FormV2'
import GoogleWalletTemplateCard from '../../../../../assets/wallet/google/frFR_template_google_wallet_card.png'
import GoogleWalletTemplateCardMobile from '../../../../../assets/wallet/google/frFR_template_google_wallet_card_mobile.png'
import GoogleClass from '../../../../../models/GoogleClass'
import Dialog from '../../../../../components/Modals/Dialog'
import Checkbox from '../../../../../components/Forms/Checkbox'
import { styled } from 'styled-components';

import Button from "@mui/material/Button";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { useTheme } from '@mui/material/styles';
import { BrowserView, MobileView } from "react-device-detect";

export default function CreatePass() {
    const [dataGoogleClass, setDataGoogleClass] = useState<GoogleClass[]>([])
    const [displayDialog, setDisplayDialog] = useState(false)
    const [dialogCardMessage, setDialogCardMessage] = useState('')
    const [valididyCheckbox, setValidityCheckbox] = useState(false)
    const [usingWideLogo, setUsingWideLogo] = useState(false)

    const [hideInformationUsingWideLogo, setHideInformationUsingWideLogo] = useState(true)
    
    const [selectedNbRowsAvailable, setSelectedNbRowsAvailable] = useState(0)
    const [selectedNbRowsAvailableText, setSelectedNbRowsAvailableText] = useState('')
    const [selectedNbRows, setSelectedNbRows] = useState(0)
    
    const [templateCardSelected, setTemplateCardSelected] = useState('')
    const [cardClassId, setCardClassId] = useState('')
    const [cardObjectId, setCardObjectId] = useState('')
    const [cardTitle, setCardTitle] = useState('')
    const [cardSubHeader, setCardSubHeader] = useState('')
    const [cardHeader, setCardHeader] = useState('')
    const [cardLogoUri, setCardLogoUri] = useState('')
    const [cardHeroImage, setCardHeroImage] = useState('')

    const [cardBarcodeType, setCardBarcodeType] = useState(-1)
    const [cardBarcodeValue, setCardBarcodeValue] = useState('')
    const [cardBarcodeTypeText, setCardBarcodeTypeText] = useState('')
    const [cardBarcodeAlternateValue, setCardBarcodeAlternateValue] = useState('')
    const [cardBackgroundColor, setCardBackgroundColor] = useState('')
    const [cardValidityStart, setCardValidityStart] = useState('')
    const [cardValidityEnd, setCardValidityEnd] = useState('')

    const [row1CardItemLeftTitle, setRow1CardItemLeftTitle] = useState('')
    const [row1CardItemLeftValue, setRow1CardItemLeftValue] = useState('')
    const [row1CardItemMiddleTitle, setRow1CardItemMiddleTitle] = useState('')
    const [row1CardItemMiddleValue, setRow1CardItemMiddleValue] = useState('')
    const [row1CardItemRightTitle, setRow1CardItemRightTitle] = useState('')
    const [row1CardItemRightValue, setRow1CardItemRightValue] = useState('')

    const [row2CardItemLeftTitle, setRow2CardItemLeftTitle] = useState('')
    const [row2CardItemLeftValue, setRow2CardItemLeftValue] = useState('')
    const [row2CardItemMiddleTitle, setRow2CardItemMiddleTitle] = useState('')
    const [row2CardItemMiddleValue, setRow2CardItemMiddleValue] = useState('')
    const [row2CardItemRightTitle, setRow2CardItemRightTitle] = useState('')
    const [row2CardItemRightValue, setRow2CardItemRightValue] = useState('')

    const [row3CardItemLeftTitle, setRow3CardItemLeftTitle] = useState('')
    const [row3CardItemLeftValue, setRow3CardItemLeftValue] = useState('')
    const [row3CardItemMiddleTitle, setRow3CardItemMiddleTitle] = useState('')
    const [row3CardItemMiddleValue, setRow3CardItemMiddleValue] = useState('')
    const [row3CardItemRightTitle, setRow3CardItemRightTitle] = useState('')
    const [row3CardItemRightValue, setRow3CardItemRightValue] = useState('')

    const [nfcEnabled, setNfcEnabled] = useState(false)
    const [redemptionValue, setRedemptionValue] = useState('')
    
    const COLUMNLEFTBASE: IInputProps[] = 
    [
        {
            input:'text',
            type:'full',
            id:'objectid',
            required: true,
            label:'ID de la carte',
            labelPosition: 'left',
            name:'objectid',
            placeholder:'ID de la carte',
            value: formatCardId(cardObjectId),
            setValue: setCardObjectId
        },
        {
            input:'text',
            type:'full',
            id:'cardTitle',
            required: true,
            label:'Titre de la carte',
            labelPosition: 'left',
            name:'cardTitle',
            placeholder:'Titre de la carte',
            value: cardTitle,
            setValue: setCardTitle
        },
        {
            input:'text',
            type:'full',
            id:'cardSubHeader',
            label:"Titre du sous en-tête",
            labelPosition: 'left',
            name:'cardSubHeader',
            placeholder:"Titre du sous en-tête",
            value: cardSubHeader,
            setValue: setCardSubHeader
        },
        {
            input:'text',
            type:'full',
            id:'cardHeader',
            required: true,
            label:"Titre de l'en-tête",
            labelPosition: 'left',
            name:'cardHeader',
            placeholder:"Titre de l'en-tête",
            value: cardHeader,
            setValue: setCardHeader
        }
    ]
    const COLUMNRIGHTBASE: IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'cardLogoUri',
            label:'URI du logo',
            labelPosition: 'left',
            name:'cardLogoUri',
            placeholder:"Si vide alors le logo AGL sera appliqué par défaut",
            value: cardLogoUri,
            setValue: setCardLogoUri
        },
        {
            input:'text',
            type:'full',
            id:'cardHeroImage',
            label:"URI de l'image principale",
            labelPosition: 'left',
            name:'cardHeroImage',
            placeholder:"URI de l'image principale",
            value: cardHeroImage,
            setValue: setCardHeroImage
        },
        {
            input:'text',
            type:'full',
            id:'cardBackgroundColor',
            label:"Couleur de la carte au format hexadecimal",
            labelPosition: 'left',
            name:'cardBackgroundColor',
            placeholder:"Si vide alors la couleur AGL sera appliquée par défaut",
            value: cardBackgroundColor,
            setValue: setCardBackgroundColor
        }
    ]
    const COLUMNLEFTBARCODE:IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'cardBarcodeValue',
            label:"Valeur du code barre",
            labelPosition: 'left',
            name:'cardBarcodeValue',
            placeholder:"Valeur du code barre",
            value: cardBarcodeValue,
            setValue: setCardBarcodeValue
        }
    ]
    const COLUMNRIGHTBARCODE:IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'cardBarcodeAlternateValue',
            label:"Valeur alternative du code barre",
            labelPosition: 'left',
            name:'cardBarcodeAlternateValue',
            placeholder:"Valeur alternative du code barre",
            value: cardBarcodeAlternateValue,
            setValue: setCardBarcodeAlternateValue
        }
    ]
    const COLUMNLEFTVALIDITY: IInputProps[] = 
    [
        {
            input:'date',
            type:'full',
            id:'cardValidityStart',
            label:'Date de validité',
            labelPosition: 'left',
            name:'cardValidityStart',
            //placeholder:'Date de validité',
            value: cardValidityStart,
            setValue: setCardValidityStart
        }
    ]
    const COLUMNRIGHTVALIDITY: IInputProps[] = 
    [
        {
            input:'date',
            type:'full',
            id:'cardValidityEnd',
            label:"Date d'expiration (requis)",
            labelPosition: 'left',
            name:'cardValidityEnd',
            //placeholder:"Date d'expiration",
            value: cardValidityEnd,
            setValue: setCardValidityEnd
        }
    ]
    const COLUMNNFC: IInputProps[] = 
    [
        {
            input:'text',
            type:'full',
            id:'redemptionValue',
            label:'Code NFC',
            labelPosition: 'left',
            name:'redemptionValue',
            placeholder:'Valeur qui sera envoyée aux terminaux NFC comptatibles',
            value: redemptionValue,
            setValue: setRedemptionValue
        }
    ]
    const ROW1COLUMNLEFTTHREEITEMS : IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'cardItemLeftTitle',
            label:"Titre de l'élément gauche",
            labelPosition: 'left',
            name:'cardItemLeftTitle',
            placeholder:"Titre de l'élément gauche",
            value: row1CardItemLeftTitle,
            setValue: setRow1CardItemLeftTitle
        }
        ,
        {
            input:'text',
            type:'full',
            id:'cardItemMiddleTitle',
            label:"Titre de l'élément milieu",
            labelPosition: 'left',
            name:'cardItemMiddleTitle',
            placeholder:"Titre de l'élément milieu",
            value: row1CardItemMiddleTitle,
            setValue: setRow1CardItemMiddleTitle
        }
        ,
        {
            input:'text',
            type:'full',
            id:'cardItemRightTitle',
            label:"Titre de l'élément droit",
            labelPosition: 'left',
            name:'cardItemRightTitle',
            placeholder:"Titre de l'élément droit",
            value: row1CardItemRightTitle,
            setValue: setRow1CardItemRightTitle
        }
    ]
    const ROW1COLUMNRIGHTTHREEITEMS : IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'cardItemLeftValue',
            label:"Valeur de l'élément gauche",
            labelPosition: 'left',
            name:'cardItemLeftValue',
            placeholder:"Valeur de l'élément gauche",
            value: row1CardItemLeftValue,
            setValue: setRow1CardItemLeftValue
        },
        {
            input:'text',
            type:'full',
            id:'cardItemMiddleValue',
            label:"Valeur de l'élément milieu",
            labelPosition: 'left',
            name:'cardItemMiddleValue',
            placeholder:"Valeur de l'élément milieu",
            value: row1CardItemMiddleValue,
            setValue: setRow1CardItemMiddleValue
        },
        {
            input:'text',
            type:'full',
            id:'cardItemRightValue',
            label:"Valeur de l'élément droit",
            labelPosition: 'left',
            name:'cardItemRightValue',
            placeholder:"Valeur de l'élément droit",
            value: row1CardItemRightValue,
            setValue: setRow1CardItemRightValue
        },
    ]
    const ROW2COLUMNLEFTTHREEITEMS : IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'cardItemLeftTitle',
            label:"Titre de l'élément gauche",
            labelPosition: 'left',
            name:'cardItemLeftTitle',
            placeholder:"Titre de l'élément gauche",
            value: row2CardItemLeftTitle,
            setValue: setRow2CardItemLeftTitle
        }
        ,
        {
            input:'text',
            type:'full',
            id:'cardItemMiddleTitle',
            label:"Titre de l'élément milieu",
            labelPosition: 'left',
            name:'cardItemMiddleTitle',
            placeholder:"Titre de l'élément milieu",
            value: row2CardItemMiddleTitle,
            setValue: setRow2CardItemMiddleTitle
        }
        ,
        {
            input:'text',
            type:'full',
            id:'cardItemRightTitle',
            label:"Titre de l'élément droit",
            labelPosition: 'left',
            name:'cardItemRightTitle',
            placeholder:"Titre de l'élément droit",
            value: row2CardItemRightTitle,
            setValue: setRow2CardItemRightTitle
        }
    ]
    const ROW2COLUMNRIGHTTHREEITEMS : IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'cardItemLeftValue',
            label:"Valeur de l'élément gauche",
            labelPosition: 'left',
            name:'cardItemLeftValue',
            placeholder:"Valeur de l'élément gauche",
            value: row2CardItemLeftValue,
            setValue: setRow2CardItemLeftValue
        },
        {
            input:'text',
            type:'full',
            id:'cardItemMiddleValue',
            label:"Valeur de l'élément milieu",
            labelPosition: 'left',
            name:'cardItemMiddleValue',
            placeholder:"Valeur de l'élément milieu",
            value: row2CardItemMiddleValue,
            setValue: setRow2CardItemMiddleValue
        },
        {
            input:'text',
            type:'full',
            id:'cardItemRightValue',
            label:"Valeur de l'élément droit",
            labelPosition: 'left',
            name:'cardItemRightValue',
            placeholder:"Valeur de l'élément droit",
            value: row2CardItemRightValue,
            setValue: setRow2CardItemRightValue
        },
    ]
    const ROW3COLUMNLEFTTHREEITEMS : IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'cardItemLeftTitle',
            label:"Titre de l'élément gauche",
            labelPosition: 'left',
            name:'cardItemLeftTitle',
            placeholder:"Titre de l'élément gauche",
            value: row3CardItemLeftTitle,
            setValue: setRow3CardItemLeftTitle
        }
        ,
        {
            input:'text',
            type:'full',
            id:'cardItemMiddleTitle',
            label:"Titre de l'élément milieu",
            labelPosition: 'left',
            name:'cardItemMiddleTitle',
            placeholder:"Titre de l'élément milieu",
            value: row3CardItemMiddleTitle,
            setValue: setRow3CardItemMiddleTitle
        }
        ,
        {
            input:'text',
            type:'full',
            id:'cardItemRightTitle',
            label:"Titre de l'élément droit",
            labelPosition: 'left',
            name:'cardItemRightTitle',
            placeholder:"Titre de l'élément droit",
            value: row3CardItemRightTitle,
            setValue: setRow3CardItemRightTitle
        }
    ]
    const ROW3COLUMNRIGHTTHREEITEMS : IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'cardItemLeftValue',
            label:"Valeur de l'élément gauche",
            labelPosition: 'left',
            name:'cardItemLeftValue',
            placeholder:"Valeur de l'élément gauche",
            value: row3CardItemLeftValue,
            setValue: setRow3CardItemLeftValue
        },
        {
            input:'text',
            type:'full',
            id:'cardItemMiddleValue',
            label:"Valeur de l'élément milieu",
            labelPosition: 'left',
            name:'cardItemMiddleValue',
            placeholder:"Valeur de l'élément milieu",
            value: row3CardItemMiddleValue,
            setValue: setRow3CardItemMiddleValue
        },
        {
            input:'text',
            type:'full',
            id:'cardItemRightValue',
            label:"Valeur de l'élément droit",
            labelPosition: 'left',
            name:'cardItemRightValue',
            placeholder:"Valeur de l'élément droit",
            value: row3CardItemRightValue,
            setValue: setRow3CardItemRightValue
        },
    ]
    
    const dataListOneItem = ["0", "1"]
    const dataListTwoItems = ["0", "1", "2"]
    const dataListThreeItems = ["0", "1", "2", "3"]

    const fetchGoogleClass = async () => {
        api.methods.get(apiBaseUrl.data().digitalFramework + endpoints.data().GetAllGoogleClassEndpoint).then((response =>{
                setDataGoogleClass(response.data)
            })).catch((error)=>{
                alert(error)
                console.log(error)
            })
        }
        
    useEffect(() => {
        fetchGoogleClass()
        initFormValues()
    }, []);
    
    const updateCardClassId = (event: SelectChangeEvent) => {
        let value = event.target.value as string
        setCardClassId(value)
        setTemplateCardSelected(value)
        
        setSelectedNbRowsAvailable(dataGoogleClass.find((item)=> item.classId === value)?.nbRows || 0)
        setSelectedNbRowsAvailableText('')
        setSelectedNbRows(0)

        setNfcEnabled(dataGoogleClass.find((item)=> item.classId === value)?.enableSmartTap || false)
    } 
    
    const updateBarcodeType= (event: SelectChangeEvent) => {
        let value = event.target.value as string
        setCardBarcodeType(Number(value))
        setCardBarcodeTypeText(value)
    }

    function getDate(addMonth? : number){
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
      
        let dayStr = ''
        let monthStr = ''
        let extraMonths = addMonth ? addMonth : 0
      
        if(day < 10){
          dayStr = '0' + day
        }
        else{
          dayStr = day.toString()
        }
      
        if(month + extraMonths < 10)
        {
            monthStr = '0' + month
        }
        else{
            if(month + extraMonths > 12){
                let newMonth = month + extraMonths - 12
                if(newMonth < 10){
                    monthStr = '0' + newMonth
                }
                else{
                    monthStr = newMonth.toString()
                }
                year = year + 1
            }
            else{
                monthStr = month.toString()
            }
        }
      
        return year + '-' + monthStr + '-' + dayStr
      }
    
    function updateValidityCheckbox(){
        if(!valididyCheckbox){
            setValidityCheckbox(true)
            setCardValidityStart(getDate())
            setCardValidityEnd(getDate(6))
        }
        else{
            setValidityCheckbox(false)
            setCardValidityStart('')
            setCardValidityEnd('')
        }
    }

    function updateWideLogoCheckbox(){
        if(!usingWideLogo){
            setUsingWideLogo(true)
            setHideInformationUsingWideLogo(false)
        }
        else{
            setUsingWideLogo(false)
            setHideInformationUsingWideLogo(true)
        }
    }

    const updateRowsDisplay = (event: SelectChangeEvent) => {
        let value = event.target.value as string
        let num = Number(value)

        setSelectedNbRowsAvailableText(value)
        setSelectedNbRows(num)
    }

    function initFormValues(){
        setCardClassId('')
        setCardObjectId('')
        setCardLogoUri('')
        setCardHeroImage('')
        setCardBackgroundColor('')
        setCardTitle('')
        setCardSubHeader('')
        setCardHeader('')
        
        setCardBarcodeType(0)
        setCardBarcodeTypeText('')
        setSelectedNbRowsAvailableText('')

        setCardBarcodeValue('')
        setCardBarcodeAlternateValue('')
        
        setCardValidityStart('')
        setCardValidityEnd('')
        setSelectedNbRows(-1)
        
        setValidityCheckbox(false)
        setUsingWideLogo(false)
        setHideInformationUsingWideLogo(true)
        setRedemptionValue('')
    }

    function formatNfcValue(){
        let result = ''
        let enabled = dataGoogleClass.find((item)=> item.classId === cardClassId)?.enableSmartTap
        
        if(enabled !== null && enabled !== undefined){
            if(enabled){
                result = 'Activé'
            }else{
                result = 'Désactivé'
            }
        }
        else{
            result = ' '
        }

        return result
    }

    function nfcDisplay(){
        let result = false
        let enabled = dataGoogleClass.find((item)=> item.classId === cardClassId)?.enableSmartTap
        
        if(enabled !== null && enabled !== undefined){
            result = enabled
        }

        return result
    }

    // function formatDisplayName(value: string){
    //     return value.split('.')[0]
    // }

    function checkInputRequired(){
        if(cardClassId === '' || cardTitle === '' || cardObjectId === '' || cardBarcodeType === -1 || cardHeader === ''){
            setDialogCardMessage('Veuillez renseigner les champs obligatoires (requis)')
            setDisplayDialog(true)
        }
        else if(valididyCheckbox && (cardValidityStart === '' || cardValidityEnd === '')){
            setDialogCardMessage('Veuillez renseigner la période de validité (requis)')
            setDisplayDialog(true)
        }
        else{
            createNewGoogleObject()
        }
    }

    function formatCardId(value: string){
        let result = ''
        if(value !== ''){
            result = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "").toLowerCase()
        }
        return result
    }

    function createNewGoogleObject(){
        let objectSend;
        let rowsArray;
        //build object to send depend on nbRows and validity

        switch(selectedNbRows){
            case 0:
                break;
            case 1:
                rowsArray = [
                    {
                        threeItems: {
                            fieldNameLeft : { header: row1CardItemLeftTitle, body: row1CardItemLeftValue},
                            fieldNameMiddle : { header: row1CardItemMiddleTitle, body: row1CardItemMiddleValue},
                            fieldNameRight : { header: row1CardItemRightTitle, body: row1CardItemRightValue}
                        }
                    }
                ]
                break;
            case 2:
                rowsArray = [
                    {
                        threeItems: {
                            fieldNameLeft : { header: row1CardItemLeftTitle, body: row1CardItemLeftValue},
                            fieldNameMiddle : { header: row1CardItemMiddleTitle, body: row1CardItemMiddleValue},
                            fieldNameRight : { header: row1CardItemRightTitle, body: row1CardItemRightValue}
                        }
                    },
                    {
                        threeItems: {
                            fieldNameLeft : { header: row2CardItemLeftTitle, body: row2CardItemLeftValue},
                            fieldNameMiddle : { header: row2CardItemMiddleTitle, body: row2CardItemMiddleValue},
                            fieldNameRight : { header: row2CardItemRightTitle, body: row2CardItemRightValue}
                        }
                    }
                ]
                break;
            case 3:
                rowsArray = [
                    {
                        threeItems: {
                            fieldNameLeft : { header: row1CardItemLeftTitle, body: row1CardItemLeftValue},
                            fieldNameMiddle : { header: row1CardItemMiddleTitle, body: row1CardItemMiddleValue},
                            fieldNameRight : { header: row1CardItemRightTitle, body: row1CardItemRightValue}
                        }
                    },
                    {
                        threeItems: {
                            fieldNameLeft : { header: row2CardItemLeftTitle, body: row2CardItemLeftValue},
                            fieldNameMiddle : { header: row2CardItemMiddleTitle, body: row2CardItemMiddleValue},
                            fieldNameRight : { header: row2CardItemRightTitle, body: row2CardItemRightValue}
                        }
                    },
                    {
                        threeItems: {
                            fieldNameLeft : { header: row3CardItemLeftTitle, body: row3CardItemLeftValue},
                            fieldNameMiddle : { header: row3CardItemMiddleTitle, body: row3CardItemMiddleValue},
                            fieldNameRight : { header: row3CardItemRightTitle, body: row3CardItemRightValue}
                        }
                    }
                ]
                break;
            default:
                break;
        }

        objectSend = {
            classId: cardClassId,
            objectId: cardObjectId,
            title: cardTitle ?? '',
            wideLogo: usingWideLogo,
            logoUri: cardLogoUri,
            heroImageUri: cardHeroImage ?? '',
            backgroundColor: cardBackgroundColor ?? '',
            subHeader: cardSubHeader ?? '',
            header: cardHeader ?? '',
            barcodeType: cardBarcodeType ?? '', 
            barcodeValue: cardBarcodeValue ?? '',
            barcodeValueAlternativeText: cardBarcodeAlternateValue ?? '',
            rows: rowsArray != null && rowsArray.length > 0 ? rowsArray : null,
            redemptionValue : redemptionValue ?? '',
            validityStart: cardValidityStart !== '' ? cardValidityStart : null,
            validityEnd: cardValidityEnd !== '' ? cardValidityEnd : null
        }

        api.methods.post(apiBaseUrl.data().digitalFramework + endpoints.data().NewGoogleObjectEndpoint, objectSend).then(() =>{
            setDialogCardMessage("La carte \"" + cardTitle + "\" vient d'être créée")
            setDisplayDialog(true)
            initFormValues()
          }).catch((error)=>{
              //alert(error)
              console.log(error)
              setDialogCardMessage("Une erreur est survenue : \"" + error)
              setDisplayDialog(true)
        })
    }

  return (
    <>
        <Dialog type='main' display={displayDialog} setDisplay={setDisplayDialog} title='AGL - Carte virtuelle' text={dialogCardMessage}>customdialog</Dialog>
        <center>
            <BrowserView>
                <img src={GoogleWalletTemplateCard} alt='template Google wallet card' width={650} height={300}/>
            </BrowserView>
            <MobileView>
                <img src={GoogleWalletTemplateCardMobile} alt='template Google wallet card' width={300} height={250}/>
            </MobileView>
            <br/><br/>
             <FormControl sx={{ m: 1,width:'25ch' }} variant="standard">
                <InputLabel id="passStyl-label">Modèle de carte</InputLabel>
                <Select
                    labelId="passStyl-label"
                    id="passStyl-select"
                    value={templateCardSelected}
                    label="Modèle de carte"
                    onChange={updateCardClassId}
                >
                    {dataGoogleClass.map((item, index = 0)=><MenuItem key={index++} value={item.classId}>{item.classId}</MenuItem>)}
                </Select>
            </FormControl>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <FormControl sx={{ m: 1,width:'20ch' }} variant="standard">
                <InputLabel id="barcode-label">Type code barre</InputLabel>
                <Select
                    labelId="barcode-label"
                    id="barcode-select"
                    value={cardBarcodeTypeText}
                    label="Type code barre"
                    onChange={updateBarcodeType}
                >
                    <MenuItem key={0} value={'10'}>Format QR</MenuItem>
                    <MenuItem key={1} value={'3'}>Format Code128</MenuItem>
                    <MenuItem key={2} value={'12'}>Format Texte</MenuItem>
                </Select>
            </FormControl>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <FormControl sx={{ m: 1,width:'10ch' }} variant="standard">
                <InputLabel id="NbRows-label">Nb lignes</InputLabel>
                <Select
                    labelId="NbRows-label"
                    id="NbRows-select"
                    value={selectedNbRowsAvailableText}
                    label="Nb lignes"
                    onChange={updateRowsDisplay}
                >
                    {
                        selectedNbRowsAvailable === 1 ? dataListOneItem.map((item, index = 0)=><MenuItem key={index++} value={item}>{item}</MenuItem>)
                        : selectedNbRowsAvailable === 2 ? dataListTwoItems.map((item, index = 0)=><MenuItem key={index++} value={item}>{item}</MenuItem>)
                        : selectedNbRowsAvailable === 3 ? dataListThreeItems.map((item, index = 0)=><MenuItem key={index++} value={item}>{item}</MenuItem>)
                        : null
                    }
                </Select>
            </FormControl>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <FormControl sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="demo-customized-textbox">Compatibilité NFC</InputLabel>
                <BootstrapInput id="demo-customized-textbox" value={formatNfcValue()} disabled/>
            </FormControl>
            <br /><br />
            <Checkbox label='Appliquer une période de validité ?' name='validity' value='' checked={valididyCheckbox} isChecked={updateValidityCheckbox}/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Checkbox label='Utiliser un logo élargit' name='wideLogo' value='' checked={usingWideLogo} isChecked={updateWideLogoCheckbox}/>
            <div hidden={hideInformationUsingWideLogo} id='WarningWideLogo'>
                <Warning>
                    <br />
                    <b>Information:</b> L'utilisation d'un logo élargit <b>masque automatiquement</b> le titre de la carte
                </Warning>
            </div>
            { nfcDisplay() ?
                <Form
                    title=""
                    first={COLUMNNFC}
                    type="main"
                    form="half" 
                    design="transparent"
                    corporate
                >
                </Form> : null
            }
            { valididyCheckbox ?
                <Form
                    title="Validité"
                    titleSmallSize={true}
                    first={COLUMNLEFTVALIDITY}
                    second={COLUMNRIGHTVALIDITY}
                    type="main"
                    form="full" 
                    design="transparent"
                    corporate
                >
                </Form> : null
            }
            <Form
                title=""
                first={COLUMNLEFTBASE}
                second={COLUMNRIGHTBASE}
                type="main"
                form="full" 
                design="transparent"
                corporate
            />
            <Form
                title="Code barre"
                titleSmallSize={true}
                first={COLUMNLEFTBARCODE}
                second={cardBarcodeType === -1 || cardBarcodeType === 12 ? [] : COLUMNRIGHTBARCODE}
                type="main"
                form="full" 
                design="main"
                corporate
            />
            { selectedNbRows === 1 ?
                <Form
                    title="Ligne 1"
                    titleSmallSize={true}
                    first={ROW1COLUMNLEFTTHREEITEMS}
                    second={ROW1COLUMNRIGHTTHREEITEMS}
                    type="main"
                    form="full" 
                    design="main"
                    corporate
                >
                </Form> : null
            }
            { selectedNbRows === 2 ?
                <>
                <Form
                    title="Ligne 1"
                    titleSmallSize={true}
                    first={ROW1COLUMNLEFTTHREEITEMS}
                    second={ROW1COLUMNRIGHTTHREEITEMS}
                    type="main"
                    form="full" 
                    design="main"
                    corporate
                >
                </Form>
                <Form
                    title="Ligne 2"
                    titleSmallSize={true}
                    first={ROW2COLUMNLEFTTHREEITEMS}
                    second={ROW2COLUMNRIGHTTHREEITEMS}
                    type="main"
                    form="full" 
                    design="main"
                    corporate
                >
                </Form></> : null
            }
            { selectedNbRows === 3 ?
                <>
                <Form
                    title="Ligne 1"
                    titleSmallSize={true}
                    first={ROW1COLUMNLEFTTHREEITEMS}
                    second={ROW1COLUMNRIGHTTHREEITEMS}
                    type="main"
                    form="full" 
                    design="main"
                    corporate
                >
                </Form>
                <Form
                    title="Ligne 2"
                    titleSmallSize={true}
                    first={ROW2COLUMNLEFTTHREEITEMS}
                    second={ROW2COLUMNRIGHTTHREEITEMS}
                    type="main"
                    form="full" 
                    design="main"
                    corporate
                >
                </Form>
                <Form
                    title="Ligne 3"
                    titleSmallSize={true}
                    first={ROW3COLUMNLEFTTHREEITEMS}
                    second={ROW3COLUMNRIGHTTHREEITEMS}
                    type="main"
                    form="full" 
                    design="main"
                    corporate
                >
                </Form></> : null
            }
            <Button onClick={checkInputRequired} sx={{color:'#1B365F'}}>Générer</Button>
        </center>
    </>
  );
}

const Warning = styled.div`
  color: #c82121;
`;

const BootstrapInput = styled(InputBase)(() => ({
    'label + &': {
      marginTop: useTheme().spacing(2),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      textAlign: 'center',
      width: '70px',
      height:'10px',
      position: 'relative',
      backgroundColor: useTheme().palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: useTheme().transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }));