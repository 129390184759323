import { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { styled as styledComponent } from 'styled-components'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import endpoints from '../../../../../mixins/endpoints'
import api from '../../../../../mixins/api'
import apiBaseUrl from '../../../../../mixins/apiBaseUrl'
import GoogleClass from '../../../../../models/GoogleClass'
import Issuer from '../../../../../models/Issuer'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1B365F",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function styleDetail(style: number){
  if(style === 0){
    return "Générique"
  }
  else{
    return "Inconnu"
  }
}

export default function DisplayPass() {
  const [dataGoogleClass, setDataGoogleClass] = useState<GoogleClass[]>([]);
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  useEffect(() => {
    fetchGoogleClass();
  }, []);

  const fetchGoogleClass = async () => {
    api.methods.get(apiBaseUrl.data().digitalFramework+ endpoints.data().GetAllGoogleClassEndpoint).then((response =>{
      setDataGoogleClass(response.data)
    })).catch((error)=>{
        alert(error)
        console.log(error)
    })
  }

  function formatIssuersValues(value: string){
    var result= ''
    const properties:Issuer[] = JSON.parse(value)
    if(properties !== null && properties !== undefined && properties.length >= 1){
        properties.forEach((element:Issuer, index, properties) => {
          if(properties.length - 1 === index){
            result += element.Id
          }
          else{
            result += element.Id + ';'
          }
      });
    }

    return result
  }

  function smartTapDisplay(value : boolean){
    var result = ''
    if(value){
        result = 'Activé'
    }
    else{
        result = 'Désactivé'
    }
    return result;
  }

  function getTypeOfStyle(style: number){
    let result = ''
    switch(style){
        case 0: result = '_generic_'
                break;
        default: result = '_generic_'
                break;
    }

    return result
}

  function displayFormatClassId(value: string, style: number){
    let result = ''
    if(value !== '' && value !== undefined){
      result = value.replace(process.env.REACT_APP_Env + getTypeOfStyle(style), '')
    }

    return result
  }

  function formatDate(parameter:string, hideHour : boolean){
    var result = ''
    if(parameter !== '')
    { 
      if(hideHour){
        result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'long', day: 'numeric' });
      }else{
        result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' });
      }
    }
    return result;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <>
      {/* <Button onClick={fetchGoogleObject}>Actualiser</Button> */}
      <center>Modèles actifs</center>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align='center'>Nom</StyledTableCell>
              <StyledTableCell align='center'>ID</StyledTableCell>
              <StyledTableCell align="center">Type</StyledTableCell>
              <StyledTableCell align='center'>Nb lignes</StyledTableCell>
              <StyledTableCell align='center'>Smart Tap (NFC)</StyledTableCell>
              <StyledTableCell align='center'>ID Marchand(s)</StyledTableCell>
              <StyledTableCell align="center">Créé</StyledTableCell>
              {/* <StyledTableCell align="center">Créé par</StyledTableCell> */}
              <StyledTableCell align="center">Mise à jour</StyledTableCell>
              {/* <StyledTableCell align="center">Mise à jour par</StyledTableCell> */}
              <StyledTableCell align="center">Version</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataGoogleClass.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <StyledTableRow key={row.classId}>
                <StyledTableCell align='center'>{displayFormatClassId(row.classId, row.style)}</StyledTableCell>
                <StyledTableCell align='center'>{row.classId}</StyledTableCell>
                <StyledTableCell align="center">{styleDetail(row.style)}</StyledTableCell>
                <StyledTableCell align='center'>{row.nbRows}</StyledTableCell>
                <StyledTableCell align="center">{smartTapDisplay(row.enableSmartTap)}</StyledTableCell>
                <StyledTableCell align="center">{formatIssuersValues(row.redemptionIssuers)}</StyledTableCell>
                <StyledTableCell align="center">{formatDate(row.created, true)}</StyledTableCell>
                {/* <StyledTableCell align='center'>{shortUsernameDisplay(row.createdBy)}</StyledTableCell> */}
                <StyledTableCell align="center">{formatDate(row.lastUpdate ?? '', true)}</StyledTableCell>
                {/* <StyledTableCell align='center'>{shortUsernameDisplay(row.lastUpdateBy)}</StyledTableCell> */}
                <StyledTableCell align="center">{row.version}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Align>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={dataGoogleClass.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={
            ({ from, to, count }) => {
              return '' + from + '-' + to + ' sur ' + count
            }
          }
          labelRowsPerPage="Lignes par page"
        />
      </Align>
    </>
  );
}

const Align = styledComponent.div`
p {
  margin-top: 13px
}
`;