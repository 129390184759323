// Define what props.theme will look like
const THEME = {
    text: {
        color: {
            main: '#1B365F',
            primary: '#EED58E',
            general: '#FFFFFF',
            principal: '#2C2A29',
            secondary: '#ED8C00',
            terciary: '#CF4527',
            diverse: '#DCDCDC'
        },
        font: 'Arial',
        size: {
            title: '2em',
            subtitle: '1.5em',
            paragraph: '18px',
            subparagraph: '14px',
        },
        weight: {
            main: '400',
            general:'300',
            emphasis: 'bold'
        }
    },
    background: {
        color: {
            general: '#1B365F',
            primary: '#EED58E',
            main: '#FFFFFF',
            dark: '#000000',
            principal: '#2C2A29',
            secondary: '#ED8C00',
            terciary: '#CF4527',
            diverse: '#DCDCDC',
            indicator: '#7B3CA5',
            signal: '#3F9703',
            content: '#F2F2F2',
            transparent: 'transparent'
        }
    },
    border: {
        color: {
            main: '#1B365F',
            primary: '#EED58E',
            general: '#FFFFFF',
            principal: '#2C2A29',
            secondary: '#ED8C00',
            terciary: '#CF4527',
            diverse: '#DCDCDC'
        },
        boxShadow: {
            main: '1px 1px 1px 0px rgba(220,220,220,0.75)',
            principal: '3px 3px 3px 0px rgba(220,220,220,0.75)'
        }
    },
    tab:{
        with:{
            small: "170px",
            medium: "220px",
            large: "350px"
        }
    }
}

export default THEME
