import { useState} from 'react'
import TabList from '../../../components/Tab/TabList'
import TabListDropdrown from '../../../components/Tab/TabListDropdown'
import TabContent from '../../../components/Tab/TabContent'
import BusinessCard from './BusinessCard'
import LogoManagement from './LogoManagement'
import SimpleContent from './SimpleContent'
import RecordManagement from './RecordManagement'

const TabsGeneral=[
  {
    label: "Générer"
  },
  {
    label: "Gestion"
  }
]

const TabsChilds=[
    {
      parent: "Générer",
      label: "Simple contenu",
      container:"SimpleContent"
    },
    {
      parent: "Générer",
      label: "Carte de visite",
      container:"BusinessCard"
    },
    {
      parent: "Gestion",
      label: "Entités",
      container:"LogoManagement"
    },
    {
      parent: "Gestion",
      label: "QR Code",
      container:"RecordManagement"
    }
]

const TABS=[
  {
    label: "Simple contenu"
  },
  {
    label: "Carte de visite"
  },
  {
    label: "Gestion des entités"
  },
  {
    label: "Gestion des QR Code"
  }
]
 
export default function QrCode() {
  const [active, setActive] = useState("Générer")
  const [container, setContainer] = useState("SimpleContent")
  return (
    <>
      <TabListDropdrown 
        active={active}
        setActive={setActive}
        container={container}
        setContainer={setContainer}
        tabList={TabsGeneral} 
        childs={TabsChilds}
        width='medium'
        liwidth='60px'
        
      />
        <TabContent active={container==="SimpleContent"}>
          <SimpleContent/> 
        </TabContent> 
        <TabContent active={container==="BusinessCard"}>
          <BusinessCard /> 
        </TabContent>
        <TabContent active={container==="LogoManagement"}>
          <LogoManagement/>
        </TabContent> 
        <TabContent active={container==="RecordManagement"}>
          <RecordManagement /> 
        </TabContent>
    </>
  );
}