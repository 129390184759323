import { useState, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import endpoints from '../../../../mixins/endpoints'
import api from '../../../../mixins/api'
import apiBaseUrl from '../../../../mixins/apiBaseUrl'
import Record from '../../../../models/Record'
import DialogImg from '../../../../components/Modals/DialogImg'
import { styled } from '@mui/material/styles'
import Button from "@mui/material/Button"
import Garbage from '../../../../assets/icons/Garbage.png'
import Download from '../../../../assets/icons/Download.png'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Paper from '@mui/material/Paper'
import THEME from '../../../../styles/theme'
import { styled as styledComponent } from 'styled-components'

export default function DisplayQrCode() {
  const [data, setData] = useState<Record[]>([])
  const [displayDialogImg, setDisplayDialogImg] = useState(false)
  const [imgDialog, setImgDialog] = useState("")
  const [displayDialogConfirmRemove, setDisplayDialogConfirmRemove] = useState(false)
  const [dialogMessage, setDialogMessage] = useState("")
  const [recordIdToRemove, setRecordIdToRemove] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1B365F",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  useEffect(() => {
    fetchQrCode()
  }, []);

  const fetchQrCode = async () => {
    api.methods.get(apiBaseUrl.data().digitalFramework+ endpoints.data().GetAllQrCodeEndpoint).then((response =>{
      setData(response.data)
    })).catch((error)=>{
        alert(error)
        console.log(error)
    })
  }

  const displayPreview = async(imgbase64: string) => {
     setImgDialog(imgbase64)
     setDisplayDialogImg(true)
  }

  function copyLink(value: string){
    navigator.clipboard.writeText(value)
  }

  function typeDisplay(value: number){
    let result = ""
    switch(value){
      case 0: result = "Simple contenu"
              break
      case 1: result = "Carte de visite"
              break
      default: break
    }

    return result
  }

  function confirmDeleteRecord(recordId: string, recordName: string){
    setDialogMessage("Suppression définitive du QR Code \"" + recordName + "\" ?")
    setDisplayDialogConfirmRemove(true)
    setRecordIdToRemove(recordId)
 }

  function formatDate(parameter:string, hideHour : boolean){
    var result = ''
    if(parameter !== '')
    { 
      if(hideHour){
        result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'long', day: 'numeric' });
      }else{
        result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' });
      }
    }
    return result;
  }

  const handleClose = () => {
    setDisplayDialogConfirmRemove(false);
  };

  const deleteRecord = async () => {
    setDisplayDialogConfirmRemove(false);
    
    if(recordIdToRemove !== ""){
      api.methods.delete(apiBaseUrl.data().digitalFramework + endpoints.data().DeleteQrCodeEndpoint + "?id=" + recordIdToRemove).then(() =>{
        setRecordIdToRemove("")
        fetchQrCode()
          }).catch((error)=>{
              alert(error)
              console.log(error)
          })
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <>
      <center>QR CODE ENREGISTRÉS</center>
      <DialogImg type='main' 
        display={displayDialogImg} 
        setDisplay={setDisplayDialogImg} 
        // title='AGL - QR Code' 
        imagebase64={imgDialog} 
        copyEnabled={true}>customdialog
      </DialogImg>
      <Dialog
        open={displayDialogConfirmRemove} 
        onClose={handleClose}
        PaperComponent={PaperStyle}>
        <DialogContent>
          <ContentContainer >
              {dialogMessage}
          </ContentContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteRecord} color="primary" autoFocus>
            <Color>Oui</Color>
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            <Color>Non</Color>
          </Button>
        </DialogActions>
      </Dialog> 
      <TableContainer component={Paper}>      
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align='center'>Nom</StyledTableCell>
              <StyledTableCell align='center'>Type</StyledTableCell>
              <StyledTableCell align='center'>Entité</StyledTableCell>
              <StyledTableCell align="center">Couleur RGB</StyledTableCell>
              <StyledTableCell align='center'>Aperçu</StyledTableCell>
              <StyledTableCell align='center'>Lien</StyledTableCell>
              <StyledTableCell align='center'>Télécharger</StyledTableCell>
              <StyledTableCell align="center">Créé</StyledTableCell>
              <StyledTableCell align="center">Créé par</StyledTableCell>
              <StyledTableCell align="center">Supprimer</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell align='center'>{row.name}</StyledTableCell>
                <StyledTableCell align='center'>{typeDisplay(row.type)}</StyledTableCell>
                <StyledTableCell align='center'>{row.entity}</StyledTableCell>
                <StyledTableCell align="center">{row.backgroundColor}</StyledTableCell>
                <StyledTableCell align='center'>
                  <input type='image' onClick={()=>displayPreview(row.imageBase64)} src={`data:image/png;base64,${row.imageBase64}`} alt='' width={'80px'}/>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Button onClick={(e) =>{copyLink(process.env.REACT_APP_BlobStorageUrl + '/qrcode/' + row.storageId + '/' + row.fileName)}}>
                      Copier le lien
                  </Button>
                 </StyledTableCell>
                 <StyledTableCell align="center">
                    <a download={row.name + '.png'} href={`data:image/png;base64,`+ row.imageBase64}>
                      <img src={Download} width={25} height={25} alt="Télécharger le QR Code" title="Télécharger le QR Code" />
                    </a>
                 </StyledTableCell>
                <StyledTableCell align="center">{formatDate(row.created, true)}</StyledTableCell>
                <StyledTableCell align="center">{row.createdBy}</StyledTableCell>
                <StyledTableCell align="center">
                    <Button onClick={(e) =>{confirmDeleteRecord(row.id, row.name)}}>
                        <img src={Garbage} width={30} height={30} alt="Supprimer l'entité" title="Supprimer l'entité" />
                    </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Align>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={
            ({ from, to, count }) => {
              return '' + from + '-' + to + ' sur ' + count
            }
          }
          labelRowsPerPage="Lignes par page"
        />
      </Align>
    </>
  );
}

const PaperStyle = styled(Paper)`
  background-color: ${THEME.background.color.general};
  color: ${THEME.text.color.general};
`;

const ContentContainer = styledComponent.div`
  color: ${THEME.text.color.general};
`;

const Warning = styledComponent.div`
  color: #c82121;
`;

const Color = styledComponent.div`
  color: white;
`;

const Align = styledComponent.div`
p {
  margin-top: 13px
}
`;