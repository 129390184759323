import axios from "axios";

function geAccessToken(){
    let accessToken = sessionStorage.getItem("msal.idtoken");
    return accessToken;
}

function reportErrors(error){
    if(!error) return;

    const e = new CustomEvent('erreurConsole',{
        detail : error
    });

    if(e.detail.response)
        if(e.detail.response.status === "401" || e.detail.response.status === "404"){
            window.dispatchEvent(e);
        }
}

axios.interceptors.request.use((config)=>{
    if(geAccessToken() !== null){
        config.headers = {
            Authorization: `Bearer ${geAccessToken()}`,
        }
    }
    return config;
},(error)=>{
    reportErrors(error);
    return Promise.reject(error);
})

axios.interceptors.response.use((response)=>{
    return response;
}, (error)=>{
    reportErrors(error);
    return Promise.reject(error);
})

export default{
    data(){
        return{
            pagePath: '',
            UserScoped: '',
        }
    },
    props:{
        token: {
            type: String,
            default: () => geAccessToken()
        }
    },
    computed:{
        //ConnectedUseMail()
        //ConnectedUserType()
        headers(){
            const token = this.token ? this.token : geAccessToken()
            return {
                headers:{
                    Authorization: `Bearer ${token}`
                }
            };
        }
    },
    methods:{
        logError(error, methodName, feature, action, message){
            let logLevel = 'Critical'
            if(error.response.status === 401 ||
               error.response.status === 400 ||
               error.response.status === 500){
                logLevel = 'Error'
            }

            this.genericLog({
                MethodName: methodName,
                Status:'End',
                Feature: feature,
                Action: action,
                LogLevel: logLevel,
                Message: message
            });
        },
        genericLog(arg){
            this.$appInsights.trackEvent(
                "AddToWallet::"+ this.pagePath + "::/" + this.arg.methodName,{
                    name: this.pagePath + "::" + this.arg.methodName,
                    Module: "AddToWallet",
                    Status: arg.status,
                    Feature: arg.feature,
                    Action: arg.action,
                    Execution: "Front",
                    LogLevel: arg.logLevel,
                    Message: arg.message
                    //user:
                    //Permission 
                }
            )
        },
        get(url){
            return new Promise((resolve, reject)=>{
                axios.get(url, this.headers)
                     .then(res=> resolve(res))
                     .catch(error=>reject(error));
            });
        },
        post(url, payload){
            return new Promise((resolve, reject)=>{
                axios.post(url, payload, this.headers)
                     .then(res=> resolve(res))
                     .catch(error=> reject(error));
            });
        },
        delete(url, data){
            return new Promise((resolve, reject)=>{
                axios.delete(url,{ data, headers: this.headers})
                     .then(res=> resolve(res))
                     .catch(error=> reject(error))
            });
        },
        patch(url, payload){
            return new Promise((resolve, reject)=>{
                axios.patch(url, payload, this.headers)
                     .then(res=> resolve(res))
                     .catch(error=> reject(error));
            });
        },
        put(url, payload){
            return new Promise((resolve, reject)=>{
                axios.put(url, payload, this.headers)
                     .then(res=> resolve(res))
                     .catch(error=> reject(error));
            });
        }
    },
    created(){
        //this.pagePath = window.location.pathname;
    }
};