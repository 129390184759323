import React, { ReactNode } from 'react';
import { styled } from 'styled-components';

interface ITabContentProps {
  active: boolean
  children: ReactNode
}

function TabContent(props: ITabContentProps) {
  if(props.active) {
    return (
      <TabContainer>{props.children}</TabContainer>
    )
  }
  return null
}

export default TabContent;

const TabContainer = styled.div`
  margin-top: 30px;
  cursor: pointer;
`;
