import { useState, useEffect } from 'react'
import { IInputProps } from '../../../../../components/Forms/InputV2'
import endpoints from '../../../../../mixins/endpoints'
import api from '../../../../../mixins/api'
import apiBaseUrl from '../../../../../mixins/apiBaseUrl'
import Form from '../../../../../components/Forms/FormV2'
import GoogleWalletTemplateClass from '../../../../../assets/wallet/google/frFR_template_google_wallet_class.png'
import GoogleClass from '../../../../../models/GoogleClass'
import Dialog from '../../../../../components/Modals/Dialog'
import Checkbox from '../../../../../components/Forms/Checkbox'
import { styled } from 'styled-components';
import Issuer from "../../../../../models/Issuer"

import Button from "@mui/material/Button";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function CreateClass() {
    const [cardClassId, setCardClassId] = useState('')
    const [selectedNbRows, setSelectedNbRows] = useState('')
    const [nfcEnabled, setNfcEnabled] = useState(false)
    const [merchandIds, setMerchandIds] = useState('')
    const [displayDialog, setDisplayDialog] = useState(false)
    const [dialogCardMessage, setDialogCardMessage] = useState('')
    const [dataGoogleClass, setDataGoogleClass] = useState<GoogleClass[]>([])
    const [passStyl, setPassStyl] = useState('')

    const COLUMNLEFTBASE: IInputProps[] = 
    [
        {
            input:'text',
            type:'full',
            id:'classId',
            label:'Nom du modèle de carte (requis)',
            labelPosition: 'left',
            name:'classId',
            placeholder:'Nom du modèle de carte',
            value: formatCardId(cardClassId),
            setValue: setCardClassId
        }
    ]

    const COLUMNLEFTNFC: IInputProps[] = 
    [
        {
            input:'text',
            type:'full',
            id:'merchandId',
            label:'ID de marchand(s) (requis)',
            labelPosition: 'left',
            name:'merchandId',
            placeholder:"Si vous en avez plusieurs séparer-les par un ;",
            value: merchandIds,
            setValue: setMerchandIds
        }
    ]
    
    const fetchGoogleClass = async () => {
        api.methods.get(apiBaseUrl.data().digitalFramework + endpoints.data().GetAllGoogleClassEndpoint).then((response =>{
                setDataGoogleClass(response.data)
            })).catch((error)=>{
                alert(error)
                console.log(error)
            })
        }
        
    useEffect(() => {
        fetchGoogleClass();
        initFormValues();
    }, []);
    
    function formatCardId(value: string){
        let result = ''
        if(value !== ''){
            result = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "").toLowerCase()
        }
        return result
    }

    function updateNfcCheckbox(){
        if(!nfcEnabled){
            setNfcEnabled(true)
        }
        else{
            setNfcEnabled(false)
        }
    }

    const updateRows = (event: SelectChangeEvent) => {
        setSelectedNbRows(event.target.value as string)
    }

    const updatePassStyl = (event: SelectChangeEvent) => {
        setPassStyl(event.target.value as string)
    } 

    function initFormValues(){
        setCardClassId('')
        setSelectedNbRows('')
        setNfcEnabled(false)
    }

    function duplicateClassIdFound(){
        if(dataGoogleClass.find((item)=> item.classId === process.env.REACT_APP_Env + getTypeOfStyle() + cardClassId)){
            return true  
        }
        else{
            return false
        }
    }

    function buildRedemptionIssuerProperties(value: string){
        var collectionIssuers:Issuer[] = []
        if(value !== '' && value !== undefined){
            let issuers = value.split(';')
            if(issuers.length >= 1){
                issuers.forEach((issuer) =>{
                    if(issuer !== '' && issuer !== undefined){
                        let newIssuer:Issuer = {
                            Id: issuer
                        }
                        
                        collectionIssuers.push(newIssuer)
                    }
                })
            }
        }

        return collectionIssuers;
    }

    function isInputIssuerValuesCompliant(value: string){
        let result = true
        if(value !== '' && value !== undefined){
            let issuers = value.split(';')
            if(issuers.length >= 1){
                issuers.forEach((issuer) =>{
                    if(issuer === '' || issuer === undefined || !Number(issuer)){
                        result = false
                    }
                })
            }
        }
        return result
    }

    function checkInputRequired(){
        if(cardClassId === ''){
            setDialogCardMessage('Veuillez renseigner le nom du modèle de carte')
            setDisplayDialog(true)
        }
        else if(nfcEnabled === true && (merchandIds === '' || !isInputIssuerValuesCompliant(merchandIds))){
            if(merchandIds === ''){
                setDialogCardMessage('Veuillez renseigner un ou plusieurs ID(s) de marchand (requis)')
            }
            else{
                setDialogCardMessage("Veuillez renseigner un ou plusieurs ID(s) de marchand sous le format d'un nombre entier uniquement")
            }
            setDisplayDialog(true)
        }
        else if(duplicateClassIdFound()){
            setDialogCardMessage('Le nom du modèle existe déjà, veuillez renseigner un autre nom')
            setDisplayDialog(true)
        }
        else{
            createNewGoogleClass()
        }
    }

    function getTypeOfStyle(){
        let result = ''
        switch(Number(passStyl)){
            case 0: result = '_generic.'
                    break;
            default: result = '_generic.'
                    break;
        }

        return result
    }

    function createNewGoogleClass(){
        let classSend
        let rowsArray
        //build class to send depend on nbRows and NFC 

        switch(Number(selectedNbRows)){
            case 0:
                break;
            case 1:
                rowsArray = [
                    {
                        threeItems: {
                            fieldNameLeft : { id:'Row1ItemLeft' },
                            fieldNameMiddle : { id: 'Row1ItemMiddle'},
                            fieldNameRight : { id: 'Row1ItemRight'}
                        }
                    }
                ]
                break;
            case 2:
                rowsArray = [
                    {
                        threeItems: {
                            fieldNameLeft : { id:'Row1ItemLeft' },
                            fieldNameMiddle : { id: 'Row1ItemMiddle'},
                            fieldNameRight : { id: 'Row1ItemRight'}
                        }
                    },
                    {
                        threeItems: {
                            fieldNameLeft : { id:'Row2ItemLeft' },
                            fieldNameMiddle : { id: 'Row2ItemMiddle'},
                            fieldNameRight : { id: 'Row2ItemRight'}
                        }
                    }
                ]
                break;
            case 3:
                rowsArray = [
                    {
                        threeItems: {
                            fieldNameLeft : { id:'Row1ItemLeft' },
                            fieldNameMiddle : { id: 'Row1ItemMiddle'},
                            fieldNameRight : { id: 'Row1ItemRight'}
                        }
                    },
                    {
                        threeItems: {
                            fieldNameLeft : { id:'Row2ItemLeft' },
                            fieldNameMiddle : { id: 'Row2ItemMiddle'},
                            fieldNameRight : { id: 'Row2ItemRight'}
                        }
                    },
                    {
                        threeItems: {
                            fieldNameLeft : { id:'Row3ItemLeft' },
                            fieldNameMiddle : { id: 'Row3ItemMiddle'},
                            fieldNameRight : { id: 'Row3ItemRight'}
                        }
                    }
                ]
                break;
            default: 
                break;
        }

        classSend = {
            classId: process.env.REACT_APP_Env + getTypeOfStyle() + cardClassId,
            style: Number(passStyl),
            nbRows: Number(selectedNbRows),
            rows: rowsArray != null && rowsArray.length > 0 ? rowsArray : null,
            enableSmartTap: nfcEnabled,
            redemptionIssuers: nfcEnabled ? buildRedemptionIssuerProperties(merchandIds) : null
        }

        api.methods.post(apiBaseUrl.data().digitalFramework + endpoints.data().NewGoogleClassEndpoint, classSend).then(() =>{
            setDialogCardMessage("Le modèle de carte \"" + cardClassId + "\" vient d'être créé")
            setDisplayDialog(true)
            initFormValues()
            fetchGoogleClass()
          }).catch((error)=>{
              //alert(error)
              console.log(error)
              setDialogCardMessage("Une erreur est survenue : \"" + error)
              setDisplayDialog(true)
        })
    }

  return (
    <>
    <Dialog type='main' display={displayDialog} setDisplay={setDisplayDialog} title='AGL - Modèle de carte' text={dialogCardMessage}>customdialog</Dialog>
	    <center>
        <img src={GoogleWalletTemplateClass} alt='template Google wallet class' width={250} height={250}/><br/>
            Un modèle de carte est la référence sur laquelle se base une carte virtuelle pour être créée.
            <Form
                title=""
                first={COLUMNLEFTBASE}
                type="main"
                form="half" 
                design="transparent"
                corporate
            ></Form> 
        
            <FormControl sx={{ m: 1,width:'25ch' }} variant="standard">
                <InputLabel id="passStyl-label">Style de carte</InputLabel>
                <Select
                    labelId="passStyl-label"
                    id="passStyl-select"
                    value={passStyl}
                    label="Style de carte"
                    onChange={updatePassStyl}
                >
                    <MenuItem key={0} value={0}>Générique</MenuItem>
                    <MenuItem key={1} value={1} disabled>Billet d'événement</MenuItem>
                    <MenuItem key={2} value={2} disabled>Carte d'embarquement</MenuItem>
                    <MenuItem key={3} value={3} disabled>Carte cadeau</MenuItem>
                    <MenuItem key={4} value={4} disabled>Carte de fidélité</MenuItem>
                    <MenuItem key={5} value={5} disabled>Carte d'offre</MenuItem>
                    <MenuItem key={6} value={6} disabled>Carte de transport</MenuItem>
                </Select>
            </FormControl>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <FormControl sx={{ m: 1,width:'10ch' }} variant="standard">
                <InputLabel id="nbRows-label">Nb lignes</InputLabel>
                <Select
                    labelId="nbRows-label"
                    id="nbRows-select"
                    value={selectedNbRows}
                    label="Nb lignes"
                    onChange={updateRows}
                >
                    <MenuItem key={0} value={'0'}>0</MenuItem>
                    <MenuItem key={1} value={'1'}>1</MenuItem>
                    <MenuItem key={2} value={'2'}>2</MenuItem>
                    <MenuItem key={3} value={'3'}>3</MenuItem>
                </Select>
            </FormControl>
            <br/><br/>
            <Checkbox label='Activer le Smart Tap (NFC) ?' name='nfcEnabled' value='' checked={nfcEnabled} isChecked={updateNfcCheckbox}/>
            <br/>
            <a href='https://developers.google.com/wallet/smart-tap/introduction/overview?hl=fr' target='_blank' rel="noreferrer">Qu'est-ce que le Smart Tap ?</a>
            <br/><br/>
            { nfcEnabled === true ?
                <>
                <center>
                    <b>Prérequis</b> : L'ID d'un marchand est nécessaire afin d'autoriser la communication entre 
                    <br/>le marchand et les futures cartes virtuelles qui hériteront de ce modèle de carte.
                    <br/><br/>
                    <Warning>Si vous n'avez pas encore d'ID(s) de marchand(s) alors il est inutile d'activer le NFC maintenant</Warning>
                </center>
                <Form
                    title=""
                    first={COLUMNLEFTNFC}
                    type="main"
                    form="half" 
                    design="main"
                    corporate
                >
                </Form>
                </> :  
                null
            }
            <Button onClick={checkInputRequired} sx={{color:'#1B365F'}}>Générer</Button> 
        </center>
    </>
  );
}

const Warning = styled.div`
  color: #c82121;
`;
