import { ReactNode } from 'react';
import { styled } from 'styled-components';
import Container from '../../../containers/Container';
import Input, { IInputProps } from '../Input';
import { Link } from 'react-router-dom';
import Title from '../../Text/Title';
import Button from '../../Button';
import THEME from '../../../styles/theme';

interface IFormsProps {
    logo?: string
    title?: string
    titleSmallSize?: boolean
    form?: string
    first: IInputProps[]
    second?: IInputProps[]
    buttonList?: IButtonProps[]
    type?: string
    design?: string
    children?: ReactNode
    corporate?: boolean
}

interface IButtonProps{
  label: string
  type?: string
  size?: string
  onClick?: (e: MouseEvent) => void
}

export type { IButtonProps }

function Form(props: IFormsProps) {
  return (
    <Container type={props.form} design={props.design}>
      <FormContainer design={props.design}>
        {props.corporate && <Link to='/'>
            {/* <Logo type={props.logo} position="center" /> */}
        </Link>}
        {
          props.titleSmallSize ?
          <b> {props.title} </b>
          : props.title 
              && <Title
                  color={props.type}
                  type='emphasis' 
                  size='title' 
                  label={props.title}
                  align='center'
              />
        }
        <InputContainer>
          <List before="true" second={props.second}>
            {props.first?.map((field) => {
              return(
                <Input
                    key={field.name}
                    input={field.input}
                    type={field.type}
                    id={field.id}
                    label={field.label}
                    labelPosition={field.labelPosition}
                    name={field.name}
                    placeholder={field.placeholder}
                    value={field.value}
                    setValue={field.setValue}
                />
              )
            })}
          </List>

          {props.second && <List second={props.second}>
            {props.second?.map((field) => {
              return(
                <Input
                    key={field.name}
                    input={field.input}
                    type={field.type}
                    id={field.id}
                    label={field.label}
                    labelPosition={field.labelPosition}
                    name={field.name}
                    placeholder={field.placeholder}
                    value={field.value}
                    setValue={field.setValue}
                />
              )
            })}
          </List>}
        </InputContainer>
        {props.children}
        {props.buttonList?.map((button) => {
          return(
            <Button
              key={button.label}
              type={button.type}
              size={button.size}
              label={button.label}
              onClick={e => button.onClick && button.onClick(e)}
            />
          )
        })}
      </FormContainer>    
    </Container>

  );
}

export default Form;

interface IFormContainerProps {
  design?: string
}

const FormContainer = styled.form<IFormContainerProps>`
  text-align: center;
  color: ${
    props => props.design === 'main' ? 
      THEME.text.color.main : 
    props.design === 'general' ? 
      THEME.text.color.general :
    props => props.design === 'primary' ? 
      THEME.text.color.main :
    props => props.design === 'content' ? 
      THEME.text.color.general :
    props => props.design === 'transparent' ? 
      THEME.text.color.general :
      THEME.text.color.main
  };
`;

interface IFirstListProps {
  before?: string
  second?: IInputProps[]
}
const List = styled.div<IFirstListProps>`
  margin-right: ${props => props.second && props.second.length > 0 && props.before && "30px"};
  width: ${props => props.second && props.second.length > 0 ? "50%": "100%"};
`;

const InputContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

