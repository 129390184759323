import React from 'react';
import LogoImg from '../../../assets/logo/agl_logo.svg'
import LogoWhiteImg from '../../../assets/logo/agl_white.svg'
import styled from 'styled-components';

interface ILogoProps {
    type?: string
    position?: string
}

function Logo(props: ILogoProps) {
    return (
        <LogoContainer 
            position={props.position} 
            src={
                props.type === 'main'? LogoImg : 
                props.type === 'primary'? LogoImg :
                props.type === 'diverse'? LogoImg :
                LogoWhiteImg} 
            alt={LogoImg} 
        />
    );
}

interface ILogoContainerProps {
    position?: string
}

const LogoContainer = styled.img<ILogoContainerProps>`
    padding: 10px;
    width: 100px;
`

export default Logo;
