import React, { ReactNode } from 'react';
import SimpleLayout from '../SimpleLayout';
import Menu, { IMenuProps } from '../../components/Menus/Menu';
import { styled } from 'styled-components';

interface ILayoutProps {
  type?: string
  menu?: IMenuProps
  action?: boolean
  active?: string
  children: ReactNode
}

function Layout(props: ILayoutProps) {
  // const [subMenuOpen, setSubMenuOpen] = useState(false)
  return (
    <SimpleLayout type={props.type}>
      {props.menu && <Menu active={props.active} type={props.menu.type} links={props.menu.links} />}
      <LayoutContainer action={props.action}>
        {props.children}
      </LayoutContainer>
    </SimpleLayout>
  );
}

export default Layout;

interface ILayoutContainerProps{
  action?: boolean
}

const LayoutContainer = styled.div<ILayoutContainerProps>`
  padding-top: ${props => props.action ? '78px' : '30px'};
  
`;
