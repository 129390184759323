import { ReactNode, useState } from 'react';
import { styled } from 'styled-components';
import THEME from '../../../styles/theme';

interface ITabsProps {
  active: string
  setActive: (active: string) => void
  container: string
  setContainer: (container: string) => void
  tabList: {
    label: string
  }[]
  childs: {
    parent: string
    label: string
    container: string
  }[]
  width : string
  children?: ReactNode
  liwidth: string
}

interface ITabProps {
  active: string
  setActive: () => void
  container: string
  setContainer: (container: string) => void
  label: string
  width: string
  childs:{
    parent: string
    label: string
    container: string
  }[]
  liwidth: string
}

function Tab(props:ITabProps) {
    const [isDropdownVisible, setDropdownVisible] = useState(false)
    const [containerSelected, setContainerSelected] = useState("")
    const handleMouseEnter = () => {
        setDropdownVisible(true)
      };
    
      const handleMouseLeave = () => {
        setDropdownVisible(false)
      };

  function SetDisplayContainer(content: string){
    props.setContainer(content)
  }

  return(
    <>
    <TabContainer 
      label={props.label}
      active={props.active} 
      onClick={() => props.setActive()}
      width={props.width}
      childs={props.childs}
      container={props.container}
      liwidth={props.liwidth}
    >
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
        {props.label}
        <ul>
        { isDropdownVisible ? 
            props.childs.map((child)=> child.parent === props.label ? 
                <li key={child.label} onClick={(e)=>SetDisplayContainer(child.container)}>{child.label}</li> 
                : null)
            : null}
        </ul>
      </div>
    </TabContainer>
    </>
  )
}

function TabListColumn(props: ITabsProps) {
  return (
    <div>
      <TabsContainer>
        {props.tabList.map(tab => {
          return(<Tab
              key={tab.label}
              label={tab.label} 
              active={props.active} 
              setActive={() => props.setActive(tab.label)}
              width={props.width}
              childs={props.childs}
              container={props.container}
              setContainer={(content)=> props.setContainer(content)}
              liwidth={props.liwidth}
            />)
        })}
      </TabsContainer>
      {props.children}
    </div>
  );
}

export default TabListColumn;

interface ITabContainerProps {
  active: string
  label: string
  width: string
  childs:{
    parent: string
    label: string
  }[]
  container: string
  liwidth: string
}

const TabsContainer = styled.div`
  display: flex;  
  flex-direction: row;
  cursor: pointer;
`;

const TabContainer = styled.div<ITabContainerProps>`
  background-color: ${props => props.active === props.label ? THEME.background.color.general : THEME.background.color.content};
  color: ${props => props.active === props.label ? THEME.text.color.general: THEME.text.color.main};
  width: ${props => props.width === "small" ? THEME.tab.with.small : props.width === "medium" ? THEME.tab.with.medium : THEME.tab.with.large};
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-right:1px;
  &:hover {
    background-color: ${THEME.background.color.general};
    color: ${THEME.text.color.general}
  }

  ul {
    position:absolute;
    list-style: none;
    padding: unset;
    margin: unset;
  }
  
  li {
    position:relative;
    top:12px;
    right:${props => props.liwidth};
    width:180px;
    padding: 14px;
    display:flex;
    align-items: center;
    justify-content: center;
    transition: all linear 0.3s;
    background:${THEME.background.color.general};
    border-radius: 10px;
    margin-bottom:1px;
  }
  
  li:hover {
    background: white;
    cursor: pointer;
    color: ${THEME.background.color.general};
  }
`;

