import { useState} from 'react'
import TabListDropdrown from '../../../components/Tab/TabListDropdown'
import TabContent from '../../../components/Tab/TabContent'
import DisplayPass from './Display'
import CreatePass from './Create'
import UpdatePass  from './Update'
import DisablePass from './Disable'


const TabsGeneral=[
  {
    label: "Cartes virtuelles"
  }
]

const TabsChilds=[
    {
      parent: "Cartes virtuelles",
      label: "Disponibles",
      container:"DisplayPass"
    },
    {
      parent: "Cartes virtuelles",
      label: "Créer une carte",
      container:"CreatePass"
    },
    {
      parent: "Cartes virtuelles",
      label: "Modifier une carte",
      container:"UpdatePass"
    },
    {
      parent: "Cartes virtuelles",
      label: "Désactiver une carte",
      container:"DisablePass"
    }
]
 
export default function AppleWallet() {
  const [active, setActive] = useState("Cartes virtuelles")
  const [container, setContainer] = useState("DisplayPass")

  return (
    <>
      <TabListDropdrown 
        active={active}
        setActive={setActive}
        container={container}
        setContainer={setContainer}
        tabList={TabsGeneral} 
        childs={TabsChilds}
        width='medium'
        liwidth='30px'
      />
        <TabContent active={container==="DisplayPass"}>
          <DisplayPass /> 
        </TabContent>
        <TabContent active={container==="CreatePass"}>
          <CreatePass /> 
        </TabContent>
        <TabContent active={container==="UpdatePass"}>
          <UpdatePass /> 
        </TabContent>
        <TabContent active={container==="DisablePass"}>
          <DisablePass /> 
        </TabContent>
    </>
  );
}