import { useState, useEffect } from 'react';
import endpoints from '../../../../mixins/endpoints';
import api from '../../../../mixins/api';
import apiBaseUrl from '../../../../mixins/apiBaseUrl';
import DialogComp from '../../../../components/Modals/Dialog'
import Button from "@mui/material/Button";
import ApplePass from '../../../../models/ApplePass'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import THEME from '../../../../styles/theme';
import { styled as styledComponent } from 'styled-components';
import { styled } from '@mui/material/styles'

export default function DeletePass() {
    const [dataApplePass, setDataApplePass] = useState<ApplePass[]>([]);
    const [displayDialog, setDisplayDialog] = useState(false)
    const [dialogMessage, setDialogMessage] = useState("")
    const [displayDialogConfirmRemove, setDisplayDialogConfirmRemove] = useState(false)
    const [passId, setPassId] = useState("")
    const [passName, setPassName] = useState("")

    useEffect(() => {
      fetchApplePass();
    }, []);
  
    const fetchApplePass = async () => {
      api.methods.get(apiBaseUrl.data().digitalFramework+ endpoints.data().GetAllApplePassesEndpoint + '?&filter=IsArchived eq false').then((response =>{
          setDataApplePass(response.data)
      })).catch((error)=>{
          alert(error)
          console.log(error)
      })
    }

    function formatDisplayName(value: string){
      return value.split('.')[0]
    }

    const preFillCard = (event: SelectChangeEvent) => {
      let value = event.target.value as string
      
      setPassName(value)
      let item = dataApplePass.find((item)=> item.passName === value)
      if(item !== undefined){
        setPassId(item.id)    
      }
    }

    const handleClose = () => {
      setDisplayDialogConfirmRemove(false);
      setDisplayDialog(false)
    };

    function checkInput(){
      if(passId !== ''){
        //disableApplePass()
        let name = passName.split('.')[0]
        setDialogMessage("Êtes-vous certains de vouloir désactiver la carte \"" + name + "\" ?")
        setDisplayDialogConfirmRemove(true)
      }
      else{
        setDialogMessage("Veuillez sélectionner la carte virtuelle")
        setDisplayDialog(true)
      }
    }



    function disableApplePass(){
        api.methods.delete(apiBaseUrl.data().digitalFramework + endpoints.data().DisableApplePassEndpoint + "?id=" + passId).then(() =>{
            setDialogMessage("La carte \"" + formatDisplayName(passName) + "\" vient d'être désactivée")
            setDisplayDialog(true)
            fetchApplePass()
        }).catch((error)=>{
            //alert(error)
            console.log(error)
            setDialogMessage("Une erreur est survenue : \"" + error)
            setDisplayDialog(true)
        })
    }

  return (
    <>
        <DialogComp type='main' display={displayDialog} setDisplay={setDisplayDialog} title='AGL - Carte virtuelle' text={dialogMessage}>customdialog</DialogComp>
        <Dialog
          open={displayDialogConfirmRemove} 
          onClose={handleClose}
          PaperComponent={PaperStyle}>
          <DialogTitle>Action irréversible</DialogTitle>
          <DialogContent>
            <ContentContainer >
                {dialogMessage}
            </ContentContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={disableApplePass} color="primary" autoFocus>
              <Color>Oui</Color>
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus>
              <Color>Non</Color>
            </Button>
          </DialogActions>
        </Dialog> 
        <center>
        La désactivation prend effet immédiatement sur tous les appareils qui possèdent la carte<br/>
        <FormControl sx={{ m: 1,width:'28ch' }} variant="standard">
            <InputLabel id="pass-label">Sélectionner une carte virtuelle</InputLabel>
            <Select
                labelId="pass-label"
                id="pass-select"
                value={passName}
                label="Sélectionner une carte virtuelle"
                onChange={preFillCard}
            >
                {dataApplePass.map((item, index = 0)=><MenuItem key={index++} value={item.passName}>{formatDisplayName(item.passName)}</MenuItem>)}
            </Select>
        </FormControl>
        <br/><br/>
        <Button onClick={checkInput}>Désactiver</Button>
        </center>
    </>
  );
}

const PaperStyle = styled(Paper)`
  background-color: ${THEME.background.color.general};
  color: ${THEME.text.color.general};
`;

const ContentContainer = styledComponent.div`
  color: ${THEME.text.color.general};
`;

const Color = styledComponent.div`
  color: white;
`;
