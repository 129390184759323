import { useState, useEffect } from 'react'
import { IInputProps } from '../../../../components/Forms/InputV2'
import moment from 'moment'
import Form from '../../../../components/Forms/FormV2'
import endpoints from '../../../../mixins/endpoints'
import api from '../../../../mixins/api'
import apiBaseUrl from '../../../../mixins/apiBaseUrl'
import Button from "@mui/material/Button";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Dialog from '../../../../components/Modals/Dialog';
import ApplePass from '../../../../models/ApplePass'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { blue, grey } from '@mui/material/colors';

export default function UpdatePass() {
  const [dataApplePass, setDataApplePass] = useState<ApplePass[]>([]);
  const [passNameSelected, setPassNameSelected] = useState("")
  const [passIdSelected, setPassIdSelected] = useState("")
  const [passStyl, setPassStyl] = useState("")
  const [barcodeType, setBarcodeType] = useState("")
  const [barcodeValue, setBarcodeValue] = useState("")
  const [barcodeAlternateText, setBarcodeAlternateText] = useState("")
  const [passName, setPassName] = useState("")
  const [organizationName, setOrganizationName] = useState("")
  const [description, setDescription] = useState("")
  const [coloursFormat, setColoursFormat] = useState("")
  const [foregroundColor, setForegroundColor] = useState("")
  const [backgroundColor, setBackgroundColor] = useState("")
  const [labelColor, setLabelColor] = useState("")
  const [logoText, setLogoText] = useState("")
  const [displayDialog, setDisplayDialog] = useState(false)
  const [dialogMessage, setDialogMessage] = useState("")
  const [extendImageSizeCheckbox, setExtendImageSizeCheckbox] = useState(false)
  const [valididyCheckbox, setValidityCheckbox] = useState(false)
  const [passValidityStart, setPassValidityStart] = useState('')
  const [passValidityEnd, setPassValidityEnd] = useState('')
  
  //const [properties, setProperties] = useState("")
  const [iconUrl, setIconUrl] = useState("")
  const [icon2xUrl, setIcon2xUrl] = useState("")
  const [icon3xUrl, setIcon3xUrl] = useState("")
  const [logoUrl, setLogoUrl] = useState("")
  const [logo2xUrl, setLogo2xUrl] = useState("")
  const [logo3xUrl, setLogo3xUrl] = useState("")
  const [thumbnailUrl, setThumbnailUrl] = useState("")
  const [thumbnail2xUrl, setThumbnail2xUrl] = useState("")
  const [thumbnail3xUrl, setThumbnail3xUrl] = useState("")
  const [backgroundUrl, setBackgroundUrl] = useState("")
  const [background2xUrl, setBackground2xUrl] = useState("")
  const [background3xUrl, setBackground3xUrl] = useState("")
  const [stripUrl, setStripUrl] = useState("")
  const [strip2xUrl, setStrip2xUrl] = useState("")
  const [strip3xUrl, setStrip3xUrl] = useState("")
  const [footerUrl, setFooterUrl] = useState("")
  const [footer2xUrl, setFooter2xUrl] = useState("")
  const [footer3xUrl, setFooter3xUrl] = useState("")

  const [nbHeaderField, setNbHeaderField] = useState("")
  const [nbPrimaryField, setNbPrimaryField] = useState("")
  const [nbSecondaryField, setNbSecondaryField] = useState("")
  const [nbAuxiliaryField, setNbAuxiliaryField] = useState("")
  const [nbBackField, setNbBackField] = useState("")
  const [headerFieldTitle1, setHeaderFieldTitle1] = useState("")
  const [headerFieldValue1, setHeaderFieldValue1] = useState("")
  const [headerFieldTitle2, setHeaderFieldTitle2] = useState("")
  const [headerFieldValue2, setHeaderFieldValue2] = useState("")
  const [headerFieldTitle3, setHeaderFieldTitle3] = useState("")
  const [headerFieldValue3, setHeaderFieldValue3] = useState("")
  const [primaryFieldTitle1, setPrimaryFieldTitle1] = useState("")
  const [primaryFieldValue1, setPrimaryFieldValue1] = useState("") 
  const [primaryFieldTitle2, setPrimaryFieldTitle2] = useState("") //BOARDING
  const [primaryFieldValue2, setPrimaryFieldValue2] = useState("") //BOARDING
  const [secondaryFieldTitle1, setSecondaryFieldTitle1] = useState("")
  const [secondaryFieldValue1, setSecondaryFieldValue1] = useState("")
  const [secondaryFieldTitle2, setSecondaryFieldTitle2] = useState("")
  const [secondaryFieldValue2, setSecondaryFieldValue2] = useState("")
  const [secondaryFieldTitle3, setSecondaryFieldTitle3] = useState("")
  const [secondaryFieldValue3, setSecondaryFieldValue3] = useState("")
  const [secondaryFieldTitle4, setSecondaryFieldTitle4] = useState("")
  const [secondaryFieldValue4, setSecondaryFieldValue4] = useState("")
  const [auxiliaryFieldTitle1, setAuxiliaryFieldTitle1] = useState("")
  const [auxiliaryFieldValue1, setAuxiliaryFieldValue1] = useState("")
  const [auxiliaryFieldTitle2, setAuxiliaryFieldTitle2] = useState("")
  const [auxiliaryFieldValue2, setAuxiliaryFieldValue2] = useState("")
  const [auxiliaryFieldTitle3, setAuxiliaryFieldTitle3] = useState("")
  const [auxiliaryFieldValue3, setAuxiliaryFieldValue3] = useState("")
  const [auxiliaryFieldTitle4, setAuxiliaryFieldTitle4] = useState("")
  const [auxiliaryFieldValue4, setAuxiliaryFieldValue4] = useState("")
  const [auxiliaryFieldTitle5, setAuxiliaryFieldTitle5] = useState("")
  const [auxiliaryFieldValue5, setAuxiliaryFieldValue5] = useState("")
  const [backFieldTitle1, setBackFieldTitle1] = useState("")
  const [backFieldValue1, setBackFieldValue1] = useState("")
  const [backFieldTitle2, setBackFieldTitle2] = useState("")
  const [backFieldValue2, setBackFieldValue2] = useState("")
  const [backFieldTitle3, setBackFieldTitle3] = useState("")
  const [backFieldValue3, setBackFieldValue3] = useState("")
  const [nfc, setNfc] = useState(false)
  const [nfcValue, setNfcValue] = useState("")
  const [templateStyl, setTemplateStyl] = useState("")

  //GENERAL RULE
  // 3 header fields
  // 1 primary field
  // 4 secondary fields
  // 4 auxiliary fields 
  // unlimited back fields but we gonna set it to 3 max
  //BOARDING RULE
  // 2 primary fields
  // 5 auxiliary fields
  //COUPON, STORECARD AND GENERIC (SQUARE) RULE
  // Total up of 4 secondary fields and auxiliary fields COMBINED

  const COLUMNLEFTBASE: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        disabled: true,
        id:'passName',
        label:'Nom de la carte',
        labelPosition: 'left',
        name:'passName',
        placeholder:'Nom de la carte',
        value: formatPassName(passName),
        setValue: setPassName
      },
      {
        input:'text',
        type:'full',
        id:'description',
        label:'Description de la carte',
        labelPosition: 'left',
        name:'description',
        placeholder:'Description de la carte',
        value: description,
        setValue: setDescription
      }
    ]
  const COLUMNRIGHTBASE: IInputProps[] = 
    [
      {
          input:'text',
          type:'full',
          id:'organizationName',
          label:"Nom de l'organisation",
          labelPosition: 'left',
          name:'organizationName',
          placeholder:"Nom de l'organisation",
          value: organizationName,
          setValue: setOrganizationName
      },
      {
        input:'text',
        type:'full',
        id:'logoText',
        label:'Texte du logo',
        labelPosition: 'left',
        name:'logoText',
        placeholder:'Texte du logo',
        value: logoText,
        setValue: setLogoText
      }
    ]
  const COLUMNLEFTBARCODE : IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'barcodeValue',
        label:'Code barre',
        labelPosition: 'left',
        name:'barcodeValue',
        placeholder:'Valeur du code barre',
        value: barcodeValue,
        setValue: setBarcodeValue
      }
    ]
  const COLUMNRIGHTBARCODE : IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'barcodeAlternateText',
        label:'Text alternatif du code barre',
        labelPosition: 'left',
        name:'barcodeAlternateText',
        placeholder:'Valeur du texte alternatif du code barre',
        value: barcodeAlternateText,
        setValue: setBarcodeAlternateText
      }
    ]
  const COLUMNRIGHTCOLOURS: IInputProps[] = 
    [
      {
          input:'text',
          type:'full',
          id:'foregroundColor',
          label:"Couleur du texte pour les valeurs",
          labelPosition: 'left',
          name:'foregroundColor',
          placeholder:"Si vide alors la couleur AGL sera appliqué par défaut",
          value: foregroundColor,
          setValue: setForegroundColor
      },
      {
          input:'text',
          type:'full',
          id:'backgroundColor',
          label:"Couleur d'arrière plan",
          labelPosition: 'left',
          name:'backgroundColor',
          placeholder:"Si vide alors la couleur AGL sera appliqué par défaut",
          value: backgroundColor,
          setValue: setBackgroundColor
      }
    ]
  const COLUMNLEFTCOLOURS: IInputProps[] = 
    [
      {
          input:'text',
          type:'full',
          id:'labelColor',
          label:"Couleur du texte pour les titres",
          labelPosition: 'left',
          name:'labelColor',
          placeholder:"Si vide alors la couleur AGL sera appliqué par défaut",
          value: labelColor,
          setValue: setLabelColor
      }
    ]
  const COLUMNLEFTVALIDITY: IInputProps[] = 
    [
        {
            input:'date',
            type:'full',
            readonly:true,
            id:'cardValidityStart',
            label:'Date de validité',
            labelPosition: 'left',
            name:'cardValidityStart',
            value: passValidityStart,
            setValue: setPassValidityStart
        }
    ]
  const COLUMNRIGHTVALIDITY: IInputProps[] = 
    [
        {
            input:'date',
            type:'full',
            required: true,
            id:'cardValidityEnd',
            label:"Date d'expiration",
            labelPosition: 'left',
            name:'cardValidityEnd',
            value: passValidityEnd,
            setValue: setPassValidityEnd
        }
    ] 
  //IMAGES
  const COLUMNLEFTGENERICIMAGES: IInputProps[] =
    [
      {
        input:'text',
        type:'full',
        id:'logoUrl',
        label:"URL logo",
        labelPosition: 'left',
        name:'logoUrl',
        placeholder:"URL logo",
        value: logoUrl,
        setValue: setLogoUrl
      },
      {
        input:'text',
        type:'full',
        id:'iconUrl',
        label:"URL icône",
        labelPosition: 'left',
        name:'iconUrl',
        placeholder:"URL icône",
        value: iconUrl,
        setValue: setIconUrl
      }
    ]
  const COLUMNRIGHTGENERICIMAGES: IInputProps[] =
    [
      {
        input:'text',
        type:'full',
        id:'thumbnailUrl',
        label:"URL vignette *",
        labelPosition: 'left',
        name:'thumbnailUrl',
        placeholder:"URL vignette",
        value: thumbnailUrl,
        setValue: setThumbnailUrl
      }
    ]
  const COLUMNLEFTGENERICIMAGESFULL: IInputProps[] =
    [
      {
        input:'text',
        type:'full',
        id:'logoUrl',
        label:"URL logo",
        labelPosition: 'left',
        name:'logoUrl',
        placeholder:"URL logo",
        value: logoUrl,
        setValue: setLogoUrl
      },
      {
        input:'text',
        type:'full',
        id:'logoUrlx2',
        label:"URL logo x2",
        labelPosition: 'left',
        name:'logoUrlx2',
        placeholder:"URL logo x2",
        value: logo2xUrl,
        setValue: setLogo2xUrl
      },
      {
        input:'text',
        type:'full',
        id:'logoUrlx3',
        label:"URL logo x3",
        labelPosition: 'left',
        name:'logoUrlx3',
        placeholder:"URL logo x3",
        value: logo3xUrl,
        setValue: setLogo3xUrl
      },
      {
        input:'text',
        type:'full',
        id:'iconUrl',
        label:"URL icône",
        labelPosition: 'left',
        name:'iconUrl',
        placeholder:"URL icône",
        value: iconUrl,
        setValue: setIconUrl
      },
      {
        input:'text',
        type:'full',
        id:'iconUrlx2',
        label:"URL icône x2",
        labelPosition: 'left',
        name:'iconUrlx2',
        placeholder:"URL icône x2",
        value: icon2xUrl,
        setValue: setIcon2xUrl
      },
      {
        input:'text',
        type:'full',
        id:'iconUrlx3',
        label:"URL icône x3",
        labelPosition: 'left',
        name:'iconUrlx3',
        placeholder:"URL icône x3",
        value: icon3xUrl,
        setValue: setIcon3xUrl
      }
    ]
  const COLUMNRIGHTGENERICIMAGESFULL: IInputProps[] =
    [
      {
        input:'text',
        type:'full',
        id:'thumbnailUrl',
        label:"URL vignette *",
        labelPosition: 'left',
        name:'thumbnailUrl',
        placeholder:"URL vignette",
        value: thumbnailUrl,
        setValue: setThumbnailUrl
      },
      {
        input:'text',
        type:'full',
        id:'thumbnailUrlx2',
        label:"URL vignette x2",
        labelPosition: 'left',
        name:'thumbnailUrlx2',
        placeholder:"URL vignette x2",
        value: thumbnail2xUrl,
        setValue: setThumbnail2xUrl
      },
      {
        input:'text',
        type:'full',
        id:'thumbnailUrlx3',
        label:"URL vignette x3",
        labelPosition: 'left',
        name:'thumbnailUrlx3',
        placeholder:"URL vignette x3",
        value: thumbnail3xUrl,
        setValue: setThumbnail3xUrl
      }
    ]
    
  const COLUMNRIGHTBOARDINGIMAGES: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'footerUrl',
        label:"URL pied de page",
        labelPosition: 'left',
        name:'footerUrl',
        placeholder:"URL pied de page",
        value: footerUrl,
        setValue: setFooterUrl
      }
    ]
  const COLUMNRIGHTBOARDINGIMAGESFULL: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'footerUrl',
        label:"URL pied de page",
        labelPosition: 'left',
        name:'footerUrl',
        placeholder:"URL pied de page",
        value: footerUrl,
        setValue: setFooterUrl
      },
      {
        input:'text',
        type:'full',
        id:'footerUrlx2',
        label:"URL pied de page x2",
        labelPosition: 'left',
        name:'footerUrlx2',
        placeholder:"URL pied de page x2",
        value: footer2xUrl,
        setValue: setFooter2xUrl
      },
      {
        input:'text',
        type:'full',
        id:'footerUrlx3',
        label:"URL pied de page x3",
        labelPosition: 'left',
        name:'footerUrlx3',
        placeholder:"URL pied de page x3",
        value: footer3xUrl,
        setValue: setFooter3xUrl
      }
    ]

  const COLUMNRIGHTCOUPONORSTOREOREVENTSTRIPIMAGES: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'stripUrl',
        label:"URL image en bande",
        labelPosition: 'left',
        name:'stripUrl',
        placeholder:"URL image en bande",
        value: stripUrl,
        setValue: setStripUrl
      }, 
    ]
  const COLUMNRIGHTCOUPONORSTOREOREVENTSTRIPIMAGESFULL: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'stripUrl',
        label:"URL image en bande",
        labelPosition: 'left',
        name:'stripUrl',
        placeholder:"URL image en bande",
        value: stripUrl,
        setValue: setStripUrl
      },
      {
        input:'text',
        type:'full',
        id:'stripUrlx2',
        label:"URL image en bande x2",
        labelPosition: 'left',
        name:'stripUrlx2',
        placeholder:"URL image en bande x2",
        value: strip2xUrl,
        setValue: setStrip2xUrl
      }, 
      {
        input:'text',
        type:'full',
        id:'stripUrlx3',
        label:"URL image en bande x3",
        labelPosition: 'left',
        name:'stripUrlx3',
        placeholder:"URL image en bande x3",
        value: strip3xUrl,
        setValue: setStrip3xUrl
      }, 
    ]

  const COLUMNRIGHTEVENTBACKGROUNDIMAGES: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'thumbnailUrl',
        label:"URL vignette",
        labelPosition: 'left',
        name:'thumbnailUrl',
        placeholder:"URL vignette",
        value: thumbnailUrl,
        setValue: setThumbnailUrl
      },
      {
        input:'text',
        type:'full',
        id:'backgroundUrl',
        label:"URL Image d'arrière plan",
        labelPosition: 'left',
        name:'backgroundUrl',
        placeholder:"URL Image d'arrière plan",
        value: backgroundUrl,
        setValue: setBackgroundUrl
      }
    ]
  const COLUMNRIGHTEVENTBACKGROUNDIMAGESFULL: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'thumbnailUrl',
        label:"URL vignette",
        labelPosition: 'left',
        name:'thumbnailUrl',
        placeholder:"URL vignette",
        value: thumbnailUrl,
        setValue: setThumbnailUrl
      },
      {
        input:'text',
        type:'full',
        id:'thumbnailUrlx2',
        label:"URL vignette x2",
        labelPosition: 'left',
        name:'thumbnailUrlx2',
        placeholder:"URL vignette x2",
        value: thumbnail2xUrl,
        setValue: setThumbnail2xUrl
      },
      {
        input:'text',
        type:'full',
        id:'thumbnailUrlx3',
        label:"URL vignette x3",
        labelPosition: 'left',
        name:'thumbnailUrlx3',
        placeholder:"URL vignette x3",
        value: thumbnail3xUrl,
        setValue: setThumbnail3xUrl
      },
      {
        input:'text',
        type:'full',
        id:'backgroundUrl',
        label:"URL Image d'arrière plan",
        labelPosition: 'left',
        name:'backgroundUrl',
        placeholder:"URL Image d'arrière plan",
        value: backgroundUrl,
        setValue: setBackgroundUrl
      },
      {
        input:'text',
        type:'full',
        id:'backgroundUrlx2',
        label:"URL Image d'arrière plan X2",
        labelPosition: 'left',
        name:'backgroundUrlx2',
        placeholder:"URL Image d'arrière plan X2",
        value: background2xUrl,
        setValue: setBackground2xUrl
      },
      {
        input:'text',
        type:'full',
        id:'backgroundUrlx3',
        label:"URL Image d'arrière plan X3",
        labelPosition: 'left',
        name:'backgroundUrlx3',
        placeholder:"URL Image d'arrière plan X3",
        value: background3xUrl,
        setValue: setBackground3xUrl
      }
    ]
  //ENDIMAGES
  
  const COLUMNLEFTHEADERFIELD1: IInputProps[] = 
    [
      {
          input:'text',
          type:'full',
          id:'headerFieldTitle1',
          label:"Titre de l'en-tête 1",
          labelPosition: 'left',
          name:'headerFieldTitle1',
          placeholder:"Title de l'en-tête 1",
          value: headerFieldTitle1,
          setValue: setHeaderFieldTitle1
      }
    ]
  const COLUMNRIGHTHEADERFIELD1: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'headerFieldValue1',
        label:"Valeur de l'en-tête 1",
        labelPosition: 'left',
        name:'headerFieldValue1',
        placeholder:"Valeur de l'en-tête 1",
        value: headerFieldValue1,
        setValue: setHeaderFieldValue1
      }
    ]
  const COLUMNLEFTHEADERFIELD2: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'headerFieldTitle1',
        label:"Titre de l'en-tête 1",
        labelPosition: 'left',
        name:'headerFieldTitle1',
        placeholder:"Title de l'en-tête 1",
        value: headerFieldTitle1,
        setValue: setHeaderFieldTitle1
      },
      {
        input:'text',
        type:'full',
        id:'headerFieldTitle2',
        label:"Titre de l'en-tête 2",
        labelPosition: 'left',
        name:'headerFieldTitle2',
        placeholder:"Title de l'en-tête 2",
        value: headerFieldTitle2,
        setValue: setHeaderFieldTitle2
      }
    ]
  const COLUMNRIGHTHEADERFIELD2: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'headerFieldValue1',
        label:"Valeur de l'en-tête 1",
        labelPosition: 'left',
        name:'headerFieldValue1',
        placeholder:"Valeur de l'en-tête 1",
        value: headerFieldValue1,
        setValue: setHeaderFieldValue1
      },
      {
        input:'text',
        type:'full',
        id:'headerFieldValue2',
        label:"Valeur de l'en-tête 2",
        labelPosition: 'left',
        name:'headerFieldValue2',
        placeholder:"Valeur de l'en-tête 2",
        value: headerFieldValue2,
        setValue: setHeaderFieldValue2
      }
    ]
  const COLUMNLEFTHEADERFIELD3: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'headerFieldTitle1',
        label:"Titre de l'en-tête 1",
        labelPosition: 'left',
        name:'headerFieldTitle1',
        placeholder:"Title de l'en-tête 1",
        value: headerFieldTitle1,
        setValue: setHeaderFieldTitle1
      },
      {
        input:'text',
        type:'full',
        id:'headerFieldTitle2',
        label:"Titre de l'en-tête 2",
        labelPosition: 'left',
        name:'headerFieldTitle2',
        placeholder:"Title de l'en-tête 2",
        value: headerFieldTitle2,
        setValue: setHeaderFieldTitle2
      },
      {
        input:'text',
        type:'full',
        id:'headerFieldTitle3',
        label:"Titre de l'en-tête 3",
        labelPosition: 'left',
        name:'headerFieldTitle3',
        placeholder:"Title de l'en-tête 3",
        value: headerFieldTitle3,
        setValue: setHeaderFieldTitle3
      }
    ]
  const COLUMNRIGHTHEADERFIELD3: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'headerFieldValue1',
        label:"Valeur de l'en-tête 1",
        labelPosition: 'left',
        name:'headerFieldValue1',
        placeholder:"Valeur de l'en-tête 1",
        value: headerFieldValue1,
        setValue: setHeaderFieldValue1
      },
      {
        input:'text',
        type:'full',
        id:'headerFieldValue2',
        label:"Valeur de l'en-tête 2",
        labelPosition: 'left',
        name:'headerFieldValue2',
        placeholder:"Valeur de l'en-tête 2",
        value: headerFieldValue2,
        setValue: setHeaderFieldValue2
      },
      {
        input:'text',
        type:'full',
        id:'headerFieldValue3',
        label:"Valeur de l'en-tête 3",
        labelPosition: 'left',
        name:'headerFieldValue3',
        placeholder:"Valeur de l'en-tête 3",
        value: headerFieldValue3,
        setValue: setHeaderFieldValue3
      }
    ]
  const COLUMNLEFTPRIMARYFIELD1: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'primaryFieldTitle1',
        label:"Titre du champ primaire 1",
        labelPosition: 'left',
        name:'primaryFieldTitle1',
        placeholder:"Titre du champ primaire 1",
        value: primaryFieldTitle1,
        setValue: setPrimaryFieldTitle1
      }
    ]
  const COLUMNRIGHTPRIMARYFIELD1: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'primaryFieldValue1',
        label:"Valeur du champ primaire 1",
        labelPosition: 'left',
        name:'primaryFieldValue1',
        placeholder:"Valeur du champ primaire 1",
        value: primaryFieldValue1,
        setValue: setPrimaryFieldValue1
      }
    ]
  const COLUMNLEFTPRIMARYFIELD2: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'primaryFieldTitle1',
        label:"Titre du champ primaire 1",
        labelPosition: 'left',
        name:'primaryFieldTitle1',
        placeholder:"Titre du champ primaire 1",
        value: primaryFieldTitle1,
        setValue: setPrimaryFieldTitle1
      },
      {
        input:'text',
        type:'full',
        id:'primaryFieldTitle2',
        label:"Titre du champ primaire 2",
        labelPosition: 'left',
        name:'primaryFieldTitle2',
        placeholder:"Titre du champ primaire 2",
        value: primaryFieldTitle2,
        setValue: setPrimaryFieldTitle2
      }
    ]
  const COLUMNRIGHTPRIMARYFIELD2: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'primaryFieldValue1',
        label:"Valeur du champ primaire 1",
        labelPosition: 'left',
        name:'primaryFieldValue1',
        placeholder:"Valeur du champ primaire 1",
        value: primaryFieldValue1,
        setValue: setPrimaryFieldValue1
      },
      {
        input:'text',
        type:'full',
        id:'primaryFieldValue2',
        label:"Valeur du champ primaire 2",
        labelPosition: 'left',
        name:'primaryFieldValue2',
        placeholder:"Valeur du champ primaire 2",
        value: primaryFieldValue2,
        setValue: setPrimaryFieldValue2
      }
    ]
  const COLUMNLEFTSECONDARYFIELD1: IInputProps[] = 
    [
      {
          input:'text',
          type:'full',
          id:'secondaryFieldTitle1',
          label:"Titre du champ secondaire 1",
          labelPosition: 'left',
          name:'secondaryFieldTitle1',
          placeholder:"Titre du champ secondaire 1",
          value: secondaryFieldTitle1,
          setValue: setSecondaryFieldTitle1
      }
    ]
  const COLUMNRIGHTSECONDARYFIELD1: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'secondaryFieldValue1',
        label:"Valeur du champ secondaire 1",
        labelPosition: 'left',
        name:'secondaryFieldValue1',
        placeholder:"Valeur du champ secondaire 1",
        value: secondaryFieldValue1,
        setValue: setSecondaryFieldValue1
      }
    ]
  const COLUMNLEFTSECONDARYFIELD2: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'secondaryFieldTitle1',
        label:"Titre du champ secondaire 1",
        labelPosition: 'left',
        name:'secondaryFieldTitle1',
        placeholder:"Titre du champ secondaire 1",
        value: secondaryFieldTitle1,
        setValue: setSecondaryFieldTitle1
      },
      {
        input:'text',
        type:'full',
        id:'secondaryFieldTitle2',
        label:"Titre du champ secondaire 2",
        labelPosition: 'left',
        name:'secondaryFieldTitle2',
        placeholder:"Titre du champ secondaire 2",
        value: secondaryFieldTitle2,
        setValue: setSecondaryFieldTitle2
      }
    ]
  const COLUMNRIGHTSECONDARYFIELD2: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'secondaryFieldValue1',
        label:"Valeur du champ secondaire 1",
        labelPosition: 'left',
        name:'secondaryFieldValue1',
        placeholder:"Valeur du champ secondaire 1",
        value: secondaryFieldValue1,
        setValue: setSecondaryFieldValue1
      },
      {
        input:'text',
        type:'full',
        id:'secondaryFieldValue2',
        label:"Valeur du champ secondaire 2",
        labelPosition: 'left',
        name:'secondaryFieldValue2',
        placeholder:"Valeur du champ secondaire 2",
        value: secondaryFieldValue2,
        setValue: setSecondaryFieldValue2
      }
    ]
  const COLUMNLEFTSECONDARYFIELD3: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'secondaryFieldTitle1',
        label:"Titre du champ secondaire 1",
        labelPosition: 'left',
        name:'secondaryFieldTitle1',
        placeholder:"Titre du champ secondaire 1",
        value: secondaryFieldTitle1,
        setValue: setSecondaryFieldTitle1
      },
      {
        input:'text',
        type:'full',
        id:'secondaryFieldTitle2',
        label:"Titre du champ secondaire 2",
        labelPosition: 'left',
        name:'secondaryFieldTitle2',
        placeholder:"Titre du champ secondaire 2",
        value: secondaryFieldTitle2,
        setValue: setSecondaryFieldTitle2
      },
      {
        input:'text',
        type:'full',
        id:'secondaryFieldTitle3',
        label:"Titre du champ secondaire 3",
        labelPosition: 'left',
        name:'secondaryFieldTitle3',
        placeholder:"Titre du champ secondaire 3",
        value: secondaryFieldTitle3,
        setValue: setSecondaryFieldTitle3
      }
    ]
  const COLUMNRIGHTSECONDARYFIELD3: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'secondaryFieldValue1',
        label:"Valeur du champ secondaire 1",
        labelPosition: 'left',
        name:'secondaryFieldValue1',
        placeholder:"Valeur du champ secondaire 1",
        value: secondaryFieldValue1,
        setValue: setSecondaryFieldValue1
      },
      {
        input:'text',
        type:'full',
        id:'secondaryFieldValue2',
        label:"Valeur du champ secondaire 2",
        labelPosition: 'left',
        name:'secondaryFieldValue2',
        placeholder:"Valeur du champ secondaire 2",
        value: secondaryFieldValue2,
        setValue: setSecondaryFieldValue2
      },
      {
        input:'text',
        type:'full',
        id:'secondaryFieldValue3',
        label:"Valeur du champ secondaire 3",
        labelPosition: 'left',
        name:'secondaryFieldValue3',
        placeholder:"Valeur du champ secondaire 3",
        value: secondaryFieldValue3,
        setValue: setSecondaryFieldValue3
      }
    ]
  const COLUMNLEFTSECONDARYFIELD4: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'secondaryFieldTitle1',
        label:"Titre du champ secondaire 1",
        labelPosition: 'left',
        name:'secondaryFieldTitle1',
        placeholder:"Titre du champ secondaire 1",
        value: secondaryFieldTitle1,
        setValue: setSecondaryFieldTitle1
      },
      {
        input:'text',
        type:'full',
        id:'secondaryFieldTitle2',
        label:"Titre du champ secondaire 2",
        labelPosition: 'left',
        name:'secondaryFieldTitle2',
        placeholder:"Titre du champ secondaire 2",
        value: secondaryFieldTitle2,
        setValue: setSecondaryFieldTitle2
      },
      {
        input:'text',
        type:'full',
        id:'secondaryFieldTitle3',
        label:"Titre du champ secondaire 3",
        labelPosition: 'left',
        name:'secondaryFieldTitle3',
        placeholder:"Titre du champ secondaire 3",
        value: secondaryFieldTitle3,
        setValue: setSecondaryFieldTitle3
      },
      {
        input:'text',
        type:'full',
        id:'secondaryFieldTitle4',
        label:"Titre du champ secondaire 4",
        labelPosition: 'left',
        name:'secondaryFieldTitle4',
        placeholder:"Titre du champ secondaire 4",
        value: secondaryFieldTitle4,
        setValue: setSecondaryFieldTitle4
      }
    ]
  const COLUMNRIGHTSECONDARYFIELD4: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'secondaryFieldValue1',
        label:"Valeur du champ secondaire 1",
        labelPosition: 'left',
        name:'secondaryFieldValue1',
        placeholder:"Valeur du champ secondaire 1",
        value: secondaryFieldValue1,
        setValue: setSecondaryFieldValue1
      },
      {
        input:'text',
        type:'full',
        id:'secondaryFieldValue2',
        label:"Valeur du champ secondaire 2",
        labelPosition: 'left',
        name:'secondaryFieldValue2',
        placeholder:"Valeur du champ secondaire 2",
        value: secondaryFieldValue2,
        setValue: setSecondaryFieldValue2
      },
      {
        input:'text',
        type:'full',
        id:'secondaryFieldValue3',
        label:"Valeur du champ secondaire 3",
        labelPosition: 'left',
        name:'secondaryFieldValue3',
        placeholder:"Valeur du champ secondaire 3",
        value: secondaryFieldValue3,
        setValue: setSecondaryFieldValue3
      },
      {
        input:'text',
        type:'full',
        id:'secondaryFieldValue4',
        label:"Valeur du champ secondaire 4",
        labelPosition: 'left',
        name:'secondaryFieldValue4',
        placeholder:"Valeur du champ secondaire 4",
        value: secondaryFieldValue4,
        setValue: setSecondaryFieldValue4
      }
    ]
  const COLUMNLEFTAUXILIARYFIELD1: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldTitle1',
        label:"Titre du champ auxiliaire 1",
        labelPosition: 'left',
        name:'auxiliaryFieldTitle1',
        placeholder:"Titre du champ auxiliaire 1",
        value: auxiliaryFieldTitle1,
        setValue: setAuxiliaryFieldTitle1
      }
    ]
  const COLUMNRIGHTAUXILIARYFIELD1: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldValue1',
        label:"Valeur du champ auxiliaire 1",
        labelPosition: 'left',
        name:'auxiliaryFieldValue1',
        placeholder:"Valeur du champ auxiliaire 1",
        value: auxiliaryFieldValue1,
        setValue: setAuxiliaryFieldValue1
      }
    ]
  const COLUMNLEFTAUXILIARYFIELD2: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldTitle1',
        label:"Titre du champ auxiliaire 1",
        labelPosition: 'left',
        name:'auxiliaryFieldTitle1',
        placeholder:"Titre du champ auxiliaire 1",
        value: auxiliaryFieldTitle1,
        setValue: setAuxiliaryFieldTitle1
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldTitle2',
        label:"Titre du champ auxiliaire 2",
        labelPosition: 'left',
        name:'auxiliaryFieldTitle2',
        placeholder:"Titre du champ auxiliaire 2",
        value: auxiliaryFieldTitle2,
        setValue: setAuxiliaryFieldTitle2
      }
    ]
  const COLUMNRIGHTAUXILIARYFIELD2: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldValue1',
        label:"Valeur du champ auxiliaire 1",
        labelPosition: 'left',
        name:'auxiliaryFieldValue1',
        placeholder:"Valeur du champ auxiliaire 1",
        value: auxiliaryFieldValue1,
        setValue: setAuxiliaryFieldValue1
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldValue2',
        label:"Valeur du champ auxiliaire 2",
        labelPosition: 'left',
        name:'auxiliaryFieldValue2',
        placeholder:"Valeur du champ auxiliaire 2",
        value: auxiliaryFieldValue2,
        setValue: setAuxiliaryFieldValue2
      }
    ]
  const COLUMNLEFTAUXILIARYFIELD3: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldTitle1',
        label:"Titre du champ auxiliaire 1",
        labelPosition: 'left',
        name:'auxiliaryFieldTitle1',
        placeholder:"Titre du champ auxiliaire 1",
        value: auxiliaryFieldTitle1,
        setValue: setAuxiliaryFieldTitle1
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldTitle2',
        label:"Titre du champ auxiliaire 2",
        labelPosition: 'left',
        name:'auxiliaryFieldTitle2',
        placeholder:"Titre du champ auxiliaire 2",
        value: auxiliaryFieldTitle2,
        setValue: setAuxiliaryFieldTitle2
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldTitle3',
        label:"Titre du champ auxiliaire 3",
        labelPosition: 'left',
        name:'auxiliaryFieldTitle3',
        placeholder:"Titre du champ auxiliaire 3",
        value: auxiliaryFieldTitle3,
        setValue: setAuxiliaryFieldTitle3
      }
    ]
  const COLUMNRIGHTAUXILIARYFIELD3: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldValue1',
        label:"Valeur du champ auxiliaire 1",
        labelPosition: 'left',
        name:'auxiliaryFieldValue1',
        placeholder:"Valeur du champ auxiliaire 1",
        value: auxiliaryFieldValue1,
        setValue: setAuxiliaryFieldValue1
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldValue2',
        label:"Valeur du champ auxiliaire 2",
        labelPosition: 'left',
        name:'auxiliaryFieldValue2',
        placeholder:"Valeur du champ auxiliaire 2",
        value: auxiliaryFieldValue2,
        setValue: setAuxiliaryFieldValue2
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldValue3',
        label:"Valeur du champ auxiliaire 3",
        labelPosition: 'left',
        name:'auxiliaryFieldValue3',
        placeholder:"Valeur du champ auxiliaire 3",
        value: auxiliaryFieldValue3,
        setValue: setAuxiliaryFieldValue3
      }
    ]
  const COLUMNLEFTAUXILIARYFIELD4: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldTitle1',
        label:"Titre du champ auxiliaire 1",
        labelPosition: 'left',
        name:'auxiliaryFieldTitle1',
        placeholder:"Titre du champ auxiliaire 1",
        value: auxiliaryFieldTitle1,
        setValue: setAuxiliaryFieldTitle1
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldTitle2',
        label:"Titre du champ auxiliaire 2",
        labelPosition: 'left',
        name:'auxiliaryFieldTitle2',
        placeholder:"Titre du champ auxiliaire 2",
        value: auxiliaryFieldTitle2,
        setValue: setAuxiliaryFieldTitle2
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldTitle3',
        label:"Titre du champ auxiliaire 3",
        labelPosition: 'left',
        name:'auxiliaryFieldTitle3',
        placeholder:"Titre du champ auxiliaire 3",
        value: auxiliaryFieldTitle3,
        setValue: setAuxiliaryFieldTitle3
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldTitle4',
        label:"Titre du champ auxiliaire 4",
        labelPosition: 'left',
        name:'auxiliaryFieldTitle4',
        placeholder:"Titre du champ auxiliaire 4",
        value: auxiliaryFieldTitle4,
        setValue: setAuxiliaryFieldTitle4
      }
    ]
  const COLUMNRIGHTAUXILIARYFIELD4: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldValue1',
        label:"Valeur du champ auxiliaire 1",
        labelPosition: 'left',
        name:'auxiliaryFieldValue1',
        placeholder:"Valeur du champ auxiliaire 1",
        value: auxiliaryFieldValue1,
        setValue: setAuxiliaryFieldValue1
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldValue2',
        label:"Valeur du champ auxiliaire 2",
        labelPosition: 'left',
        name:'auxiliaryFieldValue2',
        placeholder:"Valeur du champ auxiliaire 2",
        value: auxiliaryFieldValue2,
        setValue: setAuxiliaryFieldValue2
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldValue3',
        label:"Valeur du champ auxiliaire 3",
        labelPosition: 'left',
        name:'auxiliaryFieldValue3',
        placeholder:"Valeur du champ auxiliaire 3",
        value: auxiliaryFieldValue3,
        setValue: setAuxiliaryFieldValue3
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldValue4',
        label:"Valeur du champ auxiliaire 4",
        labelPosition: 'left',
        name:'auxiliaryFieldValue4',
        placeholder:"Valeur du champ auxiliaire 4",
        value: auxiliaryFieldValue4,
        setValue: setAuxiliaryFieldValue4
      }
    ]
  const COLUMNLEFTAUXILIARYFIELD5: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldTitle1',
        label:"Titre du champ auxiliaire 1",
        labelPosition: 'left',
        name:'auxiliaryFieldTitle1',
        placeholder:"Titre du champ auxiliaire 1",
        value: auxiliaryFieldTitle1,
        setValue: setAuxiliaryFieldTitle1
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldTitle2',
        label:"Titre du champ auxiliaire 2",
        labelPosition: 'left',
        name:'auxiliaryFieldTitle2',
        placeholder:"Titre du champ auxiliaire 2",
        value: auxiliaryFieldTitle2,
        setValue: setAuxiliaryFieldTitle2
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldTitle3',
        label:"Titre du champ auxiliaire 3",
        labelPosition: 'left',
        name:'auxiliaryFieldTitle3',
        placeholder:"Titre du champ auxiliaire 3",
        value: auxiliaryFieldTitle3,
        setValue: setAuxiliaryFieldTitle3
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldTitle4',
        label:"Titre du champ auxiliaire 4",
        labelPosition: 'left',
        name:'auxiliaryFieldTitle4',
        placeholder:"Titre du champ auxiliaire 4",
        value: auxiliaryFieldTitle4,
        setValue: setAuxiliaryFieldTitle4
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldTitle5',
        label:"Titre du champ auxiliaire 5",
        labelPosition: 'left',
        name:'auxiliaryFieldTitle5',
        placeholder:"Titre du champ auxiliaire 5",
        value: auxiliaryFieldTitle5,
        setValue: setAuxiliaryFieldTitle5
      }
    ]
  const COLUMNRIGHTAUXILIARYFIELD5: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldValue1',
        label:"Valeur du champ auxiliaire 1",
        labelPosition: 'left',
        name:'auxiliaryFieldValue1',
        placeholder:"Valeur du champ auxiliaire 1",
        value: auxiliaryFieldValue1,
        setValue: setAuxiliaryFieldValue1
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldValue2',
        label:"Valeur du champ auxiliaire 2",
        labelPosition: 'left',
        name:'auxiliaryFieldValue2',
        placeholder:"Valeur du champ auxiliaire 2",
        value: auxiliaryFieldValue2,
        setValue: setAuxiliaryFieldValue2
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldValue3',
        label:"Valeur du champ auxiliaire 3",
        labelPosition: 'left',
        name:'auxiliaryFieldValue3',
        placeholder:"Valeur du champ auxiliaire 3",
        value: auxiliaryFieldValue3,
        setValue: setAuxiliaryFieldValue3
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldValue4',
        label:"Valeur du champ auxiliaire 4",
        labelPosition: 'left',
        name:'auxiliaryFieldValue4',
        placeholder:"Valeur du champ auxiliaire 4",
        value: auxiliaryFieldValue4,
        setValue: setAuxiliaryFieldValue4
      },
      {
        input:'text',
        type:'full',
        id:'auxiliaryFieldValue5',
        label:"Valeur du champ auxiliaire 5",
        labelPosition: 'left',
        name:'auxiliaryFieldValue5',
        placeholder:"Valeur du champ auxiliaire 5",
        value: auxiliaryFieldValue5,
        setValue: setAuxiliaryFieldValue5
      }
    ]
  const COLUMNLEFTBACKFIELD1: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'backFieldTitle1',
        label:"Titre du champ verso 1",
        labelPosition: 'left',
        name:'backFieldTitle1',
        placeholder:"Titre du champ verso 1",
        value: backFieldTitle1,
        setValue: setBackFieldTitle1
      }
    ]
  const COLUMNRIGHTBACKFIELD1: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'backFieldValue1',
        label:"Valeur du champ verso 1",
        labelPosition: 'left',
        name:'backFieldValue1',
        placeholder:"Valeur du champ verso 1",
        value: backFieldValue1,
        setValue: setBackFieldValue1
      }
    ]
  const COLUMNLEFTBACKFIELD2: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'backFieldTitle1',
        label:"Titre du champ verso 1",
        labelPosition: 'left',
        name:'backFieldTitle1',
        placeholder:"Titre du champ verso 1",
        value: backFieldTitle1,
        setValue: setBackFieldTitle1
      },
      {
        input:'text',
        type:'full',
        id:'backFieldTitle2',
        label:"Titre du champ verso 2",
        labelPosition: 'left',
        name:'backFieldTitle1',
        placeholder:"Titre du champ verso 2",
        value: backFieldTitle2,
        setValue: setBackFieldTitle2
      }
    ]
  const COLUMNRIGHTBACKFIELD2: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'backFieldValue1',
        label:"Valeur du champ verso 1",
        labelPosition: 'left',
        name:'backFieldValue1',
        placeholder:"Valeur du champ verso 1",
        value: backFieldValue1,
        setValue: setBackFieldValue1
      },
      {
        input:'text',
        type:'full',
        id:'backFieldValue2',
        label:"Valeur du champ verso 2",
        labelPosition: 'left',
        name:'backFieldValue2',
        placeholder:"Valeur du champ verso 2",
        value: backFieldValue2,
        setValue: setBackFieldValue2
      }
    ]
  const COLUMNLEFTBACKFIELD3: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'backFieldTitle1',
        label:"Titre du champ verso 1",
        labelPosition: 'left',
        name:'backFieldTitle1',
        placeholder:"Titre du champ verso 1",
        value: backFieldTitle1,
        setValue: setBackFieldTitle1
      },
      {
        input:'text',
        type:'full',
        id:'backFieldTitle2',
        label:"Titre du champ verso 2",
        labelPosition: 'left',
        name:'backFieldTitle1',
        placeholder:"Titre du champ verso 2",
        value: backFieldTitle2,
        setValue: setBackFieldTitle2
      },
      {
        input:'text',
        type:'full',
        id:'backFieldTitle3',
        label:"Titre du champ verso 3",
        labelPosition: 'left',
        name:'backFieldTitle3',
        placeholder:"Titre du champ verso 3",
        value: backFieldTitle3,
        setValue: setBackFieldTitle3
      }
    ]
  const COLUMNRIGHTBACKFIELD3: IInputProps[] = 
    [
      {
        input:'text',
        type:'full',
        id:'backFieldValue1',
        label:"Valeur du champ verso 1",
        labelPosition: 'left',
        name:'backFieldValue1',
        placeholder:"Valeur du champ verso 1",
        value: backFieldValue1,
        setValue: setBackFieldValue1
      },
      {
        input:'text',
        type:'full',
        id:'backFieldValue2',
        label:"Valeur du champ verso 2",
        labelPosition: 'left',
        name:'backFieldValue2',
        placeholder:"Valeur du champ verso 2",
        value: backFieldValue2,
        setValue: setBackFieldValue2
      }
      ,
      {
        input:'text',
        type:'full',
        id:'backFieldValue3',
        label:"Valeur du champ verso 3",
        labelPosition: 'left',
        name:'backFieldValue3',
        placeholder:"Valeur du champ verso 3",
        value: backFieldValue3,
        setValue: setBackFieldValue3
      }
    ]
    

  useEffect(() => {
    fetchApplePass()
  }, []);

  const fetchApplePass = async () => {
    api.methods.get(apiBaseUrl.data().digitalFramework+ endpoints.data().GetAllApplePassesEndpoint + '?&filter=IsArchived eq false').then((response =>{
        setDataApplePass(response.data)
    })).catch((error)=>{
        alert(error)
        console.log(error)
    })
  }

function initValues(){
   setPassIdSelected("")
   setPassNameSelected("")
   setPassStyl("")
   setBarcodeType("")
   setBarcodeValue("")
   setBarcodeAlternateText("")
   setPassName("")
   setOrganizationName("")
   setDescription("")
   setColoursFormat("")
   setForegroundColor("")
   setBackgroundColor("")
   setLabelColor("")
   setLogoText("")
   setExtendImageSizeCheckbox(false)

   setPassValidityStart("")
   setPassValidityEnd("")
   
   initImages()
   initStandardFields()
}

function initImages(){
   setIconUrl("")
   setIcon2xUrl("")
   setIcon3xUrl("")
   setLogoUrl("")
   setLogo2xUrl("")
   setLogo3xUrl("")
   setThumbnailUrl("")
   setThumbnail2xUrl("")
   setThumbnail3xUrl("")
   setBackgroundUrl("")
   setBackground2xUrl("")
   setBackground3xUrl("")
   setStripUrl("")
   setStrip2xUrl("")
   setStrip3xUrl("")
   setFooterUrl("")
   setFooter2xUrl("")
   setFooter3xUrl("")
}

function initStandardFields(){
  setNbHeaderField("")
  setNbPrimaryField("")
  setNbSecondaryField("")
  setNbAuxiliaryField("")

  setHeaderFieldTitle1("")
  setHeaderFieldValue1("")
  setHeaderFieldTitle2("")
  setHeaderFieldValue2("")
  setHeaderFieldTitle3("")
  setHeaderFieldValue3("")

  setPrimaryFieldTitle1("")
  setPrimaryFieldValue1("")
  setPrimaryFieldTitle2("")
  setPrimaryFieldValue2("")

  setSecondaryFieldTitle1("")
  setSecondaryFieldValue1("")
  setSecondaryFieldTitle2("")
  setSecondaryFieldValue2("")
  setSecondaryFieldTitle3("")
  setSecondaryFieldValue3("")
  setSecondaryFieldTitle4("")
  setSecondaryFieldValue4("")

  setAuxiliaryFieldTitle1("")
  setAuxiliaryFieldValue1("")
  setAuxiliaryFieldTitle2("")
  setAuxiliaryFieldValue2("")
  setAuxiliaryFieldTitle3("")
  setAuxiliaryFieldValue3("")
  setAuxiliaryFieldTitle4("")
  setAuxiliaryFieldValue4("")
}

function formatPassName(value: string){
  let result = ''
  if(value !== ''){
      result = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "").toLowerCase()
  }
  return result
}

const updatePassStyl = (event: SelectChangeEvent) => {
  setPassStyl(event.target.value as string)
  setTemplateStyl('0')
  initImages()
  initStandardFields()
}

const updateBarcodeType = (event: SelectChangeEvent) => {
    setBarcodeType(event.target.value as string)
}

const updateTemplateStyl = (event: SelectChangeEvent) => {
    setTemplateStyl(event.target.value as string)
}

const updateColoursFormat = (event: SelectChangeEvent) => {
  setColoursFormat(event.target.value as string)
}

const updateNbHeaderField = (event: SelectChangeEvent) => {
  setNbHeaderField(event.target.value as string)
}

const updateNbPrimaryField = (event: SelectChangeEvent) => {
  setNbPrimaryField(event.target.value as string)
}

const updateNbSecondaryField = (event: SelectChangeEvent) => {
  setNbSecondaryField(event.target.value as string)
}

const updateNbAuxiliaryField = (event: SelectChangeEvent) => {
  setNbAuxiliaryField(event.target.value as string)
}

const updateNbBackField = (event: SelectChangeEvent) => {
  setNbBackField(event.target.value as string)
}

function updateExtendImageSizeCheckbox(){
  if(!extendImageSizeCheckbox){
    setExtendImageSizeCheckbox(true)
  }
  else{
    setExtendImageSizeCheckbox(false)
  }
}

function formatDisplayName(value: string){
  return value.split('.')[0]
}

const preFillCard = (event: SelectChangeEvent) => {
  let value = event.target.value as string
  setPassNameSelected(value)

  let item = dataApplePass.find((item)=> item.passName === value)
  if(item !== undefined){
    setPassIdSelected(item.id)
    setPassStyl(String(item.style))
    setColoursFormat(String(item.coloursFormat))
    setPassName(formatDisplayName(item.passName))
    setDescription(item.description)
    setOrganizationName(item.organizationName)
    setLogoText(item.logoText)
    setLabelColor(item.labelColor)
    setForegroundColor(item.foregroundColor)
    setBackgroundColor(item.backgroundColor)

    InitValidity(item.created, item.relevantDate)
    initProperties(item.properties)

    if(item.style == 1 || item.style == 3){
      setTemplateStyl(String(item.model)) 
    }
  }
}

function InitValidity(start: string, end: string){
  const startDate = moment(new Date(start)).format('YYYY-MM-DD');
  const endDate = moment(new Date(end)).format('YYYY-MM-DD');

  setPassValidityStart(startDate)
  setPassValidityEnd(endDate)
}

function initProperties(value: string){
  const properties = JSON.parse(value)
  let nbHeader = properties.HeaderFields?.length ?? 0
  let nbPrimary = properties.PrimaryFields?.length ?? 0
  let nbSecondary = properties.SecondaryFields?.length ?? 0
  let nbAuxiliary = properties.AuxiliaryFields?.length ?? 0
  let nbBack = properties.BackFields?.length ?? 0

  setNbHeaderField(nbHeader)
  setNbPrimaryField(nbPrimary)
  setNbSecondaryField(nbSecondary)
  setNbAuxiliaryField(nbAuxiliary)
  setNbBackField(nbBack)

  if(properties.Barcodes?.length > 0){
    setBarcodeType(properties.Barcodes[0]?.Type)
    setBarcodeValue(properties.Barcodes[0]?.Message)
    setBarcodeAlternateText(properties.Barcodes[0]?.AlternateText)
  }

  //Images
  setIconUrl(properties.IconUrl)
  setIcon2xUrl(properties.Icon2xUrl)
  setIcon3xUrl(properties.Icon3xUrl)
  setLogoUrl(properties.LogoUrl)
  setLogo2xUrl(properties.Logo2xUrl)
  setLogo3xUrl(properties.Logo3xUrl)
  setThumbnailUrl(properties.ThumbnailUrl)
  setThumbnail2xUrl(properties.Thumbnail2xUrl)
  setThumbnail3xUrl(properties.Thumbnail3xUrl)
  setBackgroundUrl(properties.BackgroundUrl)
  setBackground2xUrl(properties.Background2xUrl)
  setBackground3xUrl(properties.Background3xUrl)
  setStripUrl(properties.StripUrl)
  setStrip2xUrl(properties.Strip2xUrl)
  setStrip3xUrl(properties.Strip3xUrl)
  setFooterUrl(properties.FooterUrl)
  setFooter2xUrl(properties.Footer2xUrl)
  setFooter3xUrl(properties.Footer3xUrl)

  //Standard fields
  switch(nbHeader){
    case 1:
      setHeaderFieldTitle1(properties.HeaderFields[0]?.Label)
      setHeaderFieldValue1(properties.HeaderFields[0]?.Value)
      break;
    case 2:
      setHeaderFieldTitle1(properties.HeaderFields[0]?.Label)
      setHeaderFieldValue1(properties.HeaderFields[0]?.Value)
      setHeaderFieldTitle2(properties.HeaderFields[1]?.Label)
      setHeaderFieldValue2(properties.HeaderFields[1]?.Value)
      break;
    case 3:
      setHeaderFieldTitle1(properties.HeaderFields[0]?.Label)
      setHeaderFieldValue1(properties.HeaderFields[0]?.Value)
      setHeaderFieldTitle2(properties.HeaderFields[1]?.Label)
      setHeaderFieldValue2(properties.HeaderFields[1]?.Value)
      setHeaderFieldTitle3(properties.HeaderFields[3]?.Label)
      setHeaderFieldValue3(properties.HeaderFields[3]?.Value)
      break;
    default:
      break;
  }

  switch(nbPrimary){
    case 1:
      setPrimaryFieldTitle1(properties.PrimaryFields[0]?.Label)
      setPrimaryFieldValue1(properties.PrimaryFields[0]?.Value)
      break;
    case 2:
      setPrimaryFieldTitle1(properties.PrimaryFields[0]?.Label)
      setPrimaryFieldValue1(properties.PrimaryFields[0]?.Value)
      setPrimaryFieldTitle2(properties.PrimaryFields[1]?.Label)
      setPrimaryFieldValue2(properties.PrimaryFields[1]?.Value)
      break;
    default:
      break;
  }

  switch(nbSecondary){
    case 1:
      setSecondaryFieldTitle1(properties.SecondaryFields[0]?.Label)
      setSecondaryFieldValue1(properties.SecondaryFields[0]?.Value)
      break;
    case 2:
      setSecondaryFieldTitle1(properties.SecondaryFields[0]?.Label)
      setSecondaryFieldValue1(properties.SecondaryFields[0]?.Value)
      setSecondaryFieldTitle2(properties.SecondaryFields[1]?.Label)
      setSecondaryFieldValue2(properties.SecondaryFields[1]?.Value)
      break;
    default:
      break;
  }
  
  switch(nbAuxiliary){
    case 1:
      setAuxiliaryFieldTitle1(properties.AuxiliaryFields[0]?.Label)
      setAuxiliaryFieldValue1(properties.AuxiliaryFields[0]?.Value)
      break;
    case 2:
      setAuxiliaryFieldTitle1(properties.AuxiliaryFields[0]?.Label)
      setAuxiliaryFieldValue1(properties.AuxiliaryFields[0]?.Value)
      setAuxiliaryFieldTitle2(properties.AuxiliaryFields[1]?.Label)
      setAuxiliaryFieldValue2(properties.AuxiliaryFields[1]?.Value)
      break;
    case 3:
      setAuxiliaryFieldTitle1(properties.AuxiliaryFields[0]?.Label)
      setAuxiliaryFieldValue1(properties.AuxiliaryFields[0]?.Value)
      setAuxiliaryFieldTitle2(properties.AuxiliaryFields[1]?.Label)
      setAuxiliaryFieldValue2(properties.AuxiliaryFields[1]?.Value)
      setAuxiliaryFieldTitle3(properties.AuxiliaryFields[2]?.Label)
      setAuxiliaryFieldValue3(properties.AuxiliaryFields[2]?.Value)
      break;
    case 4:
      setAuxiliaryFieldTitle1(properties.AuxiliaryFields[0]?.Label)
      setAuxiliaryFieldValue1(properties.AuxiliaryFields[0]?.Value)
      setAuxiliaryFieldTitle2(properties.AuxiliaryFields[1]?.Label)
      setAuxiliaryFieldValue2(properties.AuxiliaryFields[1]?.Value)
      setAuxiliaryFieldTitle3(properties.AuxiliaryFields[2]?.Label)
      setAuxiliaryFieldValue3(properties.AuxiliaryFields[2]?.Value)
      setAuxiliaryFieldTitle4(properties.AuxiliaryFields[3]?.Label)
      setAuxiliaryFieldValue4(properties.AuxiliaryFields[3]?.Value)
      break;
    case 5:
      setAuxiliaryFieldTitle1(properties.AuxiliaryFields[0]?.Label)
      setAuxiliaryFieldValue1(properties.AuxiliaryFields[0]?.Value)
      setAuxiliaryFieldTitle2(properties.AuxiliaryFields[1]?.Label)
      setAuxiliaryFieldValue2(properties.AuxiliaryFields[1]?.Value)
      setAuxiliaryFieldTitle3(properties.AuxiliaryFields[2]?.Label)
      setAuxiliaryFieldValue3(properties.AuxiliaryFields[2]?.Value)
      setAuxiliaryFieldTitle4(properties.AuxiliaryFields[3]?.Label)
      setAuxiliaryFieldValue4(properties.AuxiliaryFields[3]?.Value)
      setAuxiliaryFieldTitle5(properties.AuxiliaryFields[4]?.Label)
      setAuxiliaryFieldValue5(properties.AuxiliaryFields[4]?.Value)
      break;
    default:
      break;
  }

  switch(nbBack){
    case 1:
      setBackFieldTitle1(properties.BackFields[0]?.Label)
      setBackFieldValue1(properties.BackFields[0]?.Value)
      break;
    case 2:
      setBackFieldTitle1(properties.BackFields[0]?.Label)
      setBackFieldValue1(properties.BackFields[0]?.Value)
      setBackFieldTitle2(properties.BackFields[1]?.Label)
      setBackFieldValue2(properties.BackFields[1]?.Value)
      break;
    case 3:
      setBackFieldTitle1(properties.BackFields[0]?.Label)
      setBackFieldValue1(properties.BackFields[0]?.Value)
      setBackFieldTitle2(properties.BackFields[1]?.Label)
      setBackFieldValue2(properties.BackFields[1]?.Value)
      setBackFieldTitle3(properties.BackFields[2]?.Label)
      setBackFieldValue3(properties.BackFields[2]?.Value)
      break;
    default:
      break;
  }
}

function checkInputs(){
  if(valididyCheckbox && (passValidityStart === '' || passValidityEnd === '')){
    setDialogMessage("Veuillez renseigner les dates de validité et d'expiration")
    setDisplayDialog(true)
  }
  else if(passStyl !== '' && barcodeType !== '' && passName !== '' ){
    switch(Number(passStyl)){
      case 0:
        if(thumbnailUrl !== ''){
          UpdatePass()
        }
        else{
          setDialogMessage("Veuillez renseigner l'URL vignette")
          setDisplayDialog(true)      
        }
        break;
      case 1:
        //if(footerUrl !== ''){
          UpdatePass()
        // }
        // else{
        //   setDialogMessage("Veuillez renseigner l'URL pied de page")
        //   setDisplayDialog(true)
        // }
        break;
      case 2:
        if(stripUrl !== ''){
          UpdatePass()
        }
        else{
          setDialogMessage("Veuillez renseigner l'URL bande")
          setDisplayDialog(true)
        }
        break;
      case 3:
        switch(Number(templateStyl)){
          case 0: 
            if(backgroundUrl !== ''){
              UpdatePass()
            }
            else{
              setDialogMessage("Veuillez renseigner l'URL arrière plan")
              setDisplayDialog(true)
            }
            break;
          case 1:
            if(stripUrl !== ''){
              UpdatePass()
            }
            else{
              setDialogMessage("Veuillez renseigner l'URL bande")
              setDisplayDialog(true)
            }
            break;
        }
        break;
      case 4:
        if(stripUrl !== ''){
          UpdatePass()
        }
        else{
          setDialogMessage("Veuillez renseigner l'URL bande")
          setDisplayDialog(true)
        }
        break;
    }
  }else{
    setDialogMessage("Veuillez renseigner les champs requis")
    setDisplayDialog(true)
  }
}

function UpdatePass(){
  let newPass
  let headerFieldsArray
  let primaryFieldsArray
  let secondaryFieldsArray
  let auxiliaryFieldsArray
  let backFieldsArray
  let barcodeArray

  switch(Number(nbHeaderField)){
    case 0:
      break;
    case 1:
      headerFieldsArray = [
        {
          key: "keyheader1",
          label: headerFieldTitle1,
          value: headerFieldValue1
        }
      ]
      break;
    case 2:
      headerFieldsArray = [
        {
          key: "keyheader1",
          label: headerFieldTitle1,
          value: headerFieldValue1
        },
        {
          key: "keyheader2",
          label: headerFieldTitle2,
          value: headerFieldValue2
        }
      ]
      break;
    case 3:
      headerFieldsArray = [
        {
          key: "keyheader1",
          label: headerFieldTitle1,
          value: headerFieldValue1
        },
        {
          key: "keyheader2",
          label: headerFieldTitle2,
          value: headerFieldValue2
        },
        {
          key: "keyheader3",
          label: headerFieldTitle3,
          value: headerFieldValue3
        }
      ]
      break;
    default: break;
  }

  switch(Number(nbPrimaryField)){
    case 0:
      break;
    case 1:
      primaryFieldsArray = [
        {
          key: "keyprimary1",
          label: primaryFieldTitle1,
          value: primaryFieldValue1
        }
      ]
      break;
    case 2:
      primaryFieldsArray = [
        {
          key: "keyprimary1",
          label: primaryFieldTitle1,
          value: primaryFieldValue1
        },
        {
          key: "keyprimary2",
          label: primaryFieldTitle2,
          value: primaryFieldValue2
        }
      ]
      break;
    default: break;
  }

  switch(Number(nbSecondaryField)){
    case 0:
      break;
    case 1:
      secondaryFieldsArray = [
        {
          key: "keysecondary1",
          label: secondaryFieldTitle1,
          value: secondaryFieldValue1
        }
      ]
      break;
    case 2:
      secondaryFieldsArray = [
        {
          key: "keysecondary1",
          label: secondaryFieldTitle1,
          value: secondaryFieldValue1
        },
        {
          key: "keysecondary2",
          label: secondaryFieldTitle2,
          value: secondaryFieldValue2
        }
      ]
      break;
    case 3:
      secondaryFieldsArray = [
        {
          key: "keysecondary1",
          label: secondaryFieldTitle1,
          value: secondaryFieldValue1
        },
        {
          key: "keysecondary2",
          label: secondaryFieldTitle2,
          value: secondaryFieldValue2
        },
        {
          key: "keysecondary3",
          label: secondaryFieldTitle3,
          value: secondaryFieldValue3
        }
      ]
      break;
    case 4:
      secondaryFieldsArray = [
        {
          key: "keysecondary1",
          label: secondaryFieldTitle1,
          value: secondaryFieldValue1
        },
        {
          key: "keysecondary2",
          label: secondaryFieldTitle2,
          value: secondaryFieldValue2
        },
        {
          key: "keysecondary3",
          label: secondaryFieldTitle3,
          value: secondaryFieldValue3
        },
        {
          key: "keysecondary4",
          label: secondaryFieldTitle4,
          value: secondaryFieldValue4
        }
      ]
      break;
    default: break;
  }

  switch(Number(nbAuxiliaryField)){
    case 0:
      break;
    case 1:
      auxiliaryFieldsArray = [
        {
          key: "keyauxiliary1",
          label: auxiliaryFieldTitle1,
          value: auxiliaryFieldValue1
        }
      ]
      break;
    case 2:
      auxiliaryFieldsArray = [
        {
          key: "keyauxiliary1",
          label: auxiliaryFieldTitle1,
          value: auxiliaryFieldValue1
        },
        {
          key: "keyauxiliary2",
          label: auxiliaryFieldTitle2,
          value: auxiliaryFieldValue2
        }
      ]
      break;
    case 3:
      auxiliaryFieldsArray = [
        {
          key: "keyauxiliary1",
          label: auxiliaryFieldTitle1,
          value: auxiliaryFieldValue1
        },
        {
          key: "keyauxiliary2",
          label: auxiliaryFieldTitle2,
          value: auxiliaryFieldValue2
        },
        {
          key: "keyauxiliary3",
          label: auxiliaryFieldTitle3,
          value: auxiliaryFieldValue3
        }
      ]
      break;
    case 4:
      auxiliaryFieldsArray = [
        {
          key: "keyauxiliary1",
          label: auxiliaryFieldTitle1,
          value: auxiliaryFieldValue1
        },
        {
          key: "keyauxiliary2",
          label: auxiliaryFieldTitle2,
          value: auxiliaryFieldValue2
        },
        {
          key: "keyauxiliary3",
          label: auxiliaryFieldTitle3,
          value: auxiliaryFieldValue3
        },
        {
          key: "keyauxiliary4",
          label: auxiliaryFieldTitle4,
          value: auxiliaryFieldValue4
        }
      ]
      break;
    case 5:
      auxiliaryFieldsArray = [
        {
          key: "keyauxiliary1",
          label: auxiliaryFieldTitle1,
          value: auxiliaryFieldValue1
        },
        {
          key: "keyauxiliary2",
          label: auxiliaryFieldTitle2,
          value: auxiliaryFieldValue2
        },
        {
          key: "keyauxiliary3",
          label: auxiliaryFieldTitle3,
          value: auxiliaryFieldValue3
        },
        {
          key: "keyauxiliary4",
          label: auxiliaryFieldTitle4,
          value: auxiliaryFieldValue4
        },
        {
          key: "keyauxiliary5",
          label: auxiliaryFieldTitle5,
          value: auxiliaryFieldValue5
        }
      ]
      break;
    default: break;
  }

  switch(Number(nbBackField)){
    case 0:
      break;
    case 1:
      backFieldsArray = [
        {
          key: "keyback1",
          label: backFieldTitle1,
          value: backFieldValue1
        }
      ]
      break;
    case 2:
      backFieldsArray = [
        {
          key: "keyback1",
          label: backFieldTitle1,
          value: backFieldValue1
        },
        {
          key: "keyback2",
          label: backFieldTitle2,
          value: backFieldValue2
        }
      ]
      break;
    case 3:
      backFieldsArray = [
        {
          key: "keyback1",
          label: backFieldTitle1,
          value: backFieldValue1
        },
        {
          key: "keyback2",
          label: backFieldTitle2,
          value: backFieldValue2
        },
        {
          key: "keyback3",
          label: backFieldTitle3,
          value: backFieldValue3
        }
      ]
      break;
    default: break;
  }

  if(barcodeValue !== ''){
    barcodeArray = [
      {
        type: barcodeType,
        message: barcodeValue,
        alternateText: barcodeAlternateText
      }
    ]
  }

  newPass = {
    passName: passName,
    description: description,
    organizationName: organizationName,
    logoText: logoText,
    foregroundColor: foregroundColor,
    backgroundColor: backgroundColor,
    labelColor: labelColor,
    coloursFormat: coloursFormat !== '' ? Number(coloursFormat) : 0,    
    style: Number(passStyl),
    relevantDate: passValidityEnd ?? new Date(),
    transitType: Number(passStyl) === 1 ? Number(templateStyl) : null,
    model: Number(passStyl) === 1 || Number(passStyl) === 3 ? Number(templateStyl) : null,
    nfc: nfc ? {
      message: nfcValue,
      //encryptionPublicKey: "string"
    } : null,
    properties: {
      iconUrl: iconUrl ?? '',
      icon2xUrl: icon2xUrl ?? '',
      icon3xUrl: icon3xUrl ?? '',
      logoUrl: logoUrl ?? '',
      logo2xUrl: logo2xUrl ?? '',
      logo3xUrl: logo3xUrl ?? '',
      thumbnailUrl: thumbnailUrl ?? '',
      thumbnail2xUrl: thumbnail2xUrl ?? '',
      thumbnail3xUrl: thumbnail3xUrl ?? '',
      backgroundUrl: backgroundUrl ?? '',
      background2xUrl: background2xUrl ?? '',
      background3xUrl: background3xUrl ?? '',
      stripUrl: stripUrl ?? '',
      strip2xUrl: strip2xUrl ?? '',
      strip3xUrl: strip3xUrl ?? '',
      footerUrl: footerUrl ?? '',
      footer2xUrl: footer2xUrl ?? '',
      footer3xUrl: footer3xUrl ?? '',
      headerFields: headerFieldsArray != null && headerFieldsArray.length > 0 ? headerFieldsArray : null,
      primaryFields: primaryFieldsArray != null && primaryFieldsArray.length > 0 ? primaryFieldsArray : null,
      secondaryFields: secondaryFieldsArray != null && secondaryFieldsArray.length > 0 ? secondaryFieldsArray : null,
      auxiliaryFields: auxiliaryFieldsArray != null && auxiliaryFieldsArray.length > 0 ? auxiliaryFieldsArray : null,
      backFields: backFieldsArray != null && backFieldsArray.length > 0 ? backFieldsArray : null,
      barcodes: barcodeArray != null && barcodeArray.length > 0 ? barcodeArray : null
    }
  }

  api.methods.put(apiBaseUrl.data().digitalFramework + endpoints.data().UpdateApplePassEndpoint + '?&id=' + passIdSelected, newPass).then(() =>{
    setDialogMessage("La carte \"" + passName + "\" vient d'être modifiée")
    setDisplayDialog(true)
    fetchApplePass()
    initValues()
  }).catch((error)=>{
      //alert(error)
      console.log(error)
      setDialogMessage("Une erreur est survenue : \"" + error)
      setDisplayDialog(true)
  })
}

  return (
    <>
    <Dialog type='main' display={displayDialog} setDisplay={setDisplayDialog} title='AGL - Apple Wallet' text={dialogMessage}>customdialog</Dialog>
    <center>
      <FormControl sx={{ m: 1,width:'28ch' }} variant="standard">
          <InputLabel id="pass-label">Sélectionner une carte virtuelle</InputLabel>
          <Select
              labelId="pass-label"
              id="pass-select"
              value={passNameSelected}
              label="Sélectionner une carte virtuelle"
              onChange={preFillCard}
          >
              {dataApplePass.map((item, index = 0)=><MenuItem key={index++} value={item.passName}>{formatDisplayName(item.passName)}</MenuItem>)}
          </Select>
      </FormControl>
    </center>
    { passNameSelected !== '' ? <>
    <center>
        <FormControl sx={{ m: 1 ,width:'22ch' }} variant="standard" disabled>
          <InputLabel id="passStyl-label">Style de carte *</InputLabel>
          <Select
            labelId="passStyl-label"
            id="passStyl-select"
            value={passStyl}
            label="Style de carte *"
            onChange={updatePassStyl}
          >
            <MenuItem key={0} value={0} disabled>Générique</MenuItem>
            <MenuItem key={1} value={1} disabled>Carte d'embarquement</MenuItem>
            <MenuItem key={2} value={2} disabled>Coupon</MenuItem>
            <MenuItem key={3} value={3} disabled>Billet d'événement</MenuItem>
            <MenuItem key={4} value={4} disabled>Carte de magasin</MenuItem>
          </Select>
        </FormControl>
        { Number(passStyl) === 1 ?
          <>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <FormControl sx={{ m: 1,width:'15ch'  }} variant="standard">
          <InputLabel id="boardingTemplateStyl-label">Modèle</InputLabel>
          <Select
            labelId="boardingTemplateStyl-label"
            id="boardingTemplateStyl-select"
            value={templateStyl}
            label="Modèle"
            onChange={updateTemplateStyl}
            >
              <MenuItem key={0} value={0}>Avion</MenuItem>
              <MenuItem key={1} value={1}>Bateau</MenuItem>
              <MenuItem key={2} value={2}>Bus</MenuItem>
              <MenuItem key={3} value={3}>Générique</MenuItem>
              <MenuItem key={4} value={4}>Train</MenuItem>
            </Select>
          </FormControl>
          </> : null
        }
        { Number(passStyl) === 3 ?
          <>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <FormControl sx={{ m: 1,width:'15ch'  }} variant="standard">
          <InputLabel id="eventTemplateStyl-label">Modèle</InputLabel>
          <Select
            labelId="eventTemplateStyl-label"
            id="eventTemplateStyl-select"
            value={templateStyl}
            label="Modèle"
            onChange={updateTemplateStyl}
            >
              <MenuItem key={0} value={0}>Fond d'écran</MenuItem>
              <MenuItem key={1} value={1}>Bande</MenuItem>
            </Select>
          </FormControl>
          </> : null
        }
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <FormControl sx={{ m: 1,width:'17ch'  }} variant="standard">
        <InputLabel id="barcodeType-label">Type code barre *</InputLabel>
          <Select
            labelId="barcodeType-label"
            id="barcodeType-select"
            value={barcodeType}
            label="Type code barre *"
            onChange={updateBarcodeType}
          >
            <MenuItem key={1} value={1}>Format QR</MenuItem>
            <MenuItem key={2} value={2}>Format PDF417</MenuItem>
            <MenuItem key={3} value={3}>Format Aztec</MenuItem>
            <MenuItem key={4} value={4}>Format Code128</MenuItem>
          </Select>
        </FormControl>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <FormControl sx={{ m: 1,width:'16ch'  }} variant="standard">
        <InputLabel id="coloursFormat-label">Format couleurs</InputLabel>
          <Select
            labelId="coloursFormat-label"
            id="coloursFormat-select"
            value={coloursFormat}
            label="Format couleurs"
            onChange={updateColoursFormat}
          >
            <MenuItem key={0} value={0}>HTML</MenuItem>
            <MenuItem key={1} value={1}>RGB</MenuItem>
          </Select>
        </FormControl>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <FormControl sx={{ m: 1,width:'8ch' }} variant="standard">
        <InputLabel id="headerField-label">En-tête</InputLabel>
          <Select
            labelId="headerField-label"
            id="headerField-select"
            value={nbHeaderField}
            label="En-tête"
            onChange={updateNbHeaderField}
          >
            <MenuItem key={0} value={0}>0</MenuItem>
            <MenuItem key={1} value={1}>1</MenuItem>
            <MenuItem key={2} value={2}>2</MenuItem>
            <MenuItem key={3} value={3}>3</MenuItem>
          </Select>
        </FormControl>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <FormControl sx={{ m: 1,width:'10ch'  }} variant="standard">
        <InputLabel id="primaryField-label">Primaires</InputLabel>
          <Select
            labelId="primaryField-label"
            id="primaryField-select"
            value={nbPrimaryField}
            label="Primaires"
            onChange={updateNbPrimaryField}
          >
            <MenuItem key={0} value={0}>0</MenuItem>
            <MenuItem key={1} value={1}>1</MenuItem>
            <MenuItem key={2} value={2}>2</MenuItem>
          </Select>
        </FormControl>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <FormControl sx={{ m: 1,width:'12ch'  }} variant="standard">
        <InputLabel id="secondaryField-label">Secondaires</InputLabel>
          <Select
            labelId="secondaryField-label"
            id="secondaryField-select"
            value={nbSecondaryField}
            label="Secondaires"
            onChange={updateNbSecondaryField}
          >
            <MenuItem key={0} value={0}>0</MenuItem>
            <MenuItem key={1} value={1}>1</MenuItem>
            <MenuItem key={2} value={2}>2</MenuItem>
            {/* <MenuItem key={3} value={3}>3</MenuItem>
            <MenuItem key={4} value={4}>4</MenuItem> */}
          </Select>
        </FormControl>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        { Number(passStyl) === 1 ?
          <FormControl sx={{ m: 1,width:'11ch'  }} variant="standard">
          <InputLabel id="auxiliaryField-label">Auxiliaires</InputLabel>
            <Select
              labelId="auxiliaryField-label"
              id="auxiliaryField-select"
              value={nbAuxiliaryField}
              label="Auxiliaires"
              onChange={updateNbAuxiliaryField}
            >
              <MenuItem key={0} value={0}>0</MenuItem>
              <MenuItem key={1} value={1}>1</MenuItem>
              <MenuItem key={2} value={2}>2</MenuItem>
              <MenuItem key={3} value={3}>3</MenuItem>
              <MenuItem key={4} value={4}>4</MenuItem>
              <MenuItem key={5} value={5}>5</MenuItem>
            </Select>
          </FormControl> :
          <FormControl sx={{ m: 1,width:'11ch'  }} variant="standard">
          <InputLabel id="auxiliaryField-label">Auxiliaires</InputLabel>
            <Select
              labelId="auxiliaryField-label"
              id="auxiliaryField-select"
              value={nbAuxiliaryField}
              label="Auxiliaires"
              onChange={updateNbAuxiliaryField}
            >
              <MenuItem key={0} value={0}>0</MenuItem>
              <MenuItem key={1} value={1}>1</MenuItem>
              <MenuItem key={2} value={2}>2</MenuItem>
              {/* <MenuItem key={3} value={3}>3</MenuItem>
              <MenuItem key={4} value={4}>4</MenuItem>
              <MenuItem key={5} value={5}>5</MenuItem> */}
            </Select>
          </FormControl>
        }
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <FormControl sx={{ m: 1,width:'7ch'  }} variant="standard">
        <InputLabel id="backField-label">Verso</InputLabel>
          <Select
            labelId="backField-label"
            id="backField-select"
            value={nbBackField}
            label="Verso"
            onChange={updateNbBackField}
          >
            <MenuItem key={0} value={0}>0</MenuItem>
            <MenuItem key={1} value={1}>1</MenuItem>
            <MenuItem key={2} value={2}>2</MenuItem>
            <MenuItem key={3} value={3}>3</MenuItem>
            {/* <MenuItem key={4} value={4}>4</MenuItem> */}
            {/* <MenuItem key={5} value={5}>5</MenuItem> */}
          </Select>
        </FormControl>
        <br/><br/>
        <FormControlLabel control={<Checkbox sx={{color: grey[600],'&.Mui-checked': {color: blue[900]}}} checked={extendImageSizeCheckbox} onChange={updateExtendImageSizeCheckbox} />} label="Renseigner les images au format x2 et x3" /><br/>
     </center>
     
      <Form
          title="Validité"
          titleSmallSize={true}
          first={COLUMNLEFTVALIDITY}
          second={COLUMNRIGHTVALIDITY}
          type="main"
          form="full" 
          design="transparent"
          corporate
      >
      </Form>     
     <Form
          title=""
          first={COLUMNLEFTBASE}
          second={COLUMNRIGHTBASE}
          type="main"
          form="full" 
          design="transparent"
          corporate
      />
      <Form
          title=""
          titleSmallSize={true}
          first={COLUMNLEFTCOLOURS}
          second={COLUMNRIGHTCOLOURS}
          type="main"
          form="full" 
          design="transparent"
          corporate
      />
      <Form
          title="Code barre"
          titleSmallSize={true}
          first={COLUMNLEFTBARCODE}
          second={COLUMNRIGHTBARCODE}
          type="main"
          form="full" 
          design="main"
          corporate
      />
      {/* Display images fields depend on passStyl */}
      {/* BEGIN */}
      { Number(passStyl) === 0 ?
        <>
        { extendImageSizeCheckbox ?
          <Form
          title="Images"
          titleSmallSize={true}
          first={COLUMNLEFTGENERICIMAGESFULL}
          second={COLUMNRIGHTGENERICIMAGESFULL}
          type="main"
          form="full" 
          design="main"
          corporate
          /> 
          : <Form
            title="Images"
            titleSmallSize={true}
            first={COLUMNLEFTGENERICIMAGES}
            second={COLUMNRIGHTGENERICIMAGES}
            type="main"
            form="full" 
            design="main"
            corporate
            />
        }
        </>
         : null
      }
      { Number(passStyl) === 1 ?
        <>
        { extendImageSizeCheckbox ?
          <Form
          title="Images"
          titleSmallSize={true}
          first={COLUMNLEFTGENERICIMAGESFULL}
          second={COLUMNRIGHTBOARDINGIMAGESFULL}
          type="main"
          form="full" 
          design="main"
          corporate
          />
          : <Form
            title="Images"
            titleSmallSize={true}
            first={COLUMNLEFTGENERICIMAGES}
            second={COLUMNRIGHTBOARDINGIMAGES}
            type="main"
            form="full" 
            design="main"
            corporate
            />

        }
        </>
         : null
      }
      { Number(passStyl) === 2 ?
        <>
        { extendImageSizeCheckbox ?
          <Form
          title="Images"
          titleSmallSize={true}
          first={COLUMNLEFTGENERICIMAGESFULL}
          second={COLUMNRIGHTCOUPONORSTOREOREVENTSTRIPIMAGESFULL}
          type="main"
          form="full" 
          design="main"
          corporate
          />
          : <Form
            title="Images"
            titleSmallSize={true}
            first={COLUMNLEFTGENERICIMAGES}
            second={COLUMNRIGHTCOUPONORSTOREOREVENTSTRIPIMAGES}
            type="main"
            form="full" 
            design="main"
            corporate
            />
        }
        </>
         : null
      }
      { Number(passStyl) === 3 ?
        <>
        { Number(templateStyl) === 0 ?
            <>
            { extendImageSizeCheckbox ?
              <Form
              title="Images"
              titleSmallSize={true}
              first={COLUMNLEFTGENERICIMAGESFULL}
              second={COLUMNRIGHTEVENTBACKGROUNDIMAGESFULL}
              type="main"
              form="full" 
              design="main"
              corporate
              />
              : <Form
                title="Images"
                titleSmallSize={true}
                first={COLUMNLEFTGENERICIMAGES}
                second={COLUMNRIGHTEVENTBACKGROUNDIMAGES}
                type="main"
                form="full" 
                design="main"
                corporate
              />
            }
            </>
          : null
        }
        { Number(templateStyl) === 1 ?
            <>
            { extendImageSizeCheckbox ?
              <Form
              title="Images"
              titleSmallSize={true}
              first={COLUMNLEFTGENERICIMAGESFULL}
              second={COLUMNRIGHTCOUPONORSTOREOREVENTSTRIPIMAGESFULL}
              type="main"
              form="full" 
              design="main"
              corporate
              />
              : <Form
                title="Images"
                titleSmallSize={true}
                first={COLUMNLEFTGENERICIMAGES}
                second={COLUMNRIGHTCOUPONORSTOREOREVENTSTRIPIMAGES}
                type="main"
                form="full" 
                design="main"
                corporate
                />
            }
            </>
          : null
        }
        </> : null
      }
      { Number(passStyl) === 4 ?
        <>
        { extendImageSizeCheckbox ?
          <Form
          title="Images"
          titleSmallSize={true}
          first={COLUMNLEFTGENERICIMAGESFULL}
          second={COLUMNRIGHTCOUPONORSTOREOREVENTSTRIPIMAGESFULL}
          type="main"
          form="full" 
          design="main"
          corporate
          />
          : <Form
            title="Images"
            titleSmallSize={true}
            first={COLUMNLEFTGENERICIMAGES}
            second={COLUMNRIGHTCOUPONORSTOREOREVENTSTRIPIMAGES}
            type="main"
            form="full" 
            design="main"
            corporate
            />
        }
        </> : null
      }
      {/* END */}   
      { Number(nbHeaderField) === 1 ?
          <Form
            title="En-tête"
            titleSmallSize={true}
            first={COLUMNLEFTHEADERFIELD1}
            second={COLUMNRIGHTHEADERFIELD1}
            type="main"
            form="full" 
            design="main"
            corporate
          ></Form> : null
      }
      { Number(nbHeaderField) === 2 ?
          <Form
            title="En-tête"
            titleSmallSize={true}
            first={COLUMNLEFTHEADERFIELD2}
            second={COLUMNRIGHTHEADERFIELD2}
            type="main"
            form="full" 
            design="main"
            corporate
          ></Form> : null
      }
      { Number(nbHeaderField) === 3 ?
          <Form
            title="En-tête"
            titleSmallSize={true}
            first={COLUMNLEFTHEADERFIELD3}
            second={COLUMNRIGHTHEADERFIELD3}           
            type="main"
            form="full" 
            design="main"
            corporate
          ></Form> : null
      }
      { Number(nbPrimaryField) === 1 ?
          <Form
            title="Champ primaire"
            titleSmallSize={true}
            first={COLUMNLEFTPRIMARYFIELD1}
            second={COLUMNRIGHTPRIMARYFIELD1}
            type="main"
            form="full" 
            design="main"
            corporate
          ></Form> : null
      }
      { Number(nbPrimaryField) === 2 ?
          <Form
            title="Champ primaire"
            titleSmallSize={true}
            first={COLUMNLEFTPRIMARYFIELD2}
            second={COLUMNRIGHTPRIMARYFIELD2}
            type="main"
            form="full" 
            design="main"
            corporate
          ></Form> : null
      }
      { Number(nbSecondaryField) === 1 ?
          <Form
            title="Champ secondaire"
            titleSmallSize={true}
            first={COLUMNLEFTSECONDARYFIELD1}
            second={COLUMNRIGHTSECONDARYFIELD1}
            type="main"
            form="full" 
            design="main"
            corporate
          ></Form> : null
      }
      { Number(nbSecondaryField) === 2 ?
          <Form
            title="Champ secondaire"
            titleSmallSize={true}
            first={COLUMNLEFTSECONDARYFIELD2}
            second={COLUMNRIGHTSECONDARYFIELD2}
            type="main"
            form="full" 
            design="main"
            corporate
          ></Form> : null
      }
      { Number(nbSecondaryField) === 3 ?
          <Form
            title="Champ secondaire"
            titleSmallSize={true}
            first={COLUMNLEFTSECONDARYFIELD3}
            second={COLUMNRIGHTSECONDARYFIELD3}
            type="main"
            form="full" 
            design="main"
            corporate
          ></Form> : null
      }
      { Number(nbSecondaryField) === 4 ?
          <Form
            title="Champ secondaire"
            titleSmallSize={true}
            first={COLUMNLEFTSECONDARYFIELD4}
            second={COLUMNRIGHTSECONDARYFIELD4}
            type="main"
            form="full" 
            design="main"
            corporate
          ></Form> : null
      }
      { Number(nbAuxiliaryField) === 1 ?
          <Form
            title="Champ auxiliaire"
            titleSmallSize={true}
            first={COLUMNLEFTAUXILIARYFIELD1}
            second={COLUMNRIGHTAUXILIARYFIELD1}
            type="main"
            form="full" 
            design="main"
            corporate
          ></Form> : null
      }
      { Number(nbAuxiliaryField) === 2 ?
          <Form
            title="Champ auxiliaire"
            titleSmallSize={true}
            first={COLUMNLEFTAUXILIARYFIELD2}
            second={COLUMNRIGHTAUXILIARYFIELD2}
            type="main"
            form="full" 
            design="main"
            corporate
          ></Form> : null
      }
      { Number(nbAuxiliaryField) === 3 ?
          <Form
            title="Champ auxiliaire"
            titleSmallSize={true}
            first={COLUMNLEFTAUXILIARYFIELD3}
            second={COLUMNRIGHTAUXILIARYFIELD3}
            type="main"
            form="full" 
            design="main"
            corporate
          ></Form> : null
      }
      { Number(nbAuxiliaryField) === 4 ?
          <Form
            title="Champ auxiliaire"
            titleSmallSize={true}
            first={COLUMNLEFTAUXILIARYFIELD4}
            second={COLUMNRIGHTAUXILIARYFIELD4}
            type="main"
            form="full" 
            design="main"
            corporate
          ></Form> : null
      }
      { Number(nbAuxiliaryField) === 5 ?
          <Form
            title="Champ auxiliaire"
            titleSmallSize={true}
            first={COLUMNLEFTAUXILIARYFIELD5}
            second={COLUMNRIGHTAUXILIARYFIELD5}
            type="main"
            form="full" 
            design="main"
            corporate
          ></Form> : null
      }
      { Number(nbBackField) === 1 ?
        <Form
            title="Champ verso"
            titleSmallSize={true}
            first={COLUMNLEFTBACKFIELD1}
            second={COLUMNRIGHTBACKFIELD1}
            type="main"
            form="full" 
            design="main"
            corporate
          ></Form> : null
      }
      { Number(nbBackField) === 2 ?
        <Form
            title="Champ verso"
            titleSmallSize={true}
            first={COLUMNLEFTBACKFIELD2}
            second={COLUMNRIGHTBACKFIELD2}
            type="main"
            form="full" 
            design="main"
            corporate
          ></Form> : null
      }
      { Number(nbBackField) === 3 ?
        <Form
            title="Champ verso"
            titleSmallSize={true}
            first={COLUMNLEFTBACKFIELD3}
            second={COLUMNRIGHTBACKFIELD3}
            type="main"
            form="full" 
            design="main"
            corporate
          ></Form> : null
      }
      <br/>
       <center>
          <Button onClick={checkInputs} sx={{color:'#1B365F'}}>Générer</Button>
       </center>
       </> : null}
    </>
  );
}