import { ReactNode, useState } from 'react';
import { styled } from "@mui/material/styles";
import { styled as styledComponent } from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import THEME from '../../../styles/theme';
import Paper from '@mui/material/Paper';

interface IModalProps {
  children: ReactNode
  display: boolean
  setDisplay: (display: boolean) => void
  type?: string
  position?: string
  title?: string
  imagebase64?: string
  copyEnabled?: boolean
}

  function DialogComponent(props: IModalProps) {

  const [message, setMessage] = useState("Copier") 

  const handleClose = () => {
    setMessage("Copier")
    props.setDisplay && props.setDisplay(false);
  }

  const copyImg = async(src: string) =>{
    const img = await fetch(src);
    const imgBlob = await img.blob();
    try {
       navigator.clipboard.write([
         new ClipboardItem({
             'image/png': imgBlob, // change image type accordingly
         })
       ]).then(()=>{
         setMessage("Copié !")
       });
     } catch (error) {
         console.error(error);
     }
 }

  return (
    <Dialog
      open={props.display} 
      onClose={handleClose}
      PaperComponent={PaperStyle}
    >
      {/* <DialogTitle>{props.title}</DialogTitle> */}
      <DialogContent>
        <ContentContainer >
            <img src={`data:image/png;base64,${props.imagebase64}`} alt='' />
        </ContentContainer>
      </DialogContent>
      <DialogActions>
        {props.copyEnabled ? 
          <>
          <Button onClick={(e:any) => copyImg(`data:image/png;base64,${props.imagebase64}`)} color="primary" autoFocus>
            <Color>{message}</Color>
          </Button>
          </>
        : null}
        <Button onClick={handleClose} color="primary" autoFocus>
          <Color>Fermer</Color>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogComponent;

const PaperStyle = styled(Paper)`
  background-color: ${THEME.background.color.general};
  color: ${THEME.text.color.general};
`;

const ContentContainer = styledComponent.div`
  color: ${THEME.text.color.general};
  border: solid #ffffff;
`;

const Color = styledComponent.div`
  color: white;
`;
