import React, { ReactNode } from 'react';
import { styled } from 'styled-components';
import THEME from '../../../styles/theme';
import { BrowserView, MobileView } from "react-device-detect";

interface ITabsProps {
  active: string
  setActive: (active: string) => void
  tabList: {
    label: string
    logo?: string
  }[]
  width : string
  children: ReactNode
}

interface ITabProps {
  active: string
  setActive: () => void
  label: string
  width: string
  logo: string
}

function Tab(props:ITabProps) {
  return(
    <TabContainer 
      label={props.label}
      active={props.active} 
      onClick={() => props.setActive()}
      width={props.width}
    >
      <BrowserView>
        {props.logo !== '' ? 
        <>
          <img src={props.logo} alt='' width={'30px'} height={'25px'}/>&nbsp;&nbsp;
        </> : null} 
        {props.label}
      </BrowserView>
      <MobileView>
        {props.logo !== '' ? 
        <>
          <img src={props.logo} alt='' width={'30px'} height={'25px'}/>
        </> : props.label} 
      </MobileView>
    </TabContainer>
  )
}

function TabList(props: ITabsProps) {
  return (
    <div>
      <TabsContainer>
        {props.tabList.map(tab => {
          return(<Tab
              key={tab.label}
              label={tab.label} 
              active={props.active} 
              setActive={() => props.setActive(tab.label)}
              width={props.width}
              logo={tab.logo !== undefined ? tab.logo : ''}
            />)
        })}
      </TabsContainer>
      {props.children}
    </div>
  );
}

export default TabList;

interface ITabContainerProps {
  active: string
  label: string
  width: string
}

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const TabContainer = styled.div<ITabContainerProps>`
  background-color: ${props => props.active === props.label ? THEME.background.color.general : THEME.background.color.content};
  color: ${props => props.active === props.label ? THEME.text.color.general: THEME.text.color.main};
  width: ${props => props.width === "small" ? THEME.tab.with.small : props.width === "medium" ? THEME.tab.with.medium : THEME.tab.with.large};
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-right:1px;
  &:hover {
    background-color: ${THEME.background.color.general};
    color: ${THEME.text.color.general}
  }
`;
