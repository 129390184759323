import React from 'react';
import { styled } from 'styled-components';
import THEME from '../../styles/theme';

interface IButtonProps {
    type?: string
    design?: string
    usage?: string
    label: string
    size?: string
    onClick?: (args: any) => void
}

interface IButtonContainerProps {
  variant?: string
  design?: string
  usage?: string
  size?: string
}

function Button(props: IButtonProps) {
  return (
    <ButtonContainer 
      variant={props.type}
      design={props.design}
      usage={props.usage}
      size={props.size}
      onClick={props.onClick}
    >
      {props.label}
    </ButtonContainer>
  );
}

export default Button;

const ButtonContainer = styled.button<IButtonContainerProps>`
  background-color: ${
    props => props.variant === 'main' ? THEME.background.color.main:
    props => props.variant === 'primary' ? THEME.background.color.primary:
    props => props.variant === 'general' ? THEME.background.color.general:
    props => props.variant === 'principal' ? THEME.background.color.dark:  THEME.background.color.main
  };
  color: ${
    props => props.variant === 'main' ? THEME.text.color.main:
    props => props.variant === 'primary' ? THEME.text.color.main:
    props => props.variant === 'general' ? THEME.text.color.general:
    props => props.variant === 'principal' ? THEME.text.color.general:  THEME.background.color.general
  };
  text-transform: uppercase;
  border: none;
  border-radius: ${props => props.design === "sharp" ? "20px" : "5px"};
  padding: 15px;
  min-width: fit-content;
  height: 50px;
  width: ${props => props.size === 'full' ? "100%" : props.size === 'small' ? "100px" :"150px"};
  margin-right: ${props => props.usage === 'row-list' && '10px'};
  box-shadow: ${THEME.border.boxShadow.main};
  -webkit-box-shadow: ${THEME.border.boxShadow.main};
  -moz-box-shadow: ${THEME.border.boxShadow.main};

  &.active {
        background-color: ${props => 
            props.variant === 'general' ? THEME.background.color.main : 
            props.variant === 'main' ? THEME.background.color.general : 
            props.variant === 'primary' ? THEME.background.color.content : 
            THEME.background.color.main};
        color: ${props => props.variant === 'general' ? THEME.text.color.main :
            props.variant === 'main' ? THEME.text.color.general :
            props.variant === 'primary' ? THEME.text.color.main : 
            THEME.text.color.main}
    }

    &:hover{
        background-color: ${props => 
            props.variant === 'general' ? THEME.background.color.main : 
            props.variant === 'main' ? THEME.background.color.general : 
            props.variant === 'primary' ? THEME.background.color.content : 
            THEME.background.color.main};
        color: ${props => props.variant === 'general' ? THEME.text.color.main :
            props.variant === 'main' ? THEME.text.color.general :
            props.variant === 'primary' ? THEME.text.color.main : 
            THEME.text.color.main}
    }
`;